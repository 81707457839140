
<template>
<div class="wrapper">
    <ReceiptviewHeader
        title="Beleg-Archiv" :btnText="$store.state.receipt.selectedReceiptIDs.length ? 'Diese Auswahl exportieren' : 'Angezeigte Belege exportieren'"
        @click-btn="handleExportClick"
        icon="export"
        >
        <template v-slot:text>
            <p>Hier findest du alle von dir hochgeladenen Belege zu abgeschlossenen Vorgängen im Überblick.</p>
        </template>
    </ReceiptviewHeader>
    <!-- <ExportModal v-if="exportModal" @close-modal="exportModal = false">
        <template v-slot:bodytext>
            Willst du die Belege aus dem Ordner Januar 2021 Bewirtung exportieren?
        </template>
    </ExportModal> -->

    <FilterArchive  />
    <ReceiptList
        :hasFilters="false"
    />
  </div>
</template>
<script>
import ReceiptviewHeader from '../../partials/ReceiptView_Header'
import FilterArchive from '../../filter/Filter_Archive'
import ReceiptList from '../../ReceiptList/ReceiptList.vue'
import { mapState } from 'vuex'

export default {
    name: 'Archive',
    components: {
        ReceiptviewHeader,
        FilterArchive,
        ReceiptList,
    },
    computed: {
        ...mapState({
            categories: state => state.receipt.categories,
            currentCategory: state => state.archiveReceipts.filterCategoryID
      })
    },
    methods: {
        /**
         * Click event listener for export button
         * Exports receipts
         */
        handleExportClick() {
            if (this.$store.state.receipt.selectedReceiptIDs.length) {
                console.log('length')
                this.$store.dispatch('receipt/downloadReceitpsByID', this.$store.state.receipt.selectedReceiptIDs)
            } else {
                this.$store.dispatch('receipt/downloadReceipts', this.$store.state.receipt.receipts)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .wrapper {
        padding: 7.5px;
        @media screen and (min-width: $medium-screen) {
            padding: 22.5px;
        }
        p {
            font-size: 12px;
            line-height: 15px;
            padding-top: 5px;
            max-width: 90%;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
                line-height: 22px;
                padding-top: 0;
            }
        }
    }
</style>
