<template>
    <div class="grid">
        <div class="grid-item"
            v-for="receipt in receipts"
            :key="receipt.id"
        >
            <ReceiptGridItem
                :receipt="receipt"
                :receiptTitle="receipt.title"
                :receiptImage="receipt.files.length && receipt.files[0].imagePaths ? receipt.files[0].imagePaths[0] : ''"
                :approvedStatus="receipt.approved"
                :receiptMonth="getMonthString(receipt.month - 1)"
                :receiptType="receipt.categoryName"
                :receiptStatus="receipt.status"
            >
            </ReceiptGridItem>
        </div>
    </div>
</template>

<script>
import ReceiptGridItem from './Receipt_GridItem'

export default {
    name: 'ReceiptGridView',
    components: {
        ReceiptGridItem
    },
    props: {
        receipts: {
            type: Array, 
            required: true
        },
    },
    methods: {
        /**
         * Function that gets the month as a Number and returns its name as a String
         * @param {Int} n
         */
        getMonthString (n) {
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months[n]
        }
    }

}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .grid {
        display: flex;
        flex-wrap: wrap;
        &-item {
            width: 50%;
            padding: 7.5px;
            @media screen and (min-width: $medium-screen) {
                width: 25%;
            }
            @media screen and (min-width: $large-screen) {
                width: 20%;
            }
            &__content {
                padding-top: 50px;
                background-color: $light-grey;
                border-radius: $button-border-r;
                height: 180px;
                @media screen and (min-width: $medium-screen) {
                    height: 265px;
                    padding-top: 60px;
                }
                @media screen and (min-width: $large-screen) {
                    height: 250px;
                }
            }
        }
    }
</style>