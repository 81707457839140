<template>
    <div class="wrapper">        
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'Index'
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .wrapper {
        padding: 7.5px;
        @media screen and (min-width: $medium-screen) {
            padding: 22.5px;
        }
    }
</style>

