<template>
    <div class="dropdown">
        <div class="dropdown-item">
            <div class="toggle-dropdown" name="dropdown" @click="toggleDropdown" :class="{active: isActive}">
                {{ selectedItem ? selectedItem.name : placeholder }}
                <svg class="arrow-btn" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5896 0.424262L5.62192 4.71006L1.65425 0.424262C1.45398 0.207944 1.12856 0.207944 0.928299 0.424262L0.383838 1.01913C0.183577 1.23545 0.183577 1.58697 0.383838 1.79653L4.67694 6.43385C4.68945 6.44737 4.69571 6.46765 4.70823 6.48116L5.25895 7.07604C5.45921 7.29236 5.78463 7.29236 5.97864 7.07604L5.98489 7.06928L6.53561 6.4744L10.8663 1.79653C11.0665 1.58021 11.0665 1.2287 10.8663 1.01238L10.3155 0.4175C10.1153 0.207942 9.78986 0.207944 9.5896 0.424262Z" fill="#58584D"/>
                </svg>
            </div>

            <label v-if="label" for="dropdown">{{ label }}</label>
            
            <ul v-if="isActive">
                <DropdownItem
                    v-for="item in listItems"
                    :key="item.id"
                    :title="item.name"
                    @click="isActive = false, handleClick(item.name, item.id)"
                >
                </DropdownItem>
            </ul>
        </div>
    </div>
</template>

<script>
    import DropdownItem from '../partials/DropdownItem'

    export default {
        name: 'Dropdown',
        components: {
            DropdownItem
        },
        data() {
            return {
                /**
                 * Detects if dropdown is active 
                 */
                isActive: false,
                /**
                 * Variable to store the outside click handler
                 */
                onOutsideClickHandler: null
            }
        },
        computed: {
            selectedItem() {
                return this.listItems.find(i => i.id === this.selectedItemID)
            }
        },
        props: {
            placeholder: {
                type: String,
                required: true
            },
            label: {
                type: String
            },
            listItems: {
                type: Array,
                required: true
            },
            selectedItemID: {
        
            }
        },
        mounted() {
            this.title = this.placeholder
        },
        methods: {
            /**
             * Function that detects outside click
             */
            createOutsideClickHandler () {
                return function (event) {
                    if (!(this.$el === event.target || this.$el.contains(event.target))) {
                        this.deactivate()
                    }
                }.bind(this)
            },
            
            /**
             * Click event listener for Dropdown
             * Function that activates or deactivates dropdown depending on its current state
             */
            toggleDropdown () {
                !this.isActive 
                    ? this.activate()
                    : this.deactivate()
            },

            /**
             * Function that activates a dropdown and starts the outside click event listener 
             */
            activate () {
                this.isActive = true
                this.onOutsideClickHandler = this.createOutsideClickHandler()
                document.body.addEventListener('click', this.onOutsideClickHandler)
            },

            /**
             * Function that deactivates the dropdown and deletes the outside click event listener 
             */
            deactivate () {
                this.isActive = false
                document.body.removeEventListener('click', this.onOutsideClickHandler)
            },
            
            /**
             * Function that sets the dropdown title to the selected name
             */
            handleClick(name, id) {
                this.title = name
                this.$emit('selected', id)
            }
        }
    }
</script>

<style lang="scss">
    @import 'src/assets/style/variables.scss';

    .dropdown-item {
        position: relative;
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        @media screen and (min-width: $medium-screen) {
            font-size: 16px;
        }
        .toggle-dropdown {
            position: relative;
            border: 1px solid $default-grey;
            padding: 5px 10px;
            -ms-user-select: None;
            -moz-user-select: None;
            -webkit-user-select: None;
            user-select: None;
            @media screen and (min-width: $medium-screen) {
                padding: 8px 15px;
            }
            &.active {
                border-color: $brand-blue;
                svg path {
                    fill: $brand-blue;
                }
            }
            &.bold {
                font-family: "Gotham-Medium";
            }
            .arrow-btn {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        label {
            display: block;
            color: $default-grey;
            font-size: 10px;
            line-height: 15px;
            padding-top: 10px;
        }
        ul {
            position: absolute;
            top: 40px;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
            width: 100%;
            background-color: #fff;
            color: $grey-text;
            border-radius: 0 0 0 30px;
            z-index: 10;
            @media screen and (min-width: $medium-screen) {
                top: 50px;
            }
        }
    }
</style>