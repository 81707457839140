/**
 * Includes Data specific to the staff view
 * */

import { listUsers, submitTicket, createTicketReply } from '@/graphql/api'

export const staffModule = {
  namespaced: true,
  state: {
    users: {}, // object of all summarum service users from the cognito user pool, key: username, val: cognito user object
    selectedUserId: undefined // ID of the selected user to view receipts, upload reports for etc.
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    setSelectedUserId(state, id) {
      state.selectedUserId = id
    } 
  },
  actions: {

    /**
     * Fetches the user list from AWS cognito.
     */
    async fetchUsers({commit}) {
      const userList = await listUsers()
      const usersObj = Object.fromEntries(
        userList.map(user => ([
            user.Username,
            user
        ]))
      );
      commit('setUsers', usersObj)
    },

    /**
     * @param  {string} username Selects a user to view receipts, reports etc of that user. Initializes a load of that data.
     */
    async selectUser({commit, dispatch}, username) {
      commit('setSelectedUserId', username)
      commit(`receipt/setListReceiptsBy`, username, { root: true })
      commit(`report/setListReportsBy`, username, { root: true })
      commit(`documents/setListDocumentsBy`, username, { root: true })

      dispatch(`receipt/load`, null, { root: true })
    },

    async requestInfoFromUser({getters, state, rootState, dispatch}, {receiptID}) {
      const receipt = rootState.receipt.receipts.find(r => r.id === receiptID)
      const fullName = getters.selectedUser.Attributes.given_name + ' ' + getters.selectedUser.Attributes.family_name
      const receiptUrl = `${window.location.origin}/receipt/${receiptID}?username=${state.selectedUserId}`

      try {
        const ticketID = await submitTicket(
          `[SUMMARUM MACHT'S RÜCKFRAGE] Beleg ${receipt.title}`,
          `Rückfrage von Summarum an ${fullName} zu Beleg ${receiptUrl}`,
          ['summarum-machts-rueckfrage'],
          getters.selectedUser.Attributes.email
        )

        dispatch(`receipt/setNeedInfoTicketID`, {
          ticketID: ticketID,
          receiptID: receiptID
        }, { root: true })

        dispatch(`receipt/serReceiptAssistanceStatus`, {
          receiptID: receiptID,
          requestAssistance: false
        }, { root: true })

        await createTicketReply(
          ticketID,
          `<div>Bitte ergänze die fehlenden Daten zu diesem Beleg und gebe den Beleg zur Buchung frei: <a href="${receiptUrl}">${receipt.title}</a></div>`
        )

      } catch (err) {
        console.error('could not finish request info from user process')
      }
      
    },

  },
  getters: {
    selectedUser: (state) => {
      return state.users[state.selectedUserId]
    },
  }
}
