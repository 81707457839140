import { receiptItem } from './partials'

export const createReceiptWithoutFiles = /* GraphQL */ `
  mutation CreateReceipt(
    $input: CreateReceiptInput!
  ) {
    createReceipt(input: $input) {
      id
      title
      owner
      categoryID
      month
    }
  }
`;

export const updateReceipt = /* GraphQL */ `
  mutation UpdateReceipt(
    $input: UpdateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    updateReceipt(input: $input, condition: $condition) {
      ${receiptItem}
    }
  }
`;

export const createMetadataProperty = /* GraphQL */ `
  mutation CreateMetadataProperty(
    $input: CreateMetadataPropertyInput!
    $condition: ModelMetadataPropertyConditionInput
  ) {
    createMetadataProperty(input: $input, condition: $condition) {
      id
      value
      typeID
      type {
        id
        name
      }
      receiptID
      createdAt
      updatedAt
      receipt {
        ${receiptItem}
      }
      owner
    }
  }
`;
export const updateMetadataProperty = /* GraphQL */ `
  mutation UpdateMetadataProperty(
    $input: UpdateMetadataPropertyInput!
    $condition: ModelMetadataPropertyConditionInput
  ) {
    updateMetadataProperty(input: $input, condition: $condition) {
      id
      value
      typeID
      type {
        id
        name
      }
      receiptID
      createdAt
      updatedAt
      receipt {
        ${receiptItem}
      }
      owner
    }
  }
`;

export const createReceiptFile = /* GraphQL */ `
  mutation CreateReceiptFile(
    $input: CreateReceiptFileInput!
    $condition: ModelReceiptFileConditionInput
  ) {
    createReceiptFile(input: $input, condition: $condition) {
      id
      originalFilename
      imagePaths
      ocrResult
      path
      receiptID
      identityId
      owner
      editBy
      createdAt
      updatedAt
      receipt {
        ${receiptItem}
      }
    }
  }
`;