<template>
    <div class="receipt__title">{{shortenName(receiptTitle)}}</div>
</template>

<script>
export default {
    name: 'ReceiptTitle',
    props: {
        receiptTitle: {
            type: String,
        }
    },
    methods: {
        /**
         * Function that shortens a String when its length is longer than 10 
         * @param {String} name
         */
        shortenName(name) {
            if (name.length > 10) {
               return name.substring(0,10) + '....' + name.substr(name.length - 3)
            } else {
                return name
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receipt__title {
        font-size: 10px;
        line-height: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
        max-width: calc(100% - 30px); // 15px x-margin 
        @media screen and (min-width: $medium-screen) {
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 0;
        }
    }
</style>