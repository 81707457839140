<template>
    <div>
        <ReceiptviewSubheader title="Datenschutz" backLinkName="zurück zu den Einstellungen" @click-btn="test"></ReceiptviewSubheader>
    </div>
</template>

<script>
    import ReceiptviewSubheader from '../../partials/ReceiptView_Subheader'

    export default {
        name: 'Payment',
        components: {
            ReceiptviewSubheader
        }
    }
</script>