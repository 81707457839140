<template>
    <div class="receiptview__header">
        <h3>{{ title }}</h3>

        <div class="receiptview__header-text">
            <slot name="text"></slot>
        </div>

        <div v-if="!emptyCollectionState">
            <div class="export-button--mobile" @click="$emit('click-btn')">
                <svg v-if="icon === 'export'" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.19617 13.1527H18.8962C19.1462 13.1527 19.3562 12.9426 19.3562 12.6926V11.9926C19.3562 11.7426 19.1462 11.5327 18.8962 11.5327H13.8362V5.9626L16.7762 8.90266C16.9662 9.09266 17.2762 9.09266 17.4662 8.90266L17.9962 8.37263C18.1862 8.18263 18.1862 7.87257 17.9962 7.68257L13.8462 3.53267L13.3162 3.00264L13.3062 2.99263C13.1162 2.80263 12.8062 2.80263 12.6162 2.99263L12.0862 3.52266C12.0762 3.53266 12.0662 3.55257 12.0562 3.56257L7.94617 7.67256C7.75617 7.86256 7.75617 8.17262 7.94617 8.36262L8.47617 8.89265C8.66617 9.08265 8.97617 9.08265 9.16617 8.89265L12.1062 5.95259V11.5227H7.18619C6.93619 11.5227 6.72617 11.7326 6.72617 11.9826V12.6826C6.73617 12.9526 6.94617 13.1527 7.19617 13.1527ZM7.19617 16.5227H18.8962C19.1462 16.5227 19.3562 16.3126 19.3562 16.0626V15.3626C19.3562 15.1126 19.1462 14.9027 18.8962 14.9027H7.19617C6.94617 14.9027 6.73618 15.1126 6.73618 15.3626V16.0626C6.73618 16.3126 6.94617 16.5227 7.19617 16.5227ZM22.6362 2.86262H21.8962C21.6262 2.86262 21.4062 3.01262 21.4062 3.36262V21.9326H4.55618V3.37263C4.55618 3.02263 4.33616 2.86262 4.06616 2.86262H3.32617C3.05617 2.86262 2.83618 3.02263 2.83618 3.37263V23.1627C2.83618 23.4327 3.04616 23.6427 3.31616 23.6527H3.32617H22.6462H22.6562C22.9262 23.6427 23.1362 23.4327 23.1362 23.1627V3.35261C23.1262 3.01261 22.9062 2.86262 22.6362 2.86262ZM7.19617 19.8826H18.8962C19.1462 19.8826 19.3562 19.6726 19.3562 19.4226V18.7226C19.3562 18.4726 19.1462 18.2626 18.8962 18.2626H7.19617C6.94617 18.2626 6.73618 18.4726 6.73618 18.7226V19.4226C6.73618 19.6726 6.94617 19.8826 7.19617 19.8826Z" fill="#FFF"/>
                </svg>

                <svg v-if="icon === 'import'" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.71008 12.62H18.4101C18.6601 12.62 18.8701 12.41 18.8701 12.16V11.46C18.8701 11.21 18.6601 11 18.4101 11H13.7201L17.6001 7.12C17.7901 6.93 17.7901 6.62005 17.6001 6.43005L17.0701 5.90002C16.8801 5.71002 16.5701 5.71002 16.3801 5.90002L13.4401 8.83997V2.80005C13.4401 2.53005 13.2201 2.31006 12.9501 2.31006H12.2101C11.9401 2.31006 11.7201 2.53005 11.7201 2.80005V8.83997L8.78009 5.90002C8.59009 5.71002 8.28009 5.71002 8.09009 5.90002L7.56009 6.43005C7.37009 6.62005 7.37009 6.93 7.56009 7.12L11.4201 11H6.72009C6.47009 11 6.2601 11.21 6.2601 11.46V12.16C6.2501 12.42 6.46008 12.62 6.71008 12.62ZM6.71008 15.98H18.4101C18.6601 15.98 18.8701 15.77 18.8701 15.52V14.8199C18.8701 14.5699 18.6601 14.36 18.4101 14.36H6.71008C6.46008 14.36 6.25009 14.5699 6.25009 14.8199V15.52C6.25009 15.78 6.46008 15.98 6.71008 15.98ZM22.1501 2.32996H21.4101C21.1401 2.32996 20.9201 2.47996 20.9201 2.82996V21.4H4.0701V2.83997C4.0701 2.48997 3.85008 2.32996 3.58008 2.32996H2.84009C2.57009 2.32996 2.3501 2.48997 2.3501 2.83997V22.63C2.3501 22.9 2.56008 23.11 2.83008 23.12H2.84009H22.1601H22.1701C22.4401 23.11 22.6501 22.9 22.6501 22.63V2.81995C22.6401 2.47995 22.4201 2.32996 22.1501 2.32996ZM6.71008 19.35H18.4101C18.6601 19.35 18.8701 19.14 18.8701 18.89V18.1899C18.8701 17.9399 18.6601 17.73 18.4101 17.73H6.71008C6.46008 17.73 6.25009 17.9399 6.25009 18.1899V18.89C6.25009 19.14 6.46008 19.35 6.71008 19.35Z" fill="#151917"/>
                </svg>
            </div>
            <div class="btn export-button--desktop" @click="$emit('click-btn')">
                {{btnText}}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'ReceiptviewHeader',
        props: {
            title: {
                type: String,
                required: true
            },
            btnText: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                required: true,
                default: 'export'
            }
        },
        computed: {
            ...mapState({
                /**
                 * Function that gets all receipts 
                 */
                receipts: state => state.receipt.receipts,

                /**
                 * Detects if filters are active
                 */
                filterActive(state, getters) {
                    return getters[`receipt/filterActive`]
                },

                /**
                 * Detects if content is loading
                 */
                loading: state => state.receipt.loading,
            }),

            /**
             * Detects if emptyCollectionState is true or false
             * this is the "Hier gibt es noch nichts zu tun" screen 
             */
            emptyCollectionState() {
                return this.$route.name === 'collection' &&
                        !this.filterActive &&
                        !this.receipts.length &&
                        !this.loading
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receiptview__header {
        position: relative;
        margin: 7.5px;
        @media screen and (min-width: $medium-screen) {
            padding-bottom: 30px;
        }
        @media screen and (min-width: $large-screen) {
            padding-bottom: 15px;
            border: none;
        }
        h3 {
            @media screen and (min-width: $medium-screen) {
                font-size: 28px;
                line-height: 32px;
            }
            @media screen and (min-width: $large-screen) {
                font-size: 34px;
                line-height: 40px;
            }
        }
        &-text {
            padding-top: 5px;
            font-size: 12px;
            line-height: 15px;
            display: inline-block;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
                line-height: 22px;
                padding-top: 15px;
            }
            @media screen and (min-width: $large-screen) {
                padding-top: 5px;
            }
        }
        .export-button--mobile {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $brand-blue;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: $medium-screen) {
                display: none;
            }
            svg {
                width: 18px;
                height: 18px;
                path {
                    fill: #fff;
                }
            }
        }
        .export-button--desktop {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $brand-blue;
            color: #fff;
            font-family: "Gotham-Medium";
            &:hover {
                background-color: $hover-blue;
            }
            @media screen and (min-width: $medium-screen) {
                display: block;
            }
        }
    }
</style>