<template>
    <Modal
        @close-modal="$emit('close-modal'), resetAssignments(), deleteFiles()"
        flexPosition="end"
    >
        <template v-slot:headertext>
            Belege hochladen
        </template>
        <template v-slot:body>
            <div v-show="categoryModeIsOrdered" class="hint">*Pflichtfelder - bitte triff eine Auswahl</div>
            <div class="upload__container">
                <!-- This is the section where the files are uploaded -->
                <div class="upload__field">
                    <div class="add-receipt">
                        <div
                            :class="['upload-scan', {active: dropzoneActive}]"
                            id="drop_zone"
                            @drop.prevent="handleDrop"
                            @dragover.prevent="dropzoneActive = true"
                            @dragleave.prevent="dropzoneActive = false"
                        >
                            <div>
                                <div class="drop-label">
                                    <svg class="upload-icon" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M29.4294 13.0702C30.4179 14.0587 30.4179 15.6682 29.4294 16.6568C28.4408 17.6453 26.8313 17.6453 25.8428 16.6568L18.112 8.92597V27.6827C18.112 29.0894 16.9714 30.2174 15.5773 30.2174C14.1705 30.2174 13.0426 29.0894 13.0426 27.6827V8.92597L5.31181 16.6568C4.32328 17.6453 2.71375 17.6453 1.72522 16.6568C0.736694 15.6682 0.736694 14.0587 1.72522 13.0702L13.7777 1.01775C14.7789 0.0165452 16.3757 0.0165452 17.3642 1.01775L29.4294 13.0702Z" fill="#009EE0"/>
                                    </svg>

                                    <span class="text--grey">PDF, PNG oder JPG (max. 1 MB)</span>
                                    Füge neue Belege einfach per drag & drop oder über den Button hinzu. 
                                </div>
                                <input
                                    type="file"
                                    id="files-input"
                                    ref="filesInput"
                                    name="files"
                                    @change="handleFileChanges"
                                    multiple
                                    />
                                <label class="chooser-label btn" for="files-input">Dateien auswählen</label>
                            </div>
                        </div>
                        <div class="file-list">
                            <span v-if="files.length" class="file-list-heading">Deine Uploads:</span>
                            <ul :class="{active: allFilesVisible}">
                                <div class="overlay" :class="{active: files.length > 5, hidden: allFilesVisible}"></div>
                                <li v-for="file in files" :key="file.id">
                                    <div class="file-photo">
                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.7 8.90008L13.05 2.19009C12.85 1.99009 12.63 2.00008 12.63 2.00008H5.47C5.21 2.00008 5 2.22009 5 2.49009V22.1701C5 22.5801 5.22002 22.8001 5.49002 22.8001H19.36C19.69 22.8001 19.95 22.5401 19.95 22.2101V9.44008C19.95 9.33008 19.93 9.13008 19.7 8.90008ZM13.13 4.72008L17.19 8.81008H13.13V4.72008ZM6.72 3.72008H11.4L11.39 10.0401C11.39 10.3101 11.61 10.5301 11.88 10.5301H18.23V21.0701H6.73001V3.72008H6.72Z" fill="#151917"/>
                                        </svg>
                                    </div>
                                    <div class="file-name">{{ shortenName(file.name) }}</div>
                                    <div class="file-footer">
                                        <div class="file-size">1,0 MB</div>
                                        <div class="delete-btn" @click="files = files.filter(f => f.name !== file.name)">
                                            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.0706 1.82094H6.53353V0.351627C6.53353 0.210534 6.42163 0.0986328 6.27567 0.0986328H4.42687C4.28577 0.0986328 4.16901 0.210534 4.16901 0.351627V1.82094H0.631953C0.500591 1.82094 0.393555 1.92798 0.393555 2.05934V2.41937C0.393555 2.55073 0.500591 2.65777 0.631953 2.65777H10.0706C10.2019 2.65777 10.309 2.55073 10.309 2.41937V2.05934C10.309 1.92798 10.2019 1.82094 10.0706 1.82094ZM6.02268 1.82094H4.67986V0.609486H6.01781V1.82094H6.02268ZM10.1192 3.55784L9.76407 3.48486C9.63757 3.45567 9.50621 3.56757 9.46729 3.73299L7.94932 11.099H7.46766L8.69371 3.5919H8.17799L6.95194 11.099H6.58218L7.24872 3.5919H6.73787L6.07133 11.099H5.63345V3.59676H5.1226V11.1039H4.6458L3.99872 3.59676H3.48787L4.13008 11.1039H3.76032L2.52941 3.59676H2.00883L3.24461 11.1039H2.81646L1.27417 3.73299C1.24011 3.56757 1.10875 3.45567 0.977388 3.48486L0.627088 3.55784C0.495725 3.58217 0.417881 3.73786 0.451938 3.90814L2.07694 11.6877C2.09154 11.7607 2.12559 11.8142 2.16938 11.8629C2.17424 11.8677 2.17424 11.8726 2.17911 11.8726C2.19857 11.8921 2.2229 11.9067 2.24722 11.9164C2.25209 11.9164 2.25209 11.9212 2.25695 11.9212C2.28128 11.931 2.31047 11.9358 2.33966 11.9358C2.34453 11.9358 2.34453 11.9358 2.34939 11.9358H8.42126C8.42612 11.9358 8.42612 11.9358 8.43099 11.9358C8.45531 11.9358 8.4845 11.931 8.50883 11.9212C8.51856 11.9164 8.52829 11.9115 8.53316 11.9067C8.54775 11.8969 8.56721 11.8872 8.58181 11.8775C8.59154 11.8677 8.60127 11.858 8.611 11.8434C8.64506 11.7996 8.67425 11.751 8.68885 11.6877L10.2944 3.90814C10.3236 3.73786 10.2457 3.58217 10.1192 3.55784Z" fill="#58584D"/>
                                            </svg>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div v-if="files.length > 5" class="showall-btn" @click="toggleFiles()">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3684 7.65902H7.86837V13.159H6.03504V7.65902H0.535034V5.82568H6.03504V0.325684H7.86837V5.82568H13.3684V7.65902Z" fill="#009EE0"/>
                                </svg>
                                <span v-if="!allFilesVisible">Alle</span> <span v-if="allFilesVisible">Weniger</span> anzeigen
                            </div>

                        </div>
                    </div>
                </div>

                <!-- This is the section with the ordered/unordered categories -->
                <div class="categories">
                    <div class="categories__header">
                        <a href="" @click.prevent="categoryModeIsOrdered = false, resetAssignments()" :class="{active: categoryModeIsOrdered === false}">Schneller Upload</a> 
                        <span class="dividingline">|</span> 
                        <a href="" @click.prevent="categoryModeIsOrdered = true" :class="{active: categoryModeIsOrdered === true}">Sortierter Upload</a>
                    </div>
                    
                    <div @click="categoryModeIsOrdered = true">
                        <!-- Month -->
                        <div class="headline">
                            <h4 :class="{disabled: !categoryModeIsOrdered}">Monat<span v-if="categoryModeIsOrdered">*</span></h4> 
                            <div v-if="activeLastMonth" class="info">
                                <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.2744 17.7702H11.2744V15.7702H13.2744V17.7702Z" fill="#58584D"/>
                                    <path d="M13.2744 13.7702H11.2744V7.7702H13.2744V13.7702Z" fill="#58584D"/>
                                </svg>

                                <div class="info__content">
                                    Du kannst noch bis zum 5. des neuen Monats deine Belege aus dem Vormonat ablegen.
                                </div>
                            </div>
                        </div>
                        <div class="categories__buttons">
                            <div
                                v-if="activeLastMonth"
                                class="btn--rounded"
                                :class="{active: month === lastMonth, disabled: !categoryModeIsOrdered}"
                                @click="setMonth(lastMonth)"
                            >
                                <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                </svg>
                                <svg class="delete-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                </svg>

                                {{ getMonthString(lastMonth) }}
                            </div>
                            <div 
                                class="btn--rounded" 
                                :class="{active: month === currentMonth, disabled: !categoryModeIsOrdered}" 
                                @click="setMonth(currentMonth)"
                            >
                                <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                </svg>
                                <svg class="delete-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                </svg>
                                {{ getMonthString(currentMonth) }}
                            </div>
                        </div>

                        <!-- Category -->
                        <h4 :class="{disabled: !categoryModeIsOrdered}">Art<span v-if="categoryModeIsOrdered">*</span></h4>
                        <div class="categories__buttons">
                            <div
                                v-for="category in categories"
                                :key="category.id"
                                :class="['btn--rounded', {active: category.id === activeCategoryID, disabled: !categoryModeIsOrdered}]"
                                @click="setCategory(category.id)"
                            >
                                <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                </svg>
                                <svg class="delete-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                </svg>
                                {{ category.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        
        <template v-slot:footer >
            <div class="footer-wrapper">
                <div class="btn cancel-btn" @click="$emit('close-modal'), resetAssignments(), deleteFiles()">Abbrechen</div>
                <div 
                    class="btn btn--blue" 
                    :class="{'btn--disabled': !files.length || !isValid}" 
                    @click="createReceiptClick"
                >
                    <div v-if="loading" class="loadingspinner">
                        <LoadingSpinner />
                    </div>
                    <span 
                        :class="{hidden: loading}"
                    >
                        Jetzt hochladen
                    </span>
                </div>
            </div>
        </template>
    </Modal>  
</template>

<script>
import Modal from './partials/Modal'
import LoadingSpinner from './partials/LoadingSpinner'
import { mapState } from 'vuex'

export default {
    name: 'CreateReceipt',

    components: {
        Modal,
        LoadingSpinner
    },

    props: {
        isModalActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            /**
             * Array that stores uploaded files
             */
            files: [],
            /**
             * Variable that detects if dropzone is active
             */
            dropzoneActive: false,
            /**
             * Variable that stores category
             */
            category: 0,
            /**
             * Variable that detects which categoryMode is selected 
             * ("geordneter Upload" or "ungeordneter Upload")
             */
            categoryModeIsOrdered: false,
            /**
             * Variable that stores active category id
             */
            activeCategoryID: undefined,
            /**
             * Variable that detects which month is selected
             */
            month: undefined,
            /**
             * Variable that stores current month
             */
            currentMonth: undefined,
            /**
             * Variable that stores last month 
             */
            lastMonth: undefined,
            /**
             * Variable that detects if last month is active
             */
            activeLastMonth: false,
            /**
             * Variable that detects if all files are visible 
             */
            allFilesVisible: false,
            /**
             * If true shows loading spinner in submit button
             */
            loading: false
        }
    },
    computed: {
        ...mapState({
            /**
             * Function that gets all categories from backend
             */
            categories(state) {
                return state.receipt.categories
            }
        }),

        isValid() {
            if (this.categoryModeIsOrdered) {
                return this.month && this.activeCategoryID
            } else {
                return true
            }
        },
    },
    created () {
        this.currentMonth = this.updateCurrentMonth()
        this.lastMonth = this.updateLastMonth()
        this.activeLastMonth = this.checkCurrentDate()
    },
    methods: {
        /**
         * Function that selects file to upload
         */
        handleDrop(ev) {
            let droppedFiles = ev.dataTransfer.files;
            if(!droppedFiles) return;
            ([...droppedFiles]).forEach(f => {
                this.files.push(f)
            })
        },

        /**
         * Change event listener for files upload
         * Pushes uploaded file to Array 
         */
        handleFileChanges(ev) {
            ([...ev.target.files]).forEach(f => {
                this.files.push(f)
            })
        },

        /**
         * Click event listener for 'Jetzt hochladen' button
         * Uploads receipt and stores it in backend
         */
        async createReceiptClick() {
            this.loading = true
            try {
                await this.$store.dispatch(`receipt/createReceipt`, {
                    files: this.files,
                    categoryID: this.activeCategoryID,
                    month: this.month ? this.month + 1 : null
                })
                // clean state, erfolgreich hochgeladen
                this.$emit('close-modal')
                this.resetAssignments()
                this.$router.push('collection')
            } catch(err) {
                console.error(err)
            }
            this.loading = false
        },

        /**
         * Function that updates current month based on current date
         */
        updateCurrentMonth () {
            let currentDate = new Date()
            return currentDate.getMonth()
        },

        /**
         * Function that updates last month based on current date
         */
        updateLastMonth () {
            let currentDate = new Date()
            currentDate.setMonth(currentDate.getMonth() - 1)
            return currentDate.getMonth()
        },

        /**
         * Function that gets month as a number and returns its name as a String
         */
        getMonthString (n) {
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months[n]
        },

        /**
         * Function that returns if last month is active depending on the current day
         */
        checkCurrentDate() {
            let date = new Date()
            let currentDay = date.getDate()
            return this.activeLastMonth = currentDay > 5 ? false : true
        },

        /** 
         * Click event listener for 'Alle anzeigen' / 'Weniger anzeigen' button in file list 
         * Enables/disables allFilesVisible mode 
         */
        toggleFiles() {
            this.allFilesVisible = !this.allFilesVisible
        },

        /**
         * Function that takes a String and returns a new String based on its length
         * @param {String} name
         */
        shortenName(name) {
            return name.substring(0,8) + '....' + name.substr(name.length - 3)
        },
        
        /**
         * Click event listener for modal close button, for 'Ungeordnet' button, for 'Abbrechen' button and 'Jetzt hochladen' button
         * Function that resets assignments
         */
        resetAssignments() {
            this.month = null
            this.activeCategoryID = undefined
        },

        /**
         * Click event listener for close button and 'Abbrechen' button
         * Deletes files, so the files Array is empty again
         */
        deleteFiles() {
            this.files = []
        },

        /**
         * Click event listener for month buttons
         * Sets month variable to month
         * @param {Int} month 
         */
        setMonth(month) {
            if (this.month === month) {
                this.month = null
            } else {
                this.month = month
            }
        },

        /**
         * Click event listener for category buttons
         * Sets activeCategoryID variable to category id
         * @param {String} month 
         */
        setCategory(id) {
            if (this.activeCategoryID === id) {
                this.activeCategoryID = undefined
            } else {
                this.activeCategoryID = id
            }
        },

    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .hint {
        font-size: 12px;
        padding: 15px 0;
    }
    .upload__container {
        @media screen and (min-width: $large-screen) {
            display: flex;
            flex-direction: row-reverse;
        }
        .upload__field {
            @media screen and (min-width: $large-screen) {
                width: 50%;
            }
        }
        .categories {
            @media screen and (min-width: $large-screen) {
                width: 50%;
                padding-right: 20px;
            }
            &__header {
                border-bottom: 1px solid $default-grey;
                padding: 15px 0;
                margin-bottom: 30px;
                display: flex;
                flex-wrap: wrap;
                .dividingline {
                    padding: 0 5px;
                }
                a {
                    color: $default-grey;
                    font-size: 14px;
                    @media screen and (min-width: $medium-screen) {
                        font-size: 16px;
                    }
                    &.active {
                        color: $brand-blue;
                        font-family: "Gotham-Medium";
                    }
                }
            }
            &__buttons {
                padding: 15px 0 25px 0;
                max-width: 70%;
                display: flex;
                flex-wrap: wrap;
                @media screen and (min-width: $large-screen) {
                    max-width: none;
                }
                .btn--rounded {
                    padding: 5px 15px;
                    font-size: 12px;
                    line-height: 22px;
                    @media screen and (min-width: $medium-screen) {
                        font-size: 14px;
                        padding: 5px 15px;
                    }
                    @media screen and (min-width: $large-screen) {
                        font-size: 16px;
                        padding: 8px 15px;
                    }
                }
            }
            .headline {
                display: flex;
                align-items: center;
                h4 {
                    display: inline;
                }
                .info {
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $brand-blue;
                    color: #fff;
                    text-align: center;
                    margin-left: 5px;
                    cursor: pointer;
                    &__content {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 220px;
                        height: auto;
                        align-items: center;
                        text-align: left;
                        padding: 15px;
                        font-size: 12px;
                        line-height: 15px;
                        background-color: $brand-blue;
                        border-radius: 30px 30px 30px 0;
                        display: none;
                        box-shadow: 0px 4px 14px rgb(0 0 0 / 25%);
                        @media screen and (min-width: $medium-screen) {
                            width: 290px;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                    &:hover .info__content {
                        display: flex;
                    }
                    svg path {
                        fill: #fff;
                    }
                }
            }
        }
    }
    .add-receipt {
        .upload-scan {
            height: 290px;
            width: 100%;
            border-radius: $brand-border-r;
            background-color: rgba(0, 158, 224, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 1px dashed #009EE0;
            margin-top: 15px;
            @media screen and (min-width: $large-screen) {
                margin-top: 0;
            }
            &.active {
                background-color: rgba(0, 158, 224, 0.1);
            }

            .drop-label {
                margin-bottom: 30px;
                font-size: 12px;
                line-height: 15px;
                max-width: 330px;
                .upload-icon {
                    display: block;
                    margin: auto;
                    margin-bottom: 10px;
                }
                .text--grey {
                    display: block;
                    padding-bottom: 15px;
                }
            }
            .chooser-label {
                cursor: pointer;
                padding: 8px 15px;
                border-radius: 0 15px;
                margin: auto;
                &:hover {
                    background-color: $hover-blue;
                    color: #fff;
                }
            } 
            #files-input {
                display: none;
            }
        }
        .file-list-heading {
            display: block;
            padding: 15px 0;
            padding-left: 7.5px;
            @media screen and (min-width: $large-screen) {
                padding-top: 30px;
            }
        }
        .file-list {
            position: relative;
            margin-bottom: 30px;
            .showall-btn {
                position: absolute;
                right: 0;
                color: $brand-blue;
                padding: 10px;
                cursor: pointer;
                span {
                    padding-left: 10px;
                }
            }
        }
        .file-list ul {
            display: flex;
            flex-wrap: wrap;
            max-height: 300px;
            transition: max-height 0.5s;
            overflow: hidden;
            position: relative;
            @media screen and (min-width: $large-screen) {
                max-height: 200px;
            }
            &.active {
                max-height: 1000px;
                transition: max-height 0.5s;
            }
            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                pointer-events: none;
                background: linear-gradient(0deg, $bg-surface 0%, $bg-surface 10%, transparent 37%);
                opacity: 1;
                display: none;
                &.active {
                    display: block;
                }
                &.hidden {
                    display: none;
                }
            }
            li {
                list-style: none;
                width: calc(100% / 3 - 15px); // 15px because of the margin
                height: auto;
                background-color: rgba(0, 158, 224, 0.1);
                border: 1px solid $brand-blue;
                border-radius: $brand-border-r;
                margin: 0 7.5px;
                margin-bottom: 15px;
                font-size: 10px;
                text-align: center;
                padding: 10px 5px;
                color: $brand-deep-green;
                @media screen and (min-width: $medium-screen) {
                    width: calc(100% / 5 - 15px); // 15px because of the margin
                }
                .file-photo {
                    width: calc(100% - 10px);
                    height: 35px;
                    margin: auto;
                    margin-top: 15px;
                    @media screen and (min-width: $medium-screen) {
                        height: 65px;
                        margin-top: 30px;
                    }
                    @media screen and (min-width: $large-screen) {
                        margin-top: 10px;
                        height: 30px;
                    }
                    svg path {
                        fill: $grey-text;
                    }
                }
                .file-name {
                    white-space: nowrap;
                    overflow: hidden;
                    padding-top: 5px;
                }
                .file-footer {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    .file-size {
                        display: none;
                        @media screen and (min-width: $medium-screen) {
                            display: block;
                        }
                    }
                    .delete-btn {
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        top: 50%;
                        right: -10px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        @media screen and (min-width: $medium-screen) {
                            left: auto;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
    .footer-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: $medium-screen) {
            justify-content: flex-end;  
        }
    }
    .btn {
        display: inline-block;
        position: relative;
        margin-left: 30px;
        &--rounded {
            padding: 10px 15px;
            cursor: pointer;
            margin-bottom: 10px;
            &:hover {
                background-color: $hover-blue;
            }
            &.active {
                background-color: $brand-blue;
                &:hover {
                    background-color: $hover-blue;
                    svg.checkmark {
                        display: none;
                    }
                    svg.delete-btn {
                        display: block;
                    }
                }
                svg.checkmark {
                    display: block;
                }
                svg path {
                    fill: #fff;
                }
            }
            &.disabled {
                background-color: $disabled-grey;
                color: $default-grey;
                pointer-events: none;
            }
            svg {
                display: none;
            }
        }
        .loadingspinner {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        span.hidden {
            opacity: 0;
        }
        &.cancel-btn {
            margin: 0;
        }
    } 
</style>