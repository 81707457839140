<template>
    <div class="filter__wrapper">
        <div class="filter">
            <!-- code for mobile filters -->
            <div class="filter__button--mobile">
                <div class="btn--rounded" :class="{active: filterActive}" @click="activeMobileFilter = true">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7974 11.8706L19.0588 5.14061H5.49007L10.8068 11.8706C10.9175 12.0447 10.9729 12.2187 10.9729 12.3928V18.4265L13.5759 19.7609V12.3347C13.5759 12.1607 13.6867 11.9866 13.7974 11.8706ZM21.1079 4.90855L15.0158 12.6248V20.9213C15.0158 21.5015 14.462 21.8496 14.019 21.6175L9.97604 19.5869C9.75451 19.4709 9.53298 19.2388 9.53298 18.8907V12.6248L3.44092 4.90855C3.05324 4.44441 3.38554 3.69019 3.99474 3.69019H20.5541C21.1633 3.69019 21.4956 4.38639 21.1079 4.90855Z" fill="#58584D"/>
                    </svg>
                    <span>Filter</span>
                </div>
                <div class="filter__content filter__content--mobile" v-if="activeMobileFilter">
                    <div class="filter__content-header">
                        <h3>Filter</h3>
                        <div class="close-btn" @click="activeMobileFilter = false">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#58584D"/>
                            </svg>
                        </div>
                    </div>
                    <div class="dropdowns">
                        <Dropdown class="dropdown--mobile"
                            @selected="handleMonthSelect"
                            :selectedItemID="$store.state.receipt.fromDateMonth"
                            :placeholder="'Monat'"
                            :listItems="months"/>
                        <Dropdown class="dropdown--mobile"
                            @selected="handleCategorySelect"
                            :selectedItemID="$store.state.receipt.filterCategoryID" 
                            :placeholder="'Art des Belegs'"
                            :listItems="categories"/>
                    </div>
                    <div class="filter__content-footer">
                        <a @click.prevent="resetFilters" href="">zurücksetzen</a>
                        <div @click="activeMobileFilter = false" class="btn">{{ receiptsCount() }} gefunden</div>
                    </div>
                </div>  
            </div>

            <!-- code for selection mode -->
            <div v-if="!activeDesktopFilter && !gridView" class="btn--rounded" :class="{active: activeSelection}" @click="$store.commit('setCheckboxActive', !activeSelection)">
                <svg class="filter-btn" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2798 0.939941C5.65365 0.939941 0.279785 6.31379 0.279785 12.9399C0.279785 19.566 5.65365 24.9399 12.2798 24.9399C18.9059 24.9399 24.2798 19.566 24.2798 12.9399C24.2699 6.30393 18.9059 0.939941 12.2798 0.939941ZM12.2798 23.2144C6.6101 23.2144 1.99548 18.5997 1.99548 12.93C1.99548 7.26037 6.6101 2.64575 12.2798 2.64575C17.9494 2.64575 22.5641 7.26037 22.5641 12.93C22.5542 18.6096 17.9494 23.2144 12.2798 23.2144Z" fill="#151917"/>
                    <path d="M16.9732 8.55194L16.3421 8.18712C16.1153 8.04907 15.8195 8.13781 15.6815 8.36459L11.2345 16.0754L9.01593 12.2397C8.87789 12.013 8.59194 11.9242 8.35529 12.0622L7.72423 12.4271C7.49745 12.5651 7.41856 12.8609 7.54675 13.0877L10.3865 18.0179C10.4753 18.1658 10.633 18.2545 10.7908 18.2545H11.6782C11.836 18.2446 11.9839 18.1559 12.0726 18.0179L17.1507 9.2225C17.2887 8.98585 17.2098 8.68999 16.9732 8.55194Z" fill="#151917"/>
                </svg>
                <div class="delete-btn">
                    <svg width="16" height="16" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                    </svg>
                </div>
                Auswahl
            </div>
            <div v-if="activeSelection && !gridView" class="filter__selection">
                <div @click="$store.commit(`receipt/toggleAllReceiptsChecked`)">
                    <svg class="larger-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.4623 5.43889L16.8223 5.06889C16.5923 4.92889 16.2923 5.00888 16.1523 5.24888L9.15227 17.3489C9.01227 17.5789 9.10227 17.8789 9.33227 18.0189L9.97227 18.3889C10.2023 18.5289 10.5023 18.4389 10.6423 18.2089L17.6423 6.10893C17.7723 5.87893 17.6923 5.57889 17.4623 5.43889ZM13.4823 5.43889L12.8423 5.06889C12.6123 4.92889 12.3123 5.00888 12.1723 5.24888L5.80227 16.2589L3.55227 12.3589C3.41227 12.1289 3.12227 12.0389 2.88227 12.1789L2.24227 12.5489C2.01227 12.6889 1.93227 12.9889 2.06227 13.2189L4.94227 18.2189C5.03227 18.3789 5.19227 18.4589 5.36227 18.4589H6.24227C6.41227 18.4589 6.57226 18.3689 6.66226 18.2089L13.6623 6.10893C13.8023 5.86893 13.7223 5.56889 13.4823 5.43889ZM21.4423 5.43889L20.8023 5.06889C20.5723 4.92889 20.2723 5.00888 20.1323 5.24888L13.1323 17.3489C12.9923 17.5789 13.0823 17.8789 13.3123 18.0189L13.9523 18.3889C14.1823 18.5289 14.4823 18.4389 14.6223 18.2089L21.6223 6.10893C21.7523 5.87893 21.6723 5.57889 21.4423 5.43889Z" fill="#151917"/>
                    </svg>
                    Alle auswählen
                </div>
                <div @click="openSortModal">
                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.71008 10.62H16.4101C16.6601 10.62 16.8701 10.41 16.8701 10.16V9.45996C16.8701 9.20996 16.6601 9 16.4101 9H11.7201L15.6001 5.12C15.7901 4.93 15.7901 4.62005 15.6001 4.43005L15.0701 3.90002C14.8801 3.71002 14.5701 3.71002 14.3801 3.90002L11.4401 6.83997V0.800049C11.4401 0.530049 11.2201 0.310059 10.9501 0.310059H10.2101C9.94008 0.310059 9.72009 0.530049 9.72009 0.800049V6.83997L6.78009 3.90002C6.59009 3.71002 6.28009 3.71002 6.09009 3.90002L5.56009 4.43005C5.37009 4.62005 5.37009 4.93 5.56009 5.12L9.4201 9H4.72009C4.47009 9 4.2601 9.20996 4.2601 9.45996V10.16C4.2501 10.42 4.46008 10.62 4.71008 10.62ZM4.71008 13.98H16.4101C16.6601 13.98 16.8701 13.77 16.8701 13.52V12.8199C16.8701 12.5699 16.6601 12.36 16.4101 12.36H4.71008C4.46008 12.36 4.25009 12.5699 4.25009 12.8199V13.52C4.25009 13.78 4.46008 13.98 4.71008 13.98ZM20.1501 0.329956H19.4101C19.1401 0.329956 18.9201 0.479956 18.9201 0.829956V19.4H2.0701V0.839966C2.0701 0.489966 1.85008 0.329956 1.58008 0.329956H0.840088C0.570088 0.329956 0.350098 0.489966 0.350098 0.839966V20.63C0.350098 20.9 0.560078 21.11 0.830078 21.12H0.840088H20.1601H20.1701C20.4401 21.11 20.6501 20.9 20.6501 20.63V0.819946C20.6401 0.479946 20.4201 0.329956 20.1501 0.329956ZM4.71008 17.35H16.4101C16.6601 17.35 16.8701 17.14 16.8701 16.89V16.1899C16.8701 15.9399 16.6601 15.73 16.4101 15.73H4.71008C4.46008 15.73 4.25009 15.9399 4.25009 16.1899V16.89C4.25009 17.14 4.46008 17.35 4.71008 17.35Z" fill="#151917"/>
                    </svg>
                    Einsortieren
                </div>
                <div @click="$store.dispatch(`receipt/downloadSelectedReceipts`)">
                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.71008 10.9202H16.4101C16.6601 10.9202 16.8701 10.7101 16.8701 10.4601V9.76018C16.8701 9.51018 16.6601 9.30021 16.4101 9.30021H11.3501V3.73015L14.2901 6.67021C14.4801 6.86021 14.7901 6.86021 14.9801 6.67021L15.5101 6.14018C15.7001 5.95018 15.7001 5.64012 15.5101 5.45012L11.3601 1.30021L10.8301 0.770186L10.8201 0.760176C10.6301 0.570176 10.3201 0.570176 10.1301 0.760176L9.6001 1.29021C9.5901 1.30021 9.5801 1.32012 9.5701 1.33012L5.46008 5.44011C5.27008 5.63011 5.27008 5.94017 5.46008 6.13017L5.99008 6.6602C6.18008 6.8502 6.49008 6.8502 6.68008 6.6602L9.62009 3.72014V9.29021H4.7001C4.4501 9.29021 4.24008 9.50017 4.24008 9.75017V10.4501C4.25008 10.7201 4.46008 10.9202 4.71008 10.9202ZM4.71008 14.2902H16.4101C16.6601 14.2902 16.8701 14.0801 16.8701 13.8301V13.1302C16.8701 12.8802 16.6601 12.6702 16.4101 12.6702H4.71008C4.46008 12.6702 4.25009 12.8802 4.25009 13.1302V13.8301C4.25009 14.0801 4.46008 14.2902 4.71008 14.2902ZM20.1501 0.630171H19.4101C19.1401 0.630171 18.9201 0.780171 18.9201 1.13017V19.7001H2.0701V1.14018C2.0701 0.790181 1.85008 0.630171 1.58008 0.630171H0.840088C0.570088 0.630171 0.350098 0.790181 0.350098 1.14018V20.9302C0.350098 21.2002 0.560078 21.4102 0.830078 21.4202H0.840088H20.1601H20.1701C20.4401 21.4102 20.6501 21.2002 20.6501 20.9302V1.12016C20.6401 0.780161 20.4201 0.630171 20.1501 0.630171ZM4.71008 17.6502H16.4101C16.6601 17.6502 16.8701 17.4401 16.8701 17.1901V16.4902C16.8701 16.2402 16.6601 16.0302 16.4101 16.0302H4.71008C4.46008 16.0302 4.25009 16.2402 4.25009 16.4902V17.1901C4.25009 17.4401 4.46008 17.6502 4.71008 17.6502Z" fill="#151917"/>
                    </svg>
                    Exportieren
                </div>
                <div @click="handleMergeClick">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.5901 1.63V1.23999C18.5901 0.96999 18.3701 0.75 18.1001 0.75H17.8001C17.8001 0.75 17.8001 0.75 17.7901 0.75H1.19012H0.880127C0.610127 0.75 0.390137 0.96999 0.390137 1.23999V1.97998C0.390137 2.12998 0.46012 2.24997 0.56012 2.33997L6.35013 8.13L7.61011 17.5C7.66011 17.84 7.90013 18.0801 8.16013 18.0601C8.19013 18.0701 8.22012 18.08 8.25012 18.08H10.7101C10.7401 18.08 10.7701 18.0701 10.8001 18.0601H10.8201C11.0901 18.1001 11.3401 17.84 11.3901 17.5L11.9401 13.4C12.0601 13.41 12.1701 13.42 12.2901 13.42C15.7601 13.42 18.5901 10.6 18.6001 7.12L18.5901 1.63ZM11.0201 7.33997L9.81012 16.37H9.20013L7.99011 7.33997L3.13013 2.47998H15.8801L11.0201 7.33997ZM16.8401 7.14001C16.8401 9.65001 14.8001 11.7 12.2801 11.7C12.2401 11.7 12.2001 11.6899 12.1701 11.6899L12.6501 8.14001L16.8401 3.94995V7.14001Z" fill="#151917"/>
                    </svg>
                    Zusammenführen
                </div>
                <div @click="deleteReceipt">
                    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.3882 3.9634H13.2138V0.983118C13.2138 0.696934 12.977 0.469971 12.6908 0.469971H8.93092C8.64474 0.469971 8.4079 0.696934 8.4079 0.983118V3.9634H1.23355C0.967103 3.9634 0.75 4.1805 0.75 4.44694V5.1772C0.75 5.44364 0.967103 5.66077 1.23355 5.66077H20.3783C20.6447 5.66077 20.8618 5.44364 20.8618 5.1772V4.44694C20.8717 4.17063 20.6546 3.9634 20.3882 3.9634ZM12.1678 3.9634H9.45395V1.50614H12.1678V3.9634Z" fill="#151917"/>
                        <path d="M20.4866 7.47645L19.7662 7.32843C19.5096 7.26922 19.2333 7.50605 19.1643 7.84157L16.0853 22.7824H14.2597L15.6116 7.53565H14.5656L13.2136 22.7824H11.3781V7.53565H10.3419V22.7824H8.44715L7.13465 7.53565H6.09846L7.41097 22.7824H5.66426L2.54583 7.84157C2.47675 7.50605 2.21031 7.27908 1.94386 7.32843L1.22347 7.47645C0.96689 7.53566 0.808989 7.85145 0.878068 8.18697L4.17412 23.9666C4.20373 24.1146 4.27281 24.2232 4.36163 24.3218C4.3715 24.3317 4.37149 24.3416 4.38136 24.3416C4.42083 24.381 4.47018 24.4106 4.51952 24.4304C4.52939 24.4304 4.52938 24.4403 4.53925 24.4403C4.58859 24.46 4.64781 24.4699 4.70702 24.4699C4.71689 24.4699 4.71689 24.4699 4.72675 24.4699H17.0425C17.0524 24.4699 17.0524 24.4699 17.0623 24.4699C17.1116 24.4699 17.1708 24.46 17.2202 24.4403C17.2399 24.4304 17.2596 24.4205 17.2695 24.4107C17.2991 24.3909 17.3386 24.3712 17.3682 24.3416C17.3879 24.3218 17.4077 24.3021 17.4274 24.2725C17.4965 24.1837 17.5557 24.085 17.5853 23.9567L20.8419 8.1771C20.9011 7.85144 20.7432 7.53566 20.4866 7.47645Z" fill="#151917"/>
                    </svg>
                    Löschen
                </div>
            </div>
            <MergeModal 
                v-if="mergeConfirmationModalIsOpen"
                @merge-receipt="mergeReceipts"
                @close-modal="closeMergeModal"
            > 
            </MergeModal>
            <SortModal 
                v-if="sortModalIsOpen" 
                :categoryItems="categories"
                :monthItems="months"
                @close-modal="closeSortModal" 
                @category-select="selectedCategoryID = $event"
                @month-select="selectedMonth = $event"
                @handlemoveclick="handleMoveClick"
            />

            <!-- code for desktop filters -->
            <div v-if="!activeSelection" class="filter__button--desktop">
                <div class="btn--rounded" :class="{active: activeDesktopFilter}" @click="toggleDesktopFilter">
                    <svg class="filter-btn" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7974 11.8706L19.0588 5.14061H5.49007L10.8068 11.8706C10.9175 12.0447 10.9729 12.2187 10.9729 12.3928V18.4265L13.5759 19.7609V12.3347C13.5759 12.1607 13.6867 11.9866 13.7974 11.8706ZM21.1079 4.90855L15.0158 12.6248V20.9213C15.0158 21.5015 14.462 21.8496 14.019 21.6175L9.97604 19.5869C9.75451 19.4709 9.53298 19.2388 9.53298 18.8907V12.6248L3.44092 4.90855C3.05324 4.44441 3.38554 3.69019 3.99474 3.69019H20.5541C21.1633 3.69019 21.4956 4.38639 21.1079 4.90855Z" fill="#58584D"/>
                    </svg>
                    <div class="delete-btn">
                        <svg width="16" height="16" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                        </svg>
                    </div>

                    Filter
                </div>
                <div class="filter-dropdowns" :class="{active: activeDesktopFilter}">
                    <Dropdown class="dropdown--desktop"
                        @selected="handleMonthSelect"
                        :selectedItemID="$store.state.receipt.fromDateMonth"
                        :placeholder="'Monat'"
                        :listItems="months"/>
                    <Dropdown class="dropdown--desktop"
                        @selected="handleCategorySelect"
                        :selectedItemID="$store.state.receipt.filterCategoryID"
                        :placeholder="'Art des Belegs'"
                        :listItems="categories"/>
                </div>
            </div>

            <!-- searchbar -->
            <SearchBar
                v-if="!activeDesktopFilter && !activeSelection"
                activeSearchState="receipt/setActiveSearchInput"
                commitSearchAction="receipt/commitSearch"
                closeSearchAction="receipt/closeSearch"
            ></SearchBar>
        </div>

        <div class="listview-btn--mobile">
            <ListViewToggleButton />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import SortModal from '../partials/modals/SortModal'
import MergeModal from '../partials/modals/MergeModal'
import Dropdown from '../partials/Dropdown'
import SearchBar from '../partials/SearchBar'
import ListViewToggleButton from '../partials/ListViewToggleButton'

export default {
    name: 'FilterCollection',
    components: {
        SortModal,
        MergeModal,
        Dropdown,
        SearchBar,
        ListViewToggleButton
    },
    data() {
        return {
            /**
             * Detects if mobile filters are active
             */
            activeMobileFilter: false,
            /**
             * Detects if desktop filters are active
             */
            activeDesktopFilter: false,
            /**
             * Detects if sort Modal is open
             */
            sortModalIsOpen: false,
            /**
             * ID of the selected category in the sort modal
             */
            selectedCategoryID: undefined,
            /**
             * Month number (1-indexed) selected in sort modal
             */
            selectedMonth: undefined,
            /**
             * Modal that prompts user to confirm destructive merge is open
             */
            mergeConfirmationModalIsOpen: false
        }
    },
    methods: {
        /**
         * Click event listener for desktop filter button
         * toggles display mode of filter dropdowns and resets filter when filters are hidden
         */
        toggleDesktopFilter() {
            if (this.activeDesktopFilter) {
                this.resetFilters()
                this.activeDesktopFilter = false
            } else {
                this.activeDesktopFilter = true
            }
        },

        /**
         * Function that detects which category id is selected, sets this as a filter and reloads the content based on this filter
         * @param {String} id
         */
        handleCategorySelect(id) {
            this.$store.dispatch(`receipt/filterCategoryID`, id)
        },

        /**
         * Function that detects which month is selected, sets this as a filter and reloads the content based on this filter
         * @param {Int} month
         */
        handleMonthSelect(month) {            
            this.$store.dispatch(`receipt/filterMonthRange`, {
                fromDateMonth: month,
                toDateMonth: month
            })
        },

        /**
         * Function that gets month as an Integer and returns month name as a string
         * @param {Int} n
         */
        getMonthString (n) {
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months[n]
        },

        /** 
         * Click event listener for 'zurücksetzen' Button
         * Function that resets all filters
         */
        resetFilters() {
            this.$store.dispatch(`receipt/resetFilters`)
        },

        /**
         * Handle click on "zusammenführen", show modal if merge will destroy user-defined metadata.
         */
        handleMergeClick() {
            if(this.selectedHaveMetadata) {
                this.mergeConfirmationModalIsOpen = true
                this.$store.state.receipt.activeModal = true
            } else {
                this.mergeReceipts()
            }
        },

        closeMergeModal() {
            this.$store.state.receipt.activeModal = false
            this.mergeConfirmationModalIsOpen = false
        },

        /**
         * Merge selected receipts.
         */
        async mergeReceipts() {
            await this.$store.dispatch(`receipt/mergeReceipts`)
            this.$store.commit(`receipt/setSelectedReceiptIDs`, [])
            this.mergeConfirmationModalIsOpen = false
        },

        /**
         * Function that moves selected files to another folder 
         */
        handleMoveClick() {
            this.$store.dispatch(`receipt/setMonthCategoryReceipts`, {
                categoryID: this.selectedCategoryID,
                month: this.selectedMonth
            })

            this.$store.state.receipt.activeModal = false
            this.sortModalIsOpen = false
            this.selectedCategoryID = undefined
            this.selectedMonth = undefined
            this.$store.commit(`receipt/setSelectedReceiptIDs`, [])
        },

        deleteReceipt() {
            this.$store.dispatch(`receipt/deleteSelectedReceipts`)
            this.$store.state.receipt.selectedReceiptIDs = []
        },

        /**
         * Function that returns a string depending on receipts length
         */
        receiptsCount() {
            let receiptsLength = this.$store.state.receipt.receipts.length
            
            let str = receiptsLength === 1 ? ' Beleg' : ' Belege'
            return receiptsLength + str
        },

        openSortModal() {
            this.$store.state.receipt.activeModal = true
            this.sortModalIsOpen = true 
        },

        closeSortModal() {
            this.$store.state.receipt.activeModal = false
            this.sortModalIsOpen = false 
        }
    },
    computed: {
        ...mapState({
            /**
             * Detects if Checkbox mode for list items is active
             */
            activeSelection: state => state.checkboxActive,
            
            /**
             * Collects all given categories from store
             */
            categories: state => {
                return Object.values(state.receipt.categories)
                    .map(category => ({
                        id: category.id,
                        name: category.name
                    }))
            },
            /**
             * Detects if the gridView mode for listed items is active or not 
             */
            gridView: state => state.gridView,
            
            selectedHaveMetadata(state, getters) {
                return getters[`receipt/selectedReceiptsHaveMetadata`]
            }
        }),

        /**
         * Function that returns active months 
         * (either only current month or current and last month)
         */
        months() {
            let date = new Date()
            const currentDay = date.getDate()
            const currentMonth = date.getMonth()
            if (currentDay < 5) {
                let lastMonth = new Date()
                lastMonth.setMonth(lastMonth.getMonth() - 1)
                const lastMonthNo = lastMonth.getMonth()

                return [{
                    id: lastMonthNo + 1,
                    name: this.getMonthString(lastMonthNo)
                }, {
                    id: currentMonth + 1,
                    name: this.getMonthString(currentMonth)
                }]
            } else {
                return [{
                    id: currentMonth + 1,
                    name: this.getMonthString(currentMonth)
                }]
            }
        },
        
        /**
         * returns true if any user-set filters are active
         */
        filterActive() {
            const currentFilterCategoryID = this.$store.state.receipt.filterCategoryID
            const currentFilterMonthNumber = this.$store.state.receipt.fromDateMonth
            const currentFilterYearNumber = this.$store.state.receipt.fromDateYear
            return currentFilterCategoryID || currentFilterMonthNumber || currentFilterYearNumber
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .filter {
        position: relative;
        display: flex;
        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0 5px 7.5px;
        }
        &__button--mobile {
            display: flex;
            font-size: 12px;
            line-height: 22px;
            .btn--rounded {
                display: flex;
                align-items: center;
                @media screen and (min-width: $medium-screen) {
                    display: none;
                }
                &:hover {
                    background: $hover-blue;
                    cursor: pointer;
                }
            }
        }
        &__button--desktop {
            display: none;
            cursor: pointer;
            @media screen and (min-width: $medium-screen) {
                display: flex;
            }
            .filter-dropdowns {
                display: none;
                &.active {
                    display: flex;
                }
                .dropdown {
                    width: 230px;
                    margin-right: 15px;
                }
            }
        }
        &__selection {
            display: none;
            align-items: center;
            @media screen and (min-width: $medium-screen) {
                display: flex;
            }
            > div {
                margin-right: 20px;
                font-size: 14px;
                line-height: 22px;
                font-family: "Gotham-Medium";
                color: $grey-text;
                display: flex;
                align-items: center;
                cursor: pointer;
                -ms-user-select: None;
                -moz-user-select: None;
                -webkit-user-select: None;
                user-select: None;
                &:hover {
                    color: $hover-blue;
                    svg path {
                        fill: $hover-blue;
                    }
                }
                svg {
                    margin-right: 5px;
                    height: 20px;
                    &.larger-icon {
                        height: 25px;
                    }
                    path {
                        fill: $grey-text;
                    }
                }
            }
        }
        &__content {
            position: fixed;
            background: #fff;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 10;
            &--mobile {
                @media screen and (min-width: $medium-screen) {
                    display: none;
                }
            }
            &-header {
                background-color: $light-grey;
                border-radius: 0 0 0 30px;
                height: 65px;
                padding-left: 15px;
                display: flex;
                align-items: center;
                position: relative;
                .close-btn {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
            &-footer {
                position: fixed;
                bottom: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 200px;
                a {
                    outline: none;
                }
                .btn {
                    background-color: $brand-blue;
                    color: #fff;
                    margin-top: 30px;
                }
            }
            &--desktop {
                .dropdown--desktop {
                    display: none;
                    @media screen and (min-width: $medium-screen) {
                        display: inline-block;
                    }
                    @media screen and (min-width: $large-screen) {
                        width: 200px;
                    }
                }
            }
        }
        > .btn--rounded {
            font-size: 12px;
            line-height: 22px;
            cursor: pointer;
            display: none;
            @media screen and (min-width: $medium-screen) {
                font-size: 14px;
                border-radius: 30px;
                display: flex;
            }
            @media screen and (min-width: $large-screen) {
                font-size: 16px;
            }
        }
    }
    .listview-btn--mobile {
        @media screen and (min-width: $medium-screen) {
            display: none;
        }
    }

    .btn--rounded {
        @media screen and (min-width: $medium-screen) {
            border-radius: 30px;
            padding: 8px 15px;
            font-size: 14px;
        }
        @media screen and (min-width: $large-screen) {
            font-size: 16px;
        }
        &:hover {
            background-color: $hover-blue;
        }
        &.active {
            background-color: $brand-blue;
            &:hover {
                background-color: $hover-blue;
                svg.filter-btn {
                    display: none;
                }
                .delete-btn {
                    display: flex;
                }
            }
        }
        .delete-btn {
            display: none;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            margin: 0 5px;
        }
        svg path {
            fill: #fff;
        }
        svg rect {
            stroke: #fff;
        }
    }
    .dropdowns {
        position: fixed;
        top: 100px;
        left: 0;
        width: 100%;
        padding: 15px;
        .dropdown--mobile {
            margin-bottom: 30px;
        }
    }
</style>