<template>
    <div class="reports">
        <ReceiptviewSubheader
            :title="categoryName"
            :btnName="reports.length ? 'Angezeigte Belege exportieren' : ''"
            @click-btn="exportClick"
            backLinkName="zurück"
        >
        </ReceiptviewSubheader>

        <div v-if="$store.getters['auth/isStaff']" class="start">
            <div v-if="!userSelected">Zum Report-Upload bitte zunächst einen Nutzer aus der <router-link  to="/home">User-Liste</router-link> wählen.</div>
            <input
                class="fileupload"
                type="file"
                id="files-input"
                ref="filesInput"
                name="files"
                :disabled="!userSelected"
                @change="handleFileChanges"
            />
            <input
                class="textinput"
                placeholder="Text einfügen"
                type="text"
                v-model="reportTitle"
                :disabled="!userSelected"
            >
            <select
                v-if="monthSelectActive"
                v-model="reportMonth"
                class="selectinput"
                :disabled="!userSelected"
            >
                <option
                    v-for="month in months"
                    :key="month.id"
                    :value="month.id"
                >{{month.name}}</option>
            </select>
            <input
                v-if="yearSelectActive"
                class="textinput year"
                type="number"
                v-model="reportYear"
                :disabled="!userSelected"
            >
            <button @click="createReport" v-if="userSelected">Report erstellen</button>
        </div>

        <FilterReports :yearActive="yearSelectActive" :monthActive="monthSelectActive" />

        <FileList
            :files="reports"
            downloadAction="report/downloadReport"
            deleteAction="report/deleteReport"
            :deleteActive="$store.getters['auth/isStaff']"
            :infoString="filterInfoString"
            :resetActive="timeFiltersActive"
            resetAction="report/resetTimeFilters"
            :loading="loading"
        >
        </FileList>
        <div class="empty-notice" v-if="!loading && !reports.length && searchStatus">Keine Suchergebnisse.</div>
        <div class="empty-notice" v-if="!loading && !reports.length && !searchStatus">Keine Reports vorhanden.</div>
    </div>
</template>

<script>
    import ReceiptviewSubheader from '../../partials/ReceiptView_Subheader'
    import FilterReports from '../../filter/Filter_Reports'
    import FileList from '../../FileList/FileList'

    import { mapState } from 'vuex'

    export default {
        name: 'ReportFiles',
        components: {
            ReceiptviewSubheader,
            FilterReports,
            FileList,
        },
        data () {
            return {
                reportTitle: '',
                reportFiles: [],
                reportYear: 0,
                reportMonth: 0
            }
        },
        computed: {
            userSelected() {
                return this.$store.state.staff.selectedUserId !== undefined
            },
            months() {
                const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
                return months.map((month, index) => ({id: index + 1, name: month}))
            },
            monthSelectActive() {
                return this.$store.state.report.filterCategoryID === process.env.VUE_APP_MONATSABSCHLUESSE_CATEGORY_ID
            },
            yearSelectActive() {
                return this.monthSelectActive || this.$store.state.report.filterCategoryID === process.env.VUE_APP_JAHRESABSCHLUESSE_CATEGORY_ID
            },
            filterInfoString() {

                const currentFilterMonthNumber = this.$store.state.report.fromDateMonth
                const currentFilterYearNumber = this.$store.state.report.fromDateYear

                let str = 'Angezeigt werden: '
                if (!this.timeFiltersActive) return str + this.categoryName
                if (currentFilterYearNumber) {
                    str += currentFilterYearNumber + " | "
                }
                if (currentFilterMonthNumber) {
                    str += this.months.map(m => m.name)[currentFilterMonthNumber - 1] + " | "
                }
                str += this.categoryName
                return str
            },
            ...mapState({
                categoryName: state => {
                    const category = state.report.categories.find(c => c.id === state.report.filterCategoryID)
                    return category ? category.name : ''
                },
                loading: state => state.report.loading,
                timeFiltersActive: state => state.report.fromDateMonth !== undefined || state.report.fromDateYear !== undefined,
                searchStatus: state => state.report.activeSearch
            }),
            reports() {
                return this.$store.getters['report/reports']
            }
        },
        mounted() {
            const now = new Date()
            this.reportYear = now.getFullYear()
            this.reportMonth = now.getMonth() + 1
        },
        methods: {
            /**
             * Click event listener for export button
             */
            exportClick() {
                this.$store.dispatch('report/downloadShownReports')
            },

            /**
             * Change event listener for files upload
             */
            handleFileChanges(ev) {
                ([...ev.target.files]).forEach(f => {
                    this.reportFiles.push(f)
                })
            },

            /**
             * Click event listener for 'Report erstellen' button
             * stores report it in backend
             */
            createReport() {
                this.$store.dispatch('report/createReport', {
                    files: this.reportFiles,
                    title: this.reportTitle,
                    month: this.monthSelectActive ? this.reportMonth : null,
                    year: this.yearSelectActive ? this.reportYear : null
                })
                this.reportTitle = ''
                this.$refs.filesInput.value = null
                this.reportFiles = []
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .reports {
        .empty-notice {
            margin-left: 7.5px;   
        }
    }
    .content-cards {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        .icon {
            height: 50px;
            @media screen and (min-width: $medium-screen) {
                height: 70px;
            }
        }
    }
    .start {
        padding: 7.5px;
        margin-bottom: 20px;
        button {
            font-family: "Gotham-Medium"; 
            color: $brand-blue;
            cursor: pointer;
            padding: 5px 10px;
            &:hover {
                color: $hover-blue;
            }
        }
        input.textinput {
            display: inline-block;
            border: none;
            border-bottom: 1px solid $default-grey;
            border-radius: 0;
            margin-right: 10px;
            outline: none;
            padding: 9px 0;
            color: $brand-deep-green;
            background-color: transparent;
            font-family: "Gotham-Regular";
            @media screen and (min-width: $medium-screen) {
                padding: 15px 0;
            }
            &.bold {
                font-family: "Gotham-Medium";
            }
            &.year {
                width: 50px;
            }
            &::placeholder {
                font-family: "Gotham-Regular";
                color: $default-grey;
            }
        }
        input.fileupload {
            color: $default-grey;
            font-family: "Gotham-Regular";
        }
        input.selectinput {
            margin-right: 10px;
        }
    }
    .reportfiles-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 7.5px 30px 7.5px;
        @media screen and (min-width: $medium-screen) {
            border-top: none;
            border-bottom: 1px solid $default-grey;
            padding-top: 30px;
            margin-top: 0;
        }
        &__info {
            font-size: 10px;
            line-height: 15px;
            padding: 5px 0;
            display: flex;
            align-items: center;
            .selectioncount {
                color: $brand-blue;
                font-size: 14px;
                line-height: 22px;
                display: none;
                margin-bottom: 3px;
                @media screen and (min-width: $medium-screen) {
                    display: block;
                }
            }
        }
    }
</style>