<template>
    <div ref="toggleBtn" class="options-btn" @click="toggleActions">
        <span class="options-btn__circle"></span>
        <span class="options-btn__circle"></span>
        <span class="options-btn__circle"></span>
        <ul :class="{hidden: !isActive}">
            <li v-if="!isArchived" @click.stop="isActive = false, $emit('edit-receipt')">
                <span>Beleg bearbeiten</span>
            </li>
            <li @click.stop="isActive = false, $emit('download')">
                <span>Herunterladen</span>
            </li>
            <li v-if="!isArchived" @click.stop="isActive = false, $emit('delete-receipt')">
                <span>Beleg löschen</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ReceiptActions',
    data() {
        return {
            /**
             * Detects if dropdown is active 
             */
            isActive: false,
            /**
             * Variable to store the outside click handler
             */
            onOutsideClickHandler: null,
        }
    },
    props: [
        /**
         * receipt archived status to disable certain actions
         */
        'isArchived'
    ],
    methods: {
        /**
         * Function that detects outside click
        */
        createOutsideClickHandler () {
            return function (event) {
                if (!(this.$el === event.target || this.$el.contains(event.target))) {
                    this.deactivate()
                }
            }.bind(this)
        },

        /**
         * Click event listener for Dropdown
         * Function that activates or deactivates actions dropdown depending on its current state
         */
        toggleActions () {
            !this.isActive 
                ? this.activate()
                : this.deactivate()
        },

        /**
         * Function that activates an actions dropdown and starts the outside click event listener 
         */
        activate () {
            this.isActive = true
            this.onOutsideClickHandler = this.createOutsideClickHandler()
            document.body.addEventListener('click', this.onOutsideClickHandler)
        },

        /**
         * Function that deactivates the dropdown and deletes the outside click event listener 
         */
        deactivate () {
            this.isActive = false
            document.body.removeEventListener('click', this.onOutsideClickHandler)
        },
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .options-btn {
        position: absolute;
        top: 5px;
        right: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        -ms-user-select: None;
        -moz-user-select: None;
        -webkit-user-select: None;
        user-select: None;
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: flex;
            right: 20px;
            top: 10px;
        }
        &:hover {
            .options-btn__circle {
                background-color: $hover-blue;
            }
        }
        &__circle {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: $brand-deep-green;
            margin: 1.5px; 
            pointer-events: none;
        }
        ul {
            position: absolute;
            top: 40px; // height of toggle button 
            right: 0;
            width: 200px;
            background-color: #fff;
            border-radius: 0 0 0 30px;
            font-size: 16px;
            line-height: 22px;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
            z-index: 10;
            &.hidden {
                display: none;
            }
            li {
                list-style: none;
                padding: 10px 30px;
                &:hover span {
                    color: $hover-blue;
                }
                span {
                    color: $grey-text;
                }
            }
        }
    }
</style>