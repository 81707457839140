<template>
    <div ref="content">
        {{receiptMonth}} <span v-if="receiptType !== null && receiptMonth !== null">|</span> {{receiptType}}
    </div>
</template>

<script>
export default {
    name: 'ReceiptType',
    props: {
        receiptMonth: {
            type: String,
        },
        receiptType: {
            type: String,
        }
    }
}
</script>