import Vue from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

import LoadScript from 'vue-plugin-load-script';
import { preInitWidget } from './lib/freshdeskWidget'

Amplify.configure(aws_exports);
Vue.config.productionTip = false

// load FreshDesk widget
window.widgetLoaded = false
Vue.use(LoadScript);
preInitWidget()
Vue.loadScript("https://euc-widget.freshworks.com/widgets/80000001409.js")
  .then(() => {
    window.widgetLoaded = true
  })

new Vue({
  render: h => h(App),
  store: store,
  router
}).$mount('#app')
