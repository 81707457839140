<template>
    <div class="filter__wrapper">
        <div class="filter">
            <div v-if="!gridView" class="btn--rounded" :class="{active: activeSelection}" @click="$store.commit('setCheckboxActive', !activeSelection)">
                <svg class="filter-btn" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2798 0.939941C5.65365 0.939941 0.279785 6.31379 0.279785 12.9399C0.279785 19.566 5.65365 24.9399 12.2798 24.9399C18.9059 24.9399 24.2798 19.566 24.2798 12.9399C24.2699 6.30393 18.9059 0.939941 12.2798 0.939941ZM12.2798 23.2144C6.6101 23.2144 1.99548 18.5997 1.99548 12.93C1.99548 7.26037 6.6101 2.64575 12.2798 2.64575C17.9494 2.64575 22.5641 7.26037 22.5641 12.93C22.5542 18.6096 17.9494 23.2144 12.2798 23.2144Z" fill="#151917"/>
                    <path d="M16.9732 8.55194L16.3421 8.18712C16.1153 8.04907 15.8195 8.13781 15.6815 8.36459L11.2345 16.0754L9.01593 12.2397C8.87789 12.013 8.59194 11.9242 8.35529 12.0622L7.72423 12.4271C7.49745 12.5651 7.41856 12.8609 7.54675 13.0877L10.3865 18.0179C10.4753 18.1658 10.633 18.2545 10.7908 18.2545H11.6782C11.836 18.2446 11.9839 18.1559 12.0726 18.0179L17.1507 9.2225C17.2887 8.98585 17.2098 8.68999 16.9732 8.55194Z" fill="#151917"/>
                </svg>
                <div class="delete-btn">
                    <svg width="16" height="16" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                    </svg>
                </div>
                Auswahl
            </div>

            <SearchBar
                activeSearchState="receipt/setActiveSearchInput"
                commitSearchAction="receipt/commitSearch"
                closeSearchAction="receipt/closeSearch"
            ></SearchBar>
        </div>
        <div v-if="activeSelection && $store.state.receipt.selectedReceiptIDs.length" class="selectioncount">
            {{ $store.state.receipt.selectedReceiptIDs.length }} ausgewählt
        </div>
        <div class="listview-btn--mobile">
            <ListViewToggleButton />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import SearchBar from '../partials/SearchBar'
import ListViewToggleButton from '../partials/ListViewToggleButton'

export default {
    name: 'FilterList',
    components: {
        SearchBar,
        ListViewToggleButton
    },
    computed: {
        ...mapState({
            /**
             * Detects if Checkbox mode for list items is active
             */
            activeSelection: state => state.checkboxActive,
            /**
             * Detects if the gridView mode for listed items is active or not 
             */
            gridView: state => state.gridView
        }),
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .filter {
        position: relative;
        display: flex;
        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0 5px 7.5px;
            .selectioncount {
                color: $brand-blue;
                font-size: 14px;
                line-height: 22px;
            }
        }
        > .btn--rounded {
            font-size: 12px;
            line-height: 22px;
            cursor: pointer;
            display: none;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
                border-radius: 30px;
                display: flex;
            }
        }
    }
    
    .listview-btn--mobile {
        @media screen and (min-width: $medium-screen) {
            display: none;
        }
    }

    .btn--rounded {
        @media screen and (min-width: $medium-screen) {
            border-radius: 30px;
            padding: 8px 15px;
        }
        &:hover {
            background-color: $hover-blue;
        }
        &.active {
            background-color: $brand-blue;
            &:hover {
                background-color: $hover-blue;
                svg.filter-btn {
                    display: none;
                }
                .delete-btn {
                    display: flex;
                }
            }
        }
        .delete-btn {
            display: none;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            margin: 0 5px;
        }
        svg path {
            fill: #fff;
        }
        svg rect {
            stroke: #fff;
        }
    }

    .selectioncount {
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
        }
    }
</style>