<template>
    <div>
        <ReceiptviewSubheader title="Dein Konto" backLinkName="zurück zur Übersicht"></ReceiptviewSubheader>

        <form>
            <h4>Persönliche Daten</h4>
            <div class="appellation">
                <Dropdown placeholder="Herr" label="Anrede" :listItems="listItems"></Dropdown>
            </div>
            <div class="name">    
                <fieldset>
                    <InputField name="firstname" type="text" label="Vorname"></InputField>
                </fieldset>
                <fieldset>
                    <InputField name="lastname" type="text" label="Nachname"></InputField>
                </fieldset>
            </div>
            <div class="contact">
                <div class="mail">
                    <fieldset>
                        <InputField name="mail" type="email" label="E-Mail Adresse"></InputField>
                    </fieldset>
                </div>
                <div class="phone">
                    <Dropdown placeholder="+49" label="Ländervorwahl" :listItems="areaCodes"></Dropdown>
                    <fieldset>
                        <InputField name="phonenumber" type="tel" label="Telefon"></InputField>
                    </fieldset>
                </div>
            </div>

            <h4>Passwort ändern</h4>
            <div class="oldpassword">
                <fieldset>
                    <InputField name="oldpassword" type="password" label="Aktuelles Passwort"></InputField>
                </fieldset>
            </div>
            <div class="newpassword">
                <fieldset>
                    <InputField name="newpassword" type="password" label="Neues Passwort"></InputField>
                </fieldset>
                <fieldset>
                    <InputField name="newpasswordrepeat" type="password" label="Neues Passwort wiederholen"></InputField>
                </fieldset>
            </div>

            <button type="submit" class="btn btn--blue">Änderung bestätigen</button>
        </form>
    </div>
</template>

<script>
    import ReceiptviewSubheader from '../../partials/ReceiptView_Subheader'
    import Dropdown from '../../partials/Dropdown'
    import InputField from '../../partials/InputField'

    export default {
        name: 'Account',
        components: {
            ReceiptviewSubheader,
            Dropdown,
            InputField
        },
        data() {
            return {
                listItems: [
                    {
                        id:  1,
                        name: 'Herr'
                    },
                    {
                        id:  2,
                        name: 'Frau'
                    }
                ],
                areaCodes: [
                    {
                        id: 1,
                        name: '+49'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    form {
        padding: 0 7.5px;
        @media screen and (min-width: $medium-screen) {
            padding-top: 30px;
        }
        h4 {
            padding: 20px 0;
        }
        fieldset {
            border: none;
            padding-bottom: 30px;
        }
        .appellation {
            padding-bottom: 30px;
            @media screen and (min-width: $medium-screen) {
                width: 45%;
            }
        }
        .name {
            @media screen and (min-width: $medium-screen) {
                display: flex;
                justify-content: space-between;
            }
            @media screen and (min-width: $large-screen) {
                width: 630px;
            }
            fieldset {
                @media screen and (min-width: $medium-screen) {
                    width: 45%;
                }
                @media screen and (min-width: $large-screen) {
                    width: 300px;
                }
            }
        }
        .contact {
            @media screen and (min-width: $medium-screen) {
                display: flex;
                justify-content: space-between;
            }
            @media screen and (min-width: $large-screen) {
                width: 630px;
            }
            > div {
                @media screen and (min-width: $medium-screen) {
                    width: 45%;
                }
                @media screen and (min-width: $large-screen) {
                    width: 300px;
                }
            }
            .phone {
                display: flex;
                justify-content: space-between;
                .dropdown {
                    width: 30%;
                }
                fieldset {
                    width: 65%;
                }
            }
        }
        .oldpassword {
            @media screen and (min-width: $medium-screen) {
                width: 45%;
            }
            @media screen and (min-width: $large-screen) {
                width: 300px;
            }
        }
        .newpassword {
            @media screen and (min-width: $medium-screen) {
                display: flex;
                justify-content: space-between;
            }
            @media screen and (min-width: $large-screen) {
                width: 630px;
            }
            fieldset {
                @media screen and (min-width: $medium-screen) {
                    width: 45%;
                }
                @media screen and (min-width: $large-screen) {
                    width: 300px;
                }
            }
        }
        button {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }
</style>