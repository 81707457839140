<template>
    <div :class="['metadata-field', {'disabled': disabled}]">
        <BoolSelect v-if="type === 'Boolean'" :value="value" :label="label" @change="handleChange"/>
        <InputField 
            v-if="type === 'String'"
            type="text"
            :valid="textValid"
            :value="value"
            :label="label"
            :removeAble="removeAble"
            @change="handleChange"
            @deleteMetadata="$emit('deleteMetadata')"
        />
    </div>
</template>
<script>
import BoolSelect from './BoolSelect'
import InputField from './InputField'

export default {
    name: 'MetadataField',
    components: {
        BoolSelect,
        InputField
    },
    props: {
        type: {},
        label: {},
        value: {},
        disabled: {},
        required: {},
        max: {},
        name: {},
        removeAble: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleChange(val) {
            this.$emit('change', val)
        }
    },
    computed: {
        textValid() {
            return !this.required || this.value !== null && this.value.length > 0
        }
    }
}
</script>
