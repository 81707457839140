<template>
    <div class="receipt__name-content">
        <svg v-if="!checkboxMode" class="icon" width="25" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7 8.90008L13.05 2.19009C12.85 1.99009 12.63 2.00008 12.63 2.00008H5.47C5.21 2.00008 5 2.22009 5 2.49009V22.1701C5 22.5801 5.22002 22.8001 5.49002 22.8001H19.36C19.69 22.8001 19.95 22.5401 19.95 22.2101V9.44008C19.95 9.33008 19.93 9.13008 19.7 8.90008ZM13.13 4.72008L17.19 8.81008H13.13V4.72008ZM6.72 3.72008H11.4L11.39 10.0401C11.39 10.3101 11.61 10.5301 11.88 10.5301H18.23V21.0701H6.73001V3.72008H6.72Z" fill="#151917"/>
        </svg>

        <label v-if="checkboxMode" class="checkbox">
            <input type="checkbox" :checked="checkboxSelected" @click.prevent.stop>
            <span class="checkmark"></span>
        </label>
        {{shorten(receiptTitle)}} <br>

        <span class="additionalinfo">{{receiptMonth}} <span v-if="receiptType !== null && receiptMonth !== null">|</span> {{receiptType}}</span>
    </div>
</template>

<script>
export default {
    name: 'ReceiptName',
    props: {
        receiptTitle: {
            type: String,
        },
        receiptMonth: {
            type: String,
        },
        receiptType: {
            type: String,
        },
        checkboxMode: {
            type: Boolean,
            default: false
        },
        checkboxSelected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        shorten(name) {
            if (name.length > 20) {
               return name.substring(0,20) + '....' + name.substr(name.length - 3)
            } else {
                return name
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receipt__name-content {
        @media screen and (min-width: $medium-screen) {
            display: flex;
            align-items: center;
        }
        svg path {
            fill: $grey-text;
        }
        .checkbox {
            width: 22px;
            height: 22px;
            position: relative;
            cursor: pointer;
            margin-right: 10px;
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: block;
            }
            &:hover input ~ .checkmark {
                background-color: transparent;
            }
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked ~ .checkmark {
                    background-color: $brand-blue;
                    &:after {
                        display: block;
                    }
                }
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 22px;
                width: 22px;
                border-radius: 50%;
                border: 2px solid $brand-deep-green;
                &:after {
                    position: absolute;
                    content: '';
                    left: 6px;
                    top: 3px;
                    width: 3px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    display: none;
                }
            }
        }
        .additionalinfo {
            color: $default-grey;
            @media screen and (min-width: $medium-screen) {
                display: none;
            }
        }
        .icon {
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: block;
                margin-right: 10px;
            }
        }
    }
</style>