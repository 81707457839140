import { receiptItem } from './partials'

export const onCreateReceipt = /* GraphQL */ `
  subscription OnCreateReceipt($owner: String) {
    onCreateReceipt(owner: $owner) {
      ${receiptItem}
    }
  }
`;


export const onUpdateReceipt = /* GraphQL */ `
  subscription OnUpdateReceipt($owner: String) {
    onUpdateReceipt(owner: $owner) {
      ${receiptItem}
    }
  }
`;


export const onCreateReceiptFile = /* GraphQL */ `
  subscription OnCreateReceiptFile($owner: String) {
    onCreateReceiptFile(owner: $owner) {
      id
      originalFilename
      imagePaths
      ocrResult
      path
      receiptID
      identityId
      owner
      createdAt
      updatedAt
      receipt {
         ${receiptItem}
      }
    }
  }
`;
export const onUpdateReceiptFile = /* GraphQL */ `
  subscription OnUpdateReceiptFile($owner: String) {
    onUpdateReceiptFile(owner: $owner) {
      id
      originalFilename
      imagePaths
      ocrResult
      path
      receiptID
      identityId
      owner
      createdAt
      updatedAt
      receipt {
         ${receiptItem}
      }
    }
  }
`;

export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($owner: String) {
    onUpdateReport(owner: $owner) {
      id
      title
      originalFilenames
      paths
      thumbnail
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
    }
  }
`;

export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($owner: String) {
    onUpdateDocument(owner: $owner) {
      id
      title
      originalFilenames
      paths
      thumbnail
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
    }
  }
`;