import { receiptItem } from './partials'

export const listReceiptsByOwner = /* GraphQL */ `
  query listReceiptsByOwner(
    $owner: String
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptsByOwner(
      owner: $owner,
      sortDirection: DESC,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
    ) {
      items {
        ${receiptItem}
      }
      nextToken
    }
  }
`;

export const listReceipts = /* GraphQL */ `
  query ListReceipts(
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ${receiptItem}
      }
      nextToken
    }
  }
`;

export const getReceipt = /* GraphQL */ `
  query GetReceipt($id: ID!) {
    getReceipt(id: $id) {
      ${receiptItem}
  }
}
`;

export const searchReceiptFiles = /* GraphQL */ `
  query SearchReceiptFiles(
    $filter: SearchableReceiptFileFilterInput
    $sort: SearchableReceiptFileSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchReceiptFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        receipt {
           ${receiptItem}
        }
      }
      nextToken
      total
    }
  }
`;

export const receiptProjects = /* GraphQL */ `
  query ListReceiptProjects(
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        project
      }
      nextToken
    }
  }
`

export const listReceiptCategorys = /* GraphQL */ `
  query ListReceiptCategorys(
    $filter: ModelReceiptCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByOrder(
      type: "ReceiptCategory"
      sortDirection: ASC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        additionalPropertyTypes {
            items {
                propertyType {
                    id
                    name
                    itemLabel
                    type
                    required
                    min
                    max
                }
            }
        }
      }
      nextToken
    }
  }
`;

export const receiptSearch = /* GraphQL */ `
  query ReceiptSearch($keyword: String! $ownerID: ID!) {
    receiptSearch(keyword: $keyword ownerID: $ownerID) {
      items {
        receiptID
      }
      nextToken
    }
  }
`;


export const searchMetadataPropertys = /* GraphQL */ `
  query SearchMetadataPropertys(
    $filter: SearchableMetadataPropertyFilterInput
    $sort: SearchableMetadataPropertySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMetadataPropertys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        value
        createdAt
        receipt {
          ${receiptItem}
        }
      }
      nextToken
      total
    }
  }
`;

export const listReportCategorys = /* GraphQL */ `
  query ListReportCategorys {
    listReportCategoryByOrder(
      type: "ReportCategory"
      sortDirection: ASC
    ) {
      items {
        id
        name
      }
    }
  }
`;

export const reportSearch = /* GraphQL */ `
  query ReportSearch($keyword: String! $userID: ID!) {
    reportSearch(keyword: $keyword userID: $userID) {
      items {
        id
        title
        originalFilenames
        paths
        thumbnail
        month
        year
        categoryID
        identityId
        user
        editBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;