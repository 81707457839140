<template>
    <div class="receipt__image">
        <div v-if="receiptImage" class="receipt__image-content" :style="`background-image: url(${receiptImage})`"></div>
        
        <div v-if="!receiptImage" class="icon-wrapper">
            <svg class="icon" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7 8.90008L13.05 2.19009C12.85 1.99009 12.63 2.00008 12.63 2.00008H5.47C5.21 2.00008 5 2.22009 5 2.49009V22.1701C5 22.5801 5.22002 22.8001 5.49002 22.8001H19.36C19.69 22.8001 19.95 22.5401 19.95 22.2101V9.44008C19.95 9.33008 19.93 9.13008 19.7 8.90008ZM13.13 4.72008L17.19 8.81008H13.13V4.72008ZM6.72 3.72008H11.4L11.39 10.0401C11.39 10.3101 11.61 10.5301 11.88 10.5301H18.23V21.0701H6.73001V3.72008H6.72Z" fill="#151917"/>
            </svg>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ReceiptImage',
    props: {
        receiptImage: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receipt__image {
        width: 100%;
        height: 55px;
        border-bottom: 1px solid $default-grey;
        @media screen and (min-width: $medium-screen) {
            height: 90px;
        }
        &-content {
            width: calc(100% - 30px); // 30px because 15px x-margin
            height: 100%;
            margin: 0 auto;
            background-size: cover;
            background-position-y: top;
            background-position-x: center;
            background-repeat: no-repeat;
            @media screen and (min-width: $medium-screen) {
                width: calc(100% - 60px); // 60px because 30px x-margin
            }
        }

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 10px;
            svg path {
                fill: $grey-text;
            }
        }
    }
</style>