export const preInitWidget = () => {
    window.fwSettings={
        'widget_id':80000001409
    };
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
}

export const prefillWidgetUserInfo = (email, name) => {
    window.FreshworksWidget('identify', 'ticketForm', {
        name: name,
        email: email,
    });
}

export const prefillWidgetTicketInfo = (ticketID, title, url) => {
    window.FreshworksWidget('prefill', 'ticketForm', {
        subject: `Frage zum Beleg: ${title}`,
        description: `


______
Link zum Beleg (bitte nicht löschen!)
${url}
`
    })
}

export const toggleButton = (state) => {
    window.FreshworksWidget(state ? 'show' : 'hide', 'launcher');
}

export const toggleWidget = (state) => {
    window.FreshworksWidget(state ? 'open' : 'close')
}