<template>
    <div class="list">
        <div class="list__topbar">
            <div class="report__name">
                Dateiname 
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.632488 5.52699C0.774988 5.66949 1.00749 5.66949 1.14999 5.52699L3.35499 3.32199V8.12949C3.35499 8.33199 3.51999 8.49699 3.72249 8.49699H4.27749C4.47999 8.49699 4.64499 8.33199 4.64499 8.12949V3.32199L6.84999 5.52699C6.99249 5.66949 7.22499 5.66949 7.36749 5.52699L7.76499 5.12949C7.90749 4.98699 7.90749 4.75449 7.76499 4.61199L4.65999 1.50699L4.26249 1.10949V1.10199C4.11999 0.959492 3.88749 0.959492 3.74499 1.10199L3.34749 1.49949C3.33249 1.50699 3.33249 1.52199 3.32499 1.52949L0.234988 4.61949C0.0924878 4.76199 0.0924878 4.99449 0.234988 5.13699L0.632488 5.52699Z" fill="#58584D"/>
                </svg>
            </div>
            <div class="report__filedata">
                Datei 
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.632488 5.52699C0.774988 5.66949 1.00749 5.66949 1.14999 5.52699L3.35499 3.32199V8.12949C3.35499 8.33199 3.51999 8.49699 3.72249 8.49699H4.27749C4.47999 8.49699 4.64499 8.33199 4.64499 8.12949V3.32199L6.84999 5.52699C6.99249 5.66949 7.22499 5.66949 7.36749 5.52699L7.76499 5.12949C7.90749 4.98699 7.90749 4.75449 7.76499 4.61199L4.65999 1.50699L4.26249 1.10949V1.10199C4.11999 0.959492 3.88749 0.959492 3.74499 1.10199L3.34749 1.49949C3.33249 1.50699 3.33249 1.52199 3.32499 1.52949L0.234988 4.61949C0.0924878 4.76199 0.0924878 4.99449 0.234988 5.13699L0.632488 5.52699Z" fill="#58584D"/>
                </svg>
            </div>
            <div class="report__download">
                Aktion 
            </div>
        </div>

        <FileListItem 
            v-for="file in files"
            :key="file.id"
            :fileID="file.id"
            :fileTitle="file.title"
            :fileType="'PDF'"
            :fileSize="'1 MB'"
            @delete="$emit('delete', $event)"
            @download="$emit('download', $event)"
            :deleteActive="deleteActive"
        >
        </FileListItem>
    </div>
</template>

<script>
import FileListItem from './File_ListItem'

export default {
    name: 'FileListView',
    components: {
        FileListItem
    },
    props: {
        files: {
            type: Array, 
            required: true
        },
        deleteActive: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .list {
        margin: 0 7.5px;
        &__topbar {
            display: flex;
            justify-content: flex-start;
            font-size: 10px;
            line-height: 15px;
            color: $grey-text;
            border-bottom: 1px solid $default-grey;
            padding-bottom: 5px;
            font-family: "Gotham-Medium";
        }      
        .report {
            &__name {
                width: 80%;
                @media screen and (min-width: $medium-screen) {
                    width: 70%;
                }
            }
            &__filedata {
                display: none;
                @media screen and (min-width: $medium-screen) {
                    display: inline;
                    width: 25%;
                    text-align: left;
                }
            }
            &__download {
                width: 5%;
            }
        }
    }
</style>