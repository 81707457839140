<template>
    <div>
        <ReceiptviewSubheader title="Datenschutz" backLinkName="zurück zur Übersicht" :exportBtn="false"></ReceiptviewSubheader>

        <div class="data-privacy">
            <h4>Datenschutz</h4>
            <p>Wir legen großen Wert auf den Schutz deiner persönlichen Daten. Du hast unsere Datenschutzbestimmungen im Registrierungsprozess akzeptiert. Du kannst sie jederzeit hier einsehen:</p>
            <p><a href="https://summarum.com/datenschutz" target="_blank">Unsere Datenschutzerklärung</a></p>
            
            <h4>AGB</h4>
            <p>Du hast unsere AGB im Registrierungsprozess akzeptiert. Du kannst sie jederzeit hier einsehen:</p>
            <p><a href="https://summarum.com/agb" target="_blank">Unsere AGB</a></p>

        </div>
    </div>
</template>

<script>
    import ReceiptviewSubheader from '../../partials/ReceiptView_Subheader'

    export default {
        name: 'DataPrivacy',
        components: {
            ReceiptviewSubheader
        },
        data() {
            return {
                /**
                 * Variable that stores current date
                 */
                currentDate: 0,
            }
        },
        created () {
            this.currentDate = this.updateCurrentDate()
        },
        methods: {
            /**
             * Function that returns current date with day, month and year (dd.mm.yyy)
             */
            updateCurrentDate() {
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();

                return dd + '.' + mm + '.' + yyyy;
            }
        }
    }
</script>

<style lang="scss" scoped>    
    @import 'src/assets/style/variables.scss';

    .data-privacy {
        margin: 7.5px; 
        h4 {
            padding-top: 30px;
            padding-bottom: 15px;
        }
        button.btn {
            margin-top: 30px;
            margin-left: 0;
        }
        p {
            font-size: 12px;
            line-height: 15px;
            width: calc(100% - 36px - 15px);
            padding-bottom: 1.5rem;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
                line-height: 22px;
                max-width: 75%;
            }
            a {
                font-family: "Gotham-Medium";
                text-decoration: underline;
            }
        }
        .setting {
            display: flex;
            align-items: center;
            .switch {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 18px;
                margin-right: 15px;
                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    &:checked + .slider {
                        background-color: $success;
                        &:before {
                            -webkit-transform: translateX(18px);
                            -ms-transform: translateX(18px);
                            transform: translateX(18px); 
                        }
                    }
                }
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $default-grey;
                    -webkit-transition: .4s;
                    transition: .4s;
                    border-radius: 34px;
                    &:before {
                        position: absolute;
                        content: "";
                        height: 14px;
                        width: 14px;
                        left: 2px;
                        bottom: 2px;
                        background-color: #fff;
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 50%;
                    }
                }
            }
        }
        .dsvgo {
            p {
                padding-bottom: 15px;
                @media screen and (min-width: $medium-screen) {
                    font-size: 14px;
                    line-height: 22px;
                } 
                &:last-child {
                    padding: 0;
                }
            }
        }
    }
</style>