<template>
    <div
        class="grid-item__content"
    >
        <div class="report__download" @click="$emit('download', fileID)">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4244 4.3202L20.8144 6.0002C21.1044 6.3402 21.2744 6.7902 21.2744 7.2702V19.7702C21.2744 20.8702 20.3744 21.7702 19.2744 21.7702H5.27441C4.17441 21.7702 3.27441 20.8702 3.27441 19.7702V7.2702C3.27441 6.7902 3.44441 6.3402 3.73441 6.0002L5.11441 4.3202C5.39441 3.9802 5.80441 3.7702 6.27441 3.7702H18.2744C18.7444 3.7702 19.1544 3.9802 19.4244 4.3202ZM18.0344 5.7702H6.51441L5.71441 6.7402H18.8444L18.0344 5.7702ZM5.27441 19.7702V8.7702H19.2744V19.7702H5.27441ZM10.8244 10.7702H13.7244V13.7702H16.2744L12.2744 17.7702L8.27441 13.7702H10.8244V10.7702Z" fill="#58584D"/>
            </svg>
        </div>
        <div v-if="deleteActive" class="report__delete" @click="$emit('delete', fileID)">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6779 2.32703H8.89893V0.341824C8.89893 0.151191 8.74774 0 8.55053 0H6.05259C5.86195 0 5.70419 0.151191 5.70419 0.341824V2.32703H0.925222C0.747737 2.32703 0.603119 2.47165 0.603119 2.64914V3.13558C0.603119 3.31306 0.747737 3.45768 0.925222 3.45768H13.6779C13.8554 3.45768 14 3.31306 14 3.13558V2.64914C14 2.47165 13.8554 2.32703 13.6779 2.32703ZM8.20871 2.32703H6.39441V0.690222H8.20213V2.32703H8.20871ZM13.7436 4.67379L13.2638 4.57518C13.0928 4.53574 12.9154 4.68693 12.8628 4.91044L10.8118 14.8628H10.161L11.8176 4.7198H11.1208L9.46425 14.8628H8.96466L9.86524 4.7198H9.17502L8.27444 14.8628H7.68282V4.72638H6.9926V14.8694H6.34839L5.47411 4.72638H4.78389L5.6516 14.8694H5.15201L3.4889 4.72638H2.78553L4.45521 14.8694H3.87674L1.79293 4.91044C1.74692 4.68693 1.56943 4.53574 1.39194 4.57518L0.918649 4.67379C0.741163 4.70666 0.635987 4.91701 0.682001 5.14708L2.87756 15.6582C2.89728 15.7568 2.9433 15.8291 3.00246 15.8948C3.00904 15.9014 3.00904 15.908 3.01561 15.908C3.0419 15.9343 3.07477 15.954 3.10764 15.9671C3.11421 15.9671 3.11421 15.9737 3.12079 15.9737C3.15365 15.9869 3.19309 15.9934 3.23254 15.9934C3.23911 15.9934 3.23911 15.9934 3.24568 15.9934H11.4495C11.456 15.9934 11.456 15.9934 11.4626 15.9934C11.4955 15.9934 11.5349 15.9869 11.5678 15.9737C11.5809 15.9671 11.5941 15.9606 11.6007 15.954C11.6204 15.9408 11.6467 15.9277 11.6664 15.9145C11.6795 15.9014 11.6927 15.8882 11.7058 15.8685C11.7518 15.8094 11.7913 15.7436 11.811 15.6582L13.9803 5.14708C14.0197 4.91701 13.9145 4.70666 13.7436 4.67379Z" fill="#FAFAF9"/>
            </svg>
        </div>
        <div class="report__image">
            <div v-if="fileImage" class="report__image-content" :style="`background-image: url(${fileImage})`"></div>
            <div v-if="!fileImage" class="icon-wrapper">
                <svg class="icon" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.7 8.90008L13.05 2.19009C12.85 1.99009 12.63 2.00008 12.63 2.00008H5.47C5.21 2.00008 5 2.22009 5 2.49009V22.1701C5 22.5801 5.22002 22.8001 5.49002 22.8001H19.36C19.69 22.8001 19.95 22.5401 19.95 22.2101V9.44008C19.95 9.33008 19.93 9.13008 19.7 8.90008ZM13.13 4.72008L17.19 8.81008H13.13V4.72008ZM6.72 3.72008H11.4L11.39 10.0401C11.39 10.3101 11.61 10.5301 11.88 10.5301H18.23V21.0701H6.73001V3.72008H6.72Z" fill="#151917"/>
                </svg>
            </div>
        </div>
        <div class="report__title">
            {{ fileTitle }}
        </div>
        <div class="report__type">
            {{ fileType }} | {{ fileSize }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FileGridItem',
        props: {
            fileID: {
                type: String
            },
            fileTitle: {
                type: String
            },
            fileType: {
                type: String
            },
            fileSize: {
                type: String
            },
            fileImage: {
                type: String
            },
            deleteActive: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .grid-item__content {
        position: relative;
        text-align: center;
    }
    .report__image {
        width: 100%;
        height: 55px;
        border-bottom: 1px solid $default-grey;
        @media screen and (min-width: $medium-screen) {
            height: 90px;
        }
        &-content {
            width: calc(100% - 30px); // 30px because 15px x-margin
            height: 100%;
            margin: 0 auto;
            background-size: cover;
            background-position-y: top;
            background-position-x: center;
            background-repeat: no-repeat;
            @media screen and (min-width: $medium-screen) {
                width: calc(100% - 60px); // 60px because 30px x-margin
            }
        }
        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            svg path {
                fill: $grey-text;
            }
        }
    }
    .report__download {
        position: absolute;
        top: 5px;
        right: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        -ms-user-select: None;
        -moz-user-select: None;
        -webkit-user-select: None;
        user-select: None;
    }
    .report__delete {
        background-color: #E17351;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        padding: 4px;
        margin: 5px 7.5px;
        left: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        -ms-user-select: None;
        -moz-user-select: None;
        -webkit-user-select: None;
        user-select: None;
    }
    .report__title {
        font-size: 10px;
        line-height: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
        max-width: calc(100% - 30px); // 15px x-margin 
        margin: auto;
        @media screen and (min-width: $medium-screen) {
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 0;
        }
    }
    .report__type {
        font-size: 10px;
        line-height: 15px;
        color: $grey-text;
        padding-bottom: 10px;
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
        }
    }
</style>