<template>
  <div class="topbar">
      <div class="topbar__mainmenu--mobile" @click="$emit('show-menu')">
        <svg width="20" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.16 3.35999H0.459991C0.209991 3.35999 0 3.56995 0 3.81995V4.52002C0 4.77002 0.209991 4.97998 0.459991 4.97998H12.16C12.41 4.97998 12.62 4.77002 12.62 4.52002V3.81995C12.62 3.56995 12.41 3.35999 12.16 3.35999ZM12.16 6.72998H0.459991C0.209991 6.72998 0 6.93994 0 7.18994V7.89001C0 8.14001 0.209991 8.34998 0.459991 8.34998H12.16C12.41 8.34998 12.62 8.14001 12.62 7.89001V7.18994C12.62 6.92994 12.41 6.72998 12.16 6.72998ZM12.16 0H0.459991C0.209991 0 0 0.209961 0 0.459961V1.16003C0 1.41003 0.209991 1.62 0.459991 1.62H12.16C12.41 1.62 12.62 1.41003 12.62 1.16003V0.459961C12.62 0.209961 12.41 0 12.16 0Z" fill="#151917"/>
        </svg>
      </div>
      <div class="topbar__mainmenu--desktop" @click="$emit('toggle-menu')">
        <svg width="20" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.16 3.35999H0.459991C0.209991 3.35999 0 3.56995 0 3.81995V4.52002C0 4.77002 0.209991 4.97998 0.459991 4.97998H12.16C12.41 4.97998 12.62 4.77002 12.62 4.52002V3.81995C12.62 3.56995 12.41 3.35999 12.16 3.35999ZM12.16 6.72998H0.459991C0.209991 6.72998 0 6.93994 0 7.18994V7.89001C0 8.14001 0.209991 8.34998 0.459991 8.34998H12.16C12.41 8.34998 12.62 8.14001 12.62 7.89001V7.18994C12.62 6.92994 12.41 6.72998 12.16 6.72998ZM12.16 0H0.459991C0.209991 0 0 0.209961 0 0.459961V1.16003C0 1.41003 0.209991 1.62 0.459991 1.62H12.16C12.41 1.62 12.62 1.41003 12.62 1.16003V0.459961C12.62 0.209961 12.41 0 12.16 0Z" fill="#151917"/>
        </svg>
      </div>
      
      <ul class="topbar__icons">
        <li v-if="$store.getters['auth/isStaff'] && $store.state.staff.selectedUserId">  
            <div href="#" class="topbar__icons-link subscribe-btn">Zeige Belege von: {{ $store.getters['staff/selectedUser'].Attributes.email }} </div>
        </li>
        <li v-if="!$store.getters['auth/isStaff']">   
            <div href="#" class="topbar__icons-link subscribe-btn">Beta-Version</div>
        </li>
        <li
            @click.prevent="aktionMenuOpen = !aktionMenuOpen"
            @mouseover="aktionMenuOpen = true"
            @mouseleave="aktionMenuOpen = false"
            :class="{active: aktionMenuOpen}"    
        >
            <a
                class="topbar__icons-link"
                href="#"
            >
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.0686 16.3364H11.2938C11.136 16.3364 11.0078 16.4646 11.0078 16.6224V18.4662C11.0078 18.624 11.136 18.7522 11.2938 18.7522H13.0686C13.2264 18.7522 13.3546 18.624 13.3546 18.4662V16.6224C13.3447 16.4646 13.2264 16.3364 13.0686 16.3364ZM12.5263 6.35777C10.7416 6.35777 9.47945 7.05785 8.48356 8.11291C8.38496 8.22137 8.38496 8.37913 8.48356 8.4876L9.4203 9.49335C9.52876 9.60182 9.69639 9.61167 9.80485 9.50321C10.5937 8.7341 11.4417 8.31997 12.4573 8.31997C13.7194 8.31997 14.4688 9.00034 14.4688 9.97651V10.0159C14.4688 11.1696 13.4532 11.8894 11.2346 11.9584L11.1459 12.0472L11.4614 14.6306C11.4811 14.7686 11.5994 14.8771 11.7473 14.8771H12.6643C12.8122 14.8771 12.9306 14.7686 12.9503 14.6306L13.0982 13.4868C15.0407 13.1515 16.6282 12.1556 16.6282 9.95678V9.91735C16.6282 7.7185 14.9716 6.35777 12.5263 6.35777ZM12.2798 0.550049C5.65365 0.550049 0.279785 5.92392 0.279785 12.55C0.279785 19.1762 5.65365 24.55 12.2798 24.55C18.9059 24.55 24.2798 19.1762 24.2798 12.55C24.2699 5.92392 18.9059 0.550049 12.2798 0.550049ZM12.2798 22.8343C6.6101 22.8343 1.99548 18.2197 1.99548 12.55C1.99548 6.88037 6.6101 2.26574 12.2798 2.26574C17.9495 2.26574 22.5641 6.88037 22.5641 12.55C22.5542 18.2197 17.9495 22.8343 12.2798 22.8343Z" fill="black"/>
                </svg>
                <span class="topbar__icons-linkname">Aktion</span>
            </a>
            <ul v-show="aktionMenuOpen" @click.stop="aktionMenuOpen = false">
                <li>
                    <a target="_blank" @click.prevent="openTicketWidget">Ticket schreiben</a>
                </li>
                <li>
                    <a target="_blank" :href="freshDeskURL">Ticketportal öffnen</a>
                </li>
                <li>
                    <a href="mailto:hello@summarum.com">E-Mail an summarum</a>
                </li>
                <li class="profile-details" v-if="enablePhoneSupport">
                    <strong class="text--white">Support-Hotline</strong> <br>
                    +49-202-25621048
                </li>
                <li>
                    <a href="" @click.prevent="$emit('start-tour')">Starte die Tour</a>
                </li>
            </ul>
        </li>
        <li
            @click.prevent="profilMenuOpen = !profilMenuOpen"
            @mouseover="profilMenuOpen = true"
            @mouseleave="profilMenuOpen = false"
            :class="{active: profilMenuOpen}"
        >
            <a
                class="topbar__icons-link"
                href="#"
            >
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2798 0.77002C5.65365 0.77002 0.279785 6.14389 0.279785 12.77C0.279785 19.3961 5.65365 24.77 12.2798 24.77C18.9059 24.77 24.2798 19.3961 24.2798 12.77C24.2699 6.14389 18.9059 0.77002 12.2798 0.77002ZM17.3973 21.6739C15.8887 22.5514 14.1434 23.0543 12.2699 23.0543C10.3274 23.0543 8.51314 22.5021 6.96507 21.5654V21.1809C6.96507 18.3214 9.28225 15.9943 12.1319 15.9648C12.1615 15.9648 12.191 15.9648 12.2108 15.9648C12.2305 15.9648 12.2403 15.9648 12.2502 15.9648C15.09 16.0141 17.3776 18.3312 17.3874 21.171L17.3973 21.6739ZM8.62161 10.65C8.62161 8.66812 10.2387 7.05103 12.2206 7.05103C14.2124 7.05103 15.8196 8.66812 15.8196 10.65C15.8196 12.6221 14.2223 14.2293 12.2601 14.2491C12.2305 14.2491 12.2009 14.2491 12.1812 14.2491C12.1615 14.2491 12.1516 14.2491 12.1417 14.2491C10.1993 14.1998 8.62161 12.6122 8.62161 10.65ZM19.0735 20.4709C18.827 18.0749 17.3578 16.0338 15.297 14.9886C16.6578 14.0223 17.5551 12.4446 17.5551 10.65C17.5551 7.72153 15.1689 5.33533 12.2305 5.33533C9.30197 5.33533 6.90592 7.72153 6.90592 10.65C6.90592 12.4249 7.78348 14.0026 9.13435 14.9689C7.0834 15.9845 5.61421 17.9664 5.3184 20.323C3.27732 18.4397 1.99548 15.7577 1.99548 12.77C1.99548 7.10034 6.6101 2.4857 12.2798 2.4857C17.9495 2.4857 22.5641 7.10034 22.5641 12.77C22.5542 15.8366 21.2034 18.5876 19.0735 20.4709Z" fill="black"/>
                </svg>
                <span class="topbar__icons-linkname">Profil</span>
            </a>
            <ul v-show="profilMenuOpen" @click.stop="profilMenuOpen = false">
                <li class="profile-details">
                    <strong>{{ $store.state.auth.user && $store.state.auth.user.attributes.given_name }} {{ $store.state.auth.user && $store.state.auth.user.attributes.family_name }}</strong> <br>
                    {{ $store.state.auth.user && $store.state.auth.user.attributes.email }}
                </li>
                <li>
                    <router-link  to="/settings">Deine Einstellungen</router-link>
                </li>
                <li>
                    <a href="" @click.prevent="$store.dispatch('auth/logout')">Log Out</a>
                </li>
            </ul>
        </li>
        <!-- <li>
            Notifications
            <a class="topbar__icons-link" href="">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6621 2.96143C10.6621 2.13143 11.3321 1.46143 12.1621 1.46143C12.9921 1.46143 13.6621 2.13143 13.6621 2.96143V4.13143C16.8021 4.81143 19.1621 7.61143 19.1621 10.9614V16.9614L21.1621 18.9614V19.9614H3.16211V18.9614L5.16211 16.9614V10.9614C5.16211 7.61143 7.52211 4.81143 10.6621 4.13143V2.96143ZM12.1621 5.96143C14.9221 5.96143 17.1621 8.20143 17.1621 10.9614V17.9614H7.16211V10.9614C7.16211 8.20143 9.40211 5.96143 12.1621 5.96143ZM10.1721 20.9714C10.1721 22.0714 11.0621 22.9614 12.1621 22.9614C13.2621 22.9614 14.1521 22.0714 14.1521 20.9714H10.1721Z" fill="#141917"/>
                </svg>
            </a>
        </li> -->
      </ul>
  </div>
</template>
<script>
  import { toggleWidget } from '../lib/freshdeskWidget'

  export default {
      name: 'Topbar', 
      data: () => {
          return {
                freshDeskURL: process.env.VUE_APP_FRESHDESK_SSO_URL,
                aktionMenuOpen: false,
                profilMenuOpen: false
          }
      },
      computed: {
          enablePhoneSupport() {
              const featureFlags = this.$store.getters['auth/featureFlags']
              if ('enablePhoneSupport' in featureFlags) {
                  return featureFlags['enablePhoneSupport']
              } else {
                  return true
              }
              
          }
      },
      methods: {
          openTicketWidget() {
                toggleWidget(true)
          }
      } 
  }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .topbar {
        height: 63px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: $medium-screen) {
            height: 100px;
        }
        &__mainmenu {
            &--mobile {
                width: 50px;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @media screen and (min-width: $medium-screen) {
                    margin-left: 15px;
                }
                @media screen and (min-width: $large-screen) {
                    opacity: 0;
                }
                svg path {
                    fill: #fff;
                }
            }
            &--desktop {
                position: fixed;
                left: 100px;
                width: 50px;
                height: 50px;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                z-index: 30;
                @media screen and (min-width: $large-screen) {
                    display: flex;
                }
                svg path {
                    fill: #fff;
                }
            }
        }
        &__icons {
            display: flex;
            align-items: center;
            @media screen and (min-width: $medium-screen) {
                padding-right: 20px;
            }
            li {
                position: relative;
                line-height: 22px;
                list-style: none;
                padding: 0;
                &.active {
                    a {
                        color: $hover-blue;
                        svg path {
                            fill: $hover-blue;
                        }
                    }
                    ul a {
                        color: #fff;
                    }
                }
                ul {
                    position: absolute;
                    right: 0;
                    background: radial-gradient(66.95% 151.66% at 49.97% 151.66%, #009EE0 0%, #00261A 100%);
                    width: 200px;
                    color: #fff;
                    border-radius: 0px 0px 0px 30px;
                    overflow: hidden;
                    z-index: 30;
                    font-size: 12px;
                    @media screen and (min-width: $medium-screen) {
                        width: 252px;
                        font-size: 16px;
                    }
                    li {
                        color: $default-grey;
                        padding: 0 15px;
                        &.profile-details{
                            padding: 13px 30px;
                        }
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            padding: 10px 15px;
                            color: #fff;
                            text-decoration: none;
                            cursor: pointer;
                            @media screen and (min-width: $medium-screen) {
                                padding: 23px 15px;
                            }
                            &:hover {
                                background: rgba(20, 25, 23, 0.1);
                            }   
                        }
                    }
                }
            }
            &-link {
                padding: 10px 15px;
                color: #fff;
                text-decoration: none;
                display: flex;
                align-items: center;
                outline: none;
                svg path {
                    fill: #fff;
                }
                // &:hover {
                //     color: $hover-blue;
                //     svg path {
                //         fill: $hover-blue;
                //     }
                // }
                &.subscribe-btn {
                    background-color: $default-grey;
                    border-radius: 20px;
                    margin-right: 15px;
                    display: none;
                    @media screen and (min-width: $large-screen) {
                        display: flex;
                    }
                    // &:hover {
                    //     background-color: $hover-blue;
                    //     color: #fff;
                    // }
                }
            }
            &-linkname {
                padding-left: 10px;
                display: none;
                @media screen and (min-width: $medium-screen) {
                    display: inline-block;
                }
            }
        }
    }
</style>