<template>
    <div :class="{'inactive': receipt.archived }">
        <Modal @close-modal="$emit('close-modal')" flexPosition="spacebetween">
            <template v-slot:headertext>
                Beleg zuordnen
            </template>
            <template v-slot:body>

                <!-- these items (message + overlay) get active, when receipts have the state 'gebucht' -->
                <div v-if="receipt.archived" class="message">
                    <div class="hint-icon">!</div>
                    <p>Dieser Beleg wurde gebucht und kann nicht mehr verändert werden.</p>
                    <p>Fehler entdeckt? <a href="" @click.prevent="help">Schreib’ uns ein Ticket</a></p>
                </div>
                <div v-if="receipt.archived" class="overlay white">
                </div>

                <!-- main content start -->
                <div class="receipt-modal-body">
                    <div class="hint">*Pflichtfelder - bitte triff eine Auswahl</div>

                    <InputField
                        name="name"
                        type="text"
                        label="Belegbezeichnung"
                        :value="receipt.title"
                        @change="$store.dispatch(`receipt/setTitle`, {receiptID: receipt.id, title: $event})" />

                    <div class="flex-content">
                        <!-- image area with scrollable file view and possibility to add new files to the receipt -->
                        <div class="receipt-image-area">
                            <div class="flex-content">
                                <div class="buttons">
                                    <div class="export-btn" @click="$store.dispatch(`receipt/downloadReceiptFile`, {receiptID: receipt.id, receiptFileID: activeFileID})">
                                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.71008 10.9202H16.4101C16.6601 10.9202 16.8701 10.7101 16.8701 10.4601V9.76018C16.8701 9.51018 16.6601 9.30021 16.4101 9.30021H11.3501V3.73015L14.2901 6.67021C14.4801 6.86021 14.7901 6.86021 14.9801 6.67021L15.5101 6.14018C15.7001 5.95018 15.7001 5.64012 15.5101 5.45012L11.3601 1.30021L10.8301 0.770186L10.8201 0.760176C10.6301 0.570176 10.3201 0.570176 10.1301 0.760176L9.6001 1.29021C9.5901 1.30021 9.5801 1.32012 9.5701 1.33012L5.46008 5.44011C5.27008 5.63011 5.27008 5.94017 5.46008 6.13017L5.99008 6.6602C6.18008 6.8502 6.49008 6.8502 6.68008 6.6602L9.62009 3.72014V9.29021H4.7001C4.4501 9.29021 4.24008 9.50017 4.24008 9.75017V10.4501C4.25008 10.7201 4.46008 10.9202 4.71008 10.9202ZM4.71008 14.2902H16.4101C16.6601 14.2902 16.8701 14.0801 16.8701 13.8301V13.1302C16.8701 12.8802 16.6601 12.6702 16.4101 12.6702H4.71008C4.46008 12.6702 4.25009 12.8802 4.25009 13.1302V13.8301C4.25009 14.0801 4.46008 14.2902 4.71008 14.2902ZM20.1501 0.630171H19.4101C19.1401 0.630171 18.9201 0.780171 18.9201 1.13017V19.7001H2.0701V1.14018C2.0701 0.790181 1.85008 0.630171 1.58008 0.630171H0.840088C0.570088 0.630171 0.350098 0.790181 0.350098 1.14018V20.9302C0.350098 21.2002 0.560078 21.4102 0.830078 21.4202H0.840088H20.1601H20.1701C20.4401 21.4102 20.6501 21.2002 20.6501 20.9302V1.12016C20.6401 0.780161 20.4201 0.630171 20.1501 0.630171ZM4.71008 17.6502H16.4101C16.6601 17.6502 16.8701 17.4401 16.8701 17.1901V16.4902C16.8701 16.2402 16.6601 16.0302 16.4101 16.0302H4.71008C4.46008 16.0302 4.25009 16.2402 4.25009 16.4902V17.1901C4.25009 17.4401 4.46008 17.6502 4.71008 17.6502Z" fill="#151917"/>
                                        </svg>
                                    </div>
                                    <button v-if="!receipt.archived" class="delete-btn" @click="handleDeleteFileClick">
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.6779 2.32703H8.89893V0.341824C8.89893 0.151191 8.74774 0 8.55053 0H6.05259C5.86195 0 5.70419 0.151191 5.70419 0.341824V2.32703H0.925222C0.747737 2.32703 0.603119 2.47165 0.603119 2.64914V3.13558C0.603119 3.31306 0.747737 3.45768 0.925222 3.45768H13.6779C13.8554 3.45768 14 3.31306 14 3.13558V2.64914C14 2.47165 13.8554 2.32703 13.6779 2.32703ZM8.20871 2.32703H6.39441V0.690222H8.20213V2.32703H8.20871ZM13.7436 4.67379L13.2638 4.57518C13.0928 4.53574 12.9154 4.68693 12.8628 4.91044L10.8118 14.8628H10.161L11.8176 4.7198H11.1208L9.46425 14.8628H8.96466L9.86524 4.7198H9.17502L8.27444 14.8628H7.68282V4.72638H6.9926V14.8694H6.34839L5.47411 4.72638H4.78389L5.6516 14.8694H5.15201L3.4889 4.72638H2.78553L4.45521 14.8694H3.87674L1.79293 4.91044C1.74692 4.68693 1.56943 4.53574 1.39194 4.57518L0.918649 4.67379C0.741163 4.70666 0.635987 4.91701 0.682001 5.14708L2.87756 15.6582C2.89728 15.7568 2.9433 15.8291 3.00246 15.8948C3.00904 15.9014 3.00904 15.908 3.01561 15.908C3.0419 15.9343 3.07477 15.954 3.10764 15.9671C3.11421 15.9671 3.11421 15.9737 3.12079 15.9737C3.15365 15.9869 3.19309 15.9934 3.23254 15.9934C3.23911 15.9934 3.23911 15.9934 3.24568 15.9934H11.4495C11.456 15.9934 11.456 15.9934 11.4626 15.9934C11.4955 15.9934 11.5349 15.9869 11.5678 15.9737C11.5809 15.9671 11.5941 15.9606 11.6007 15.954C11.6204 15.9408 11.6467 15.9277 11.6664 15.9145C11.6795 15.9014 11.6927 15.8882 11.7058 15.8685C11.7518 15.8094 11.7913 15.7436 11.811 15.6582L13.9803 5.14708C14.0197 4.91701 13.9145 4.70666 13.7436 4.67379Z" fill="#FAFAF9"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="receipt-image-wrapper">
                                    <div class="receipt-images">
                                        <div
                                            v-for="page in receipt.files.find(f => f.id === activeFileID).imagePaths"
                                            :key="page.id"
                                        >
                                            <img 
                                                :key="page.id"
                                                :src="page" 
                                            />
                                        </div>
                                        <div v-if="!receipt.files.find(f => f.id === activeFileID).imagePaths.length" class="file-placeholder-wrapper">
                                            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.7 8.90008L13.05 2.19009C12.85 1.99009 12.63 2.00008 12.63 2.00008H5.47C5.21 2.00008 5 2.22009 5 2.49009V22.1701C5 22.5801 5.22002 22.8001 5.49002 22.8001H19.36C19.69 22.8001 19.95 22.5401 19.95 22.2101V9.44008C19.95 9.33008 19.93 9.13008 19.7 8.90008ZM13.13 4.72008L17.19 8.81008H13.13V4.72008ZM6.72 3.72008H11.4L11.39 10.0401C11.39 10.3101 11.61 10.5301 11.88 10.5301H18.23V21.0701H6.73001V3.72008H6.72Z" fill="#151917"/>
                                            </svg>
                                        </div>
                                        <!-- <img src="https://miro.medium.com/max/2400/0*LZGw5v0-0tlsiKxR.png" /> -->
                                    </div>
                                </div>
                            </div>
                            <div class="receipt-files">
                                <div 
                                    v-for="file in receipt.files" 
                                    :key="file.id" 
                                    class="file" 
                                    :class="{active: activeFileID === file.id}" 
                                    @click="activeFileID = file.id" 
                                    :style="`background-image: url(${file.imagePaths[0]})`"
                                >
                                    <div v-if="!file.imagePaths[0]" class="file-placeholder-wrapper">
                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.7 8.90008L13.05 2.19009C12.85 1.99009 12.63 2.00008 12.63 2.00008H5.47C5.21 2.00008 5 2.22009 5 2.49009V22.1701C5 22.5801 5.22002 22.8001 5.49002 22.8001H19.36C19.69 22.8001 19.95 22.5401 19.95 22.2101V9.44008C19.95 9.33008 19.93 9.13008 19.7 8.90008ZM13.13 4.72008L17.19 8.81008H13.13V4.72008ZM6.72 3.72008H11.4L11.39 10.0401C11.39 10.3101 11.61 10.5301 11.88 10.5301H18.23V21.0701H6.73001V3.72008H6.72Z" fill="#151917"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="add-file">
                                    <input
                                        type="file"
                                        id="files-input"
                                        ref="filesInput"
                                        name="files"
                                        @change="handleFileChanges"
                                    />
                                    <label class="chooser-label" for="files-input"></label>

                                    <svg viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                        <path d="M11.7029 12.5735C11.7029 12.3035 11.4829 12.0835 11.2129 12.0835H10.4729C10.2029 12.0835 9.98287 12.3035 9.98287 12.5735V16.6335H5.92287C5.65287 16.6335 5.43287 16.8535 5.43287 17.1235V17.8635C5.43287 18.1335 5.65287 18.3535 5.92287 18.3535H9.98287V22.4135C9.98287 22.6835 10.2029 22.9035 10.4729 22.9035H11.2129C11.4829 22.9035 11.7029 22.6835 11.7029 22.4135V18.3535H15.7629C16.0329 18.3535 16.2529 18.1335 16.2529 17.8635V17.1235C16.2529 16.8535 16.0329 16.6335 15.7629 16.6335H11.7029V12.5735ZM20.9929 7.5235C20.9929 7.4135 20.9729 7.2135 20.7429 6.9835L14.0929 0.273496C13.8929 0.0834961 13.6629 0.0834961 13.6629 0.0834961H1.18287C0.912871 0.0834961 0.692871 0.303496 0.692871 0.573496V27.0835C0.692871 27.3535 0.902871 27.5635 1.17287 27.5735H1.18287H20.5029H20.5129C20.7829 27.5635 20.9929 27.3535 20.9929 27.0835V7.5235ZM14.1629 2.8035L18.2229 6.8935H14.1629V2.8035ZM19.2629 25.8535H13.6629H8.01287H2.41287V1.8035H12.4429L12.4329 8.1235C12.4329 8.3935 12.6529 8.6135 12.9229 8.6135H19.2729V25.8535H19.2629Z" fill="#58584D"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <!-- selection area with buttons and headlines -->
                        <div class="receipt-category-area">
                            <div class="headline">
                                <h4>Monat*</h4> 
                                <div v-if="activeLastMonth" class="info">
                                    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2744 17.7702H11.2744V15.7702H13.2744V17.7702Z" fill="#58584D"/>
                                        <path d="M13.2744 13.7702H11.2744V7.7702H13.2744V13.7702Z" fill="#58584D"/>
                                    </svg>

                                    <div class="info__content">
                                        Du kannst noch bis zum 5. des neuen Monats deine Belege aus dem Vormonat ablegen.
                                    </div>
                                </div>
                            </div>
                            <div class="categories__buttons">
                                <div 
                                    v-if="activeLastMonth" 
                                    class="btn--rounded" 
                                    :class="{active: receipt.month === lastMonth}" 
                                    @click="setMonth(lastMonth)"
                                >
                                    <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                    </svg>
                                    <svg class="delete-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                    </svg>
                                    {{ getMonthString(lastMonth) }}
                                </div>
                                <div class="btn--rounded" :class="{active: receipt.month === currentMonth}" @click="setMonth(currentMonth)">
                                    <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                    </svg>
                                    <svg class="delete-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                    </svg>
                                    {{ getMonthString(currentMonth) }}
                                </div>
                            </div>

                            <h4>Art*</h4>
                            <div class="categories__buttons">
                                <div 
                                    v-for="category in categories" 
                                    :key="category.id" 
                                    class="btn--rounded" :class="{active: category.id === receipt.categoryID}"
                                    @click="setCategory(category.id)"
                                >
                                    <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                    </svg>
                                    <svg class="delete-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                    </svg>
                                    {{ category.name }}
                                </div>
                            </div>

                            <AdditionalMetadata
                                :receipt="receipt"
                                @is-valid="additionalMetadataValid = $event"
                            />

                            <h4>Projektbezug</h4>
                            <div class="categories__buttons projects">
                                <div v-if="!receipt.project" class="categories__input" :class="{focused: focusedInput}">
                                    <input 
                                        ref="projectInput"
                                        type="text" 
                                        placeholder="Projektbezug hinzufügen" 
                                        v-model="projectSearchTerm" 
                                        :class="{hidden: projectSearchTerm.length}"
                                        @keyup.enter="handleProjectEnterKey"
                                        @keydown.tab="projectAutoFill($event)"
                                        @keydown.down="displayNextActiveProject"
                                        @focus="focusedInput = true" 
                                        @blur="focusedInput = false" 
                                    >
                                    <span ref="autoFillText" class="searchterm" :class="{active: activeProjects.length}"></span>
                                    <svg :class="{active: focusedInput}" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2744 2.7702C6.75441 2.7702 2.27441 7.2502 2.27441 12.7702C2.27441 18.2902 6.75441 22.7702 12.2744 22.7702C17.7944 22.7702 22.2744 18.2902 22.2744 12.7702C22.2744 7.2502 17.7944 2.7702 12.2744 2.7702ZM11.2744 7.7702V11.7702H7.27441V13.7702H11.2744V17.7702H13.2744V13.7702H17.2744V11.7702H13.2744V7.7702H11.2744ZM4.27441 12.7702C4.27441 17.1802 7.86441 20.7702 12.2744 20.7702C16.6844 20.7702 20.2744 17.1802 20.2744 12.7702C20.2744 8.3602 16.6844 4.7702 12.2744 4.7702C7.86441 4.7702 4.27441 8.3602 4.27441 12.7702Z" fill="#58584D"/>
                                    </svg>
                                </div>
                                <p v-if="!receipt.project && projectSearchTerm.length > 0">Mit ENTER neues Projekt erstellen oder Projekt auswählen:</p>
                                
                                <div>
                                    <!-- element for active project -->
                                    <div 
                                        v-if="receipt.project"
                                        class="btn--rounded project-btn active"
                                        @click="deleteProject"
                                    >
                                        <svg class="checkmark" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                                        </svg>
                                        <svg class="close-btn" width="14" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                                        </svg>
                                        <span ref="projectname"> {{ receipt.project }} </span>
                                    </div>

                                    <!-- element for displayed projects when none is selected -->
                                    <div 
                                        v-for="project in activeProjects" 
                                        :key="project" 
                                        class="btn--rounded project-btn" :class="{hidden: receipt.project}" 
                                        @click="setProject(project)"
                                    >
                                        <svg v-if="project !== receipt.project" :class="{active: focusedInput}" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2744 2.7702C6.75441 2.7702 2.27441 7.2502 2.27441 12.7702C2.27441 18.2902 6.75441 22.7702 12.2744 22.7702C17.7944 22.7702 22.2744 18.2902 22.2744 12.7702C22.2744 7.2502 17.7944 2.7702 12.2744 2.7702ZM11.2744 7.7702V11.7702H7.27441V13.7702H11.2744V17.7702H13.2744V13.7702H17.2744V11.7702H13.2744V7.7702H11.2744ZM4.27441 12.7702C4.27441 17.1802 7.86441 20.7702 12.2744 20.7702C16.6844 20.7702 20.2744 17.1802 20.2744 12.7702C20.2744 8.3602 16.6844 4.7702 12.2744 4.7702C7.86441 4.7702 4.27441 8.3602 4.27441 12.7702Z" fill="#58584D"/>
                                        </svg>
                                        <span ref="projectname"> {{ project }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <a v-if="!autoSaveNoticeIsActive && !resetNoticeIsActive && !$store.getters['auth/isStaff']" class="footer-link footer-link--mobile" href="#" @click.prevent="help">
                    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3101 6.56995H14.0401C14.3101 6.56995 14.5301 6.34996 14.5301 6.07996V5.33994C14.5301 5.06994 14.3101 4.84995 14.0401 4.84995H13.3101C13.0401 4.84995 12.8201 5.06994 12.8201 5.33994V6.07996C12.8201 6.34996 13.0401 6.56995 13.3101 6.56995ZM9.71014 6.56995H10.4401C10.7101 6.56995 10.9301 6.34996 10.9301 6.07996V5.33994C10.9301 5.06994 10.7101 4.84995 10.4401 4.84995H9.71014C9.44014 4.84995 9.22014 5.06994 9.22014 5.33994V6.07996C9.22014 6.34996 9.44014 6.56995 9.71014 6.56995ZM6.12013 6.56995H6.85014C7.12014 6.56995 7.34013 6.34996 7.34013 6.07996V5.33994C7.34013 5.06994 7.12014 4.84995 6.85014 4.84995H6.12013C5.85013 4.84995 5.63013 5.06994 5.63013 5.33994V6.07996C5.63013 6.34996 5.85013 6.56995 6.12013 6.56995ZM19.5701 0.439941H1.05013H0.880135C0.610135 0.439941 0.390137 0.659932 0.390137 0.929932V10.5399C0.390137 10.6799 0.440136 10.8 0.530136 10.89C0.620136 10.98 0.740135 11.0299 0.880135 11.0299H15.5901L18.4601 13.7599C18.5201 13.8099 18.5801 13.8499 18.6501 13.8699C18.7001 13.8899 18.7601 13.8999 18.8101 13.8999H19.5501C19.8201 13.8999 20.0401 13.6799 20.0401 13.4099V1.69995C20.0401 1.68995 20.0501 1.67995 20.0501 1.66995V0.929932C20.0601 0.659932 19.8401 0.439941 19.5701 0.439941ZM18.3301 11.2599L16.4501 9.47995C16.3601 9.39995 16.2501 9.35995 16.1401 9.34995C16.0801 9.31995 16.0201 9.30994 15.9501 9.30994H2.11014V2.15994H18.3301V11.2599Z" fill="#151917"/>
                    </svg>
                    Ticket schreiben
                </a>
                <div>
                    <div v-if="autoSaveNoticeIsActive" class="autosave-hint">
                        <div class="checkmark">
                            <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                            </svg>
                        </div>
                        <div class="text-content text-content--save">
                            <span class="text-content--mobile">Automatische Speicherung. </span> 
                            <span class="text-content--desktop">Deine Auswahl wurde automatisch gespeichert. </span> 
                            <a class="text--bold" @click.prevent="resetReceipt">Beleg zurücksetzen.</a>
                        </div> 
                    </div>
                    <div v-if="resetNoticeIsActive" class="autosave-hint">
                        <div class="checkmark">
                            <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
                            </svg>
                        </div>
                        <div class="text-content">
                            Der Beleg wurde erfolgreich zurückgesetzt.
                        </div> 
                    </div>
                </div>

                <div class="footer-buttons">
                     <a class="footer-link footer-link--desktop" href="#" v-if="!$store.getters['auth/isStaff']" @click.prevent="help">
                        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3101 6.56995H14.0401C14.3101 6.56995 14.5301 6.34996 14.5301 6.07996V5.33994C14.5301 5.06994 14.3101 4.84995 14.0401 4.84995H13.3101C13.0401 4.84995 12.8201 5.06994 12.8201 5.33994V6.07996C12.8201 6.34996 13.0401 6.56995 13.3101 6.56995ZM9.71014 6.56995H10.4401C10.7101 6.56995 10.9301 6.34996 10.9301 6.07996V5.33994C10.9301 5.06994 10.7101 4.84995 10.4401 4.84995H9.71014C9.44014 4.84995 9.22014 5.06994 9.22014 5.33994V6.07996C9.22014 6.34996 9.44014 6.56995 9.71014 6.56995ZM6.12013 6.56995H6.85014C7.12014 6.56995 7.34013 6.34996 7.34013 6.07996V5.33994C7.34013 5.06994 7.12014 4.84995 6.85014 4.84995H6.12013C5.85013 4.84995 5.63013 5.06994 5.63013 5.33994V6.07996C5.63013 6.34996 5.85013 6.56995 6.12013 6.56995ZM19.5701 0.439941H1.05013H0.880135C0.610135 0.439941 0.390137 0.659932 0.390137 0.929932V10.5399C0.390137 10.6799 0.440136 10.8 0.530136 10.89C0.620136 10.98 0.740135 11.0299 0.880135 11.0299H15.5901L18.4601 13.7599C18.5201 13.8099 18.5801 13.8499 18.6501 13.8699C18.7001 13.8899 18.7601 13.8999 18.8101 13.8999H19.5501C19.8201 13.8999 20.0401 13.6799 20.0401 13.4099V1.69995C20.0401 1.68995 20.0501 1.67995 20.0501 1.66995V0.929932C20.0601 0.659932 19.8401 0.439941 19.5701 0.439941ZM18.3301 11.2599L16.4501 9.47995C16.3601 9.39995 16.2501 9.35995 16.1401 9.34995C16.0801 9.31995 16.0201 9.30994 15.9501 9.30994H2.11014V2.15994H18.3301V11.2599Z" fill="#151917"/>
                        </svg>
                        Ticket schreiben
                    </a>
                    <div class="staff-btns" v-if="$store.getters['auth/isStaff']"> 
                        <div v-if="!autoSaveNoticeIsActive && !receipt.archived && receipt.needInfoTicketID" class="text-content">
                            Kunde wurde benachrichtigt!
                        </div> 
                        <div
                            :class="['btn btn--blue']"
                            v-if="!receipt.archived && !receipt.needInfoTicketID"
                            @click="handleNeedInfoClick">
                                <div v-if="needInfoLoading" class="loadingspinner">
                                    <LoadingSpinner />
                                </div>
                            <span :class="{hidden: needInfoLoading}">Rückfrage zu Beleg</span>
                        </div>
                        <div class="btn btn--blue" v-if="!receipt.archived && receipt.needInfoTicketID">
                            <a
                                target="_blank"
                                :href="'https://summarum.freshdesk.com/a/tickets/' + receipt.needInfoTicketID"
                            >
                                Zum Rückfrage-Ticket
                            </a>
                        </div>
                        <div
                            class="btn btn--blue"
                            @click="$store.dispatch(`receipt/downloadReceitpsByID`, [receipt.id])">
                            Beleg herunterladen
                        </div>
                        <div
                            class="btn btn--blue"
                            v-if="receipt.archived"
                            @click="handleBookRevertClick"
                            :class="{'btn--disabled': !isValid}"
                        >
                            Buchung rückgängig machen
                        </div>
                        <div
                            class="btn btn--blue"
                            v-if="!receipt.archived"
                            @click="handleBookClick"
                            :class="{'btn--disabled': !isValid}"
                        >
                            Beleg buchen
                        </div>
                    </div>

                    <!-- Desktop button with longer text -->
                    <div
                        v-if="!$store.getters['auth/isStaff']"
                        class="btn btn--blue btn--desktop"
                        @click="handleApproveClick"
                        :class="{'btn--disabled': !isValid || receipt.approved}"
                    >
                        <div v-if="submitLoad" class="loadingspinner">
                            <LoadingSpinner />
                        </div>
                        <span :class="{hidden: submitLoad}">Beleg zur Buchung freigeben</span>
                    </div>
                    <!-- Mobile button with shorter text -->
                    <div
                        v-if="!$store.getters['auth/isStaff']"
                        class="btn btn--blue btn--mobile"
                        @click="handleApproveClick"
                        :class="{'btn--disabled': !isValid || receipt.approved}"
                    >
                        <div v-if="submitLoad" class="loadingspinner">
                            <LoadingSpinner />
                        </div>
                        <span :class="{hidden: submitLoad}">Freigeben</span>
                    </div>
                </div>
            </template>
        </Modal>
        <DeleteModal 
            v-if="confirmDeleteModalOpen"
            :above-modal="true"
            @delete-receipt="handleConfirmDeleteReceiptClick"
            @close-modal="confirmDeleteModalOpen = false"
        > 
        </DeleteModal>
    </div>
</template>

<script>
import Modal from '../partials/Modal'
import DeleteModal from '../partials/modals/DeleteModal'
import InputField from '../partials/InputField'
import AdditionalMetadata from './ReceiptDetail/AdditionalMetadata'
import LoadingSpinner from '../partials/LoadingSpinner'
import { mapState } from 'vuex'

/**
 * Variable that defines the time how long the autosave notices should be displayed before disappearing
 */
const TIMER = 6000

export default {
    name: 'ReceiptDetail',
    components: {
        Modal,
        InputField,
        AdditionalMetadata,
        LoadingSpinner,
        DeleteModal
    },
    props: {
        receipt: Object,
    },
    data() {
        return {
            month: undefined,
            /**
             * current month as a number
             */
            currentMonth: 0,
            /**
             * last month as a number
             */
            lastMonth: 0,
            /**
             * Detects if last month is active
             * is true, when current day is <= 5
             */
            activeLastMonth: false,
            /**
             * Detects if input is focused
             */
            focusedInput: false,
            /**
             * Variable to store active projects that are displayed in the 'Projektbezug' section
             */
            activeProjects: [],
            /**
             * Variable to store what the user writes in the project input field
             */
            projectSearchTerm: '',
            /**
             * selected project name of receipt
             */
            projectName: '',
            /**
             * Detects the id of the current active receipt file
             */
            activeFileID: this.receipt.files[0].id,
            /**
             * Detects if user has submitted 
             */
            submitLoad: false,
            /**
             * Detects if additional Metadata is valid
             */
            additionalMetadataValid: false,
            /**
             * Open state of delete confirmation modal
             */
            confirmDeleteModalOpen: false,
            /**
             * Detects if autosave-notice is active
             * gets true when user makes changes for receipt
             */
            autoSaveNoticeIsActive: false,
            /**
             * Detects if reset-notice is active
             * gets true when user clicks on "Beleg zurücksetzen"
             */
            resetNoticeIsActive: false,
            /**
             * Timer for autosave notices to be displayed
             */
            timer: null,
            /**
             * Variable to store the autofill string for 'Projektbezug' input 
             */
            autoFillString: '',
            /**
             * Variable that controls current index for 'Projektbezug' input autofill
             */
            autoFillIndex: 0,
            /**
             * Loading state of need info (summarum macht's rückfragen) button
             */
            needInfoLoading: false,

            clientIsInformed: false
        }
    },
    computed: {
        ...mapState({
            /**
             * Returns all categories stored in the backend
             */
            categories(state) {
                return state.receipt.categories
            }
        }),

        /**
         * Returns an archived receipt
         */
        archived() {
            return !this.$store.getters['auth/isStaff'] && this.receipt.archived
        },

        /**
         * Returns valid value
         * Sets button to clickable state when following neccessary buttons have been clicked. 
         */
        isValid() {
            return !this.receipt.missingData
        },
    },
    watch: {
        /**
         * Listener for 'Projektbezug' input field
         * Displays project button when project contains search value 
         * @param {String} val
         */
        projectSearchTerm: function(val) {
            this.$refs.autoFillText.innerHTML = val
            const searchValue = val.toLowerCase()
            const storedProjects = this.$store.state.receipt.projects

            storedProjects.forEach((project) => {
                let projectName = project.toLowerCase()
                let activeProjectIndex = this.activeProjects.indexOf(project)
                this.autoFillString = this.projectSearchTerm

                if (val.length === 0) {
                    this.activeProjects = []
                    return
                } 
                
                if (projectName.indexOf(searchValue) !== -1 && activeProjectIndex === -1) {
                    this.activeProjects.push(project)
                } else if (projectName.indexOf(searchValue) === -1 && activeProjectIndex > -1) {
                    this.activeProjects.splice(activeProjectIndex, 1)
                }

                if (this.activeProjects.length) {
                    this.createProjectAutoFillString(this.activeProjects[0], val)
                }
            })
        }
    },
    created () {
        this.currentMonth = this.updateCurrentMonth()
        this.lastMonth = this.updateLastMonth()
        this.activeLastMonth = this.checkCurrentDate()
        this.activeFileID = this.receipt.files[0].id
    },
    methods: {
        /**
         * Hets month number and returns its name a string
         * @param {Int} number
         */
        getMonthString(number) {
            let months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months[number-1]
        },

        /**
         * Updates currentMonth 
         */
        updateCurrentMonth () {
            let currentDate = new Date()
            return currentDate.getMonth() + 1
        },

        /**
         * Updates lastMonth
         */
        updateLastMonth () {
            let currentDate = new Date()
            currentDate.setMonth(currentDate.getMonth() - 1)
            return currentDate.getMonth() + 1
        },

        /**
         * Updates activeLastMonth depending on if currentday is bigger than 5
         */
        checkCurrentDate() {
            let date = new Date()
            let currentDay = date.getDate()
            return this.activeLastMonth = currentDay > 5 ? false : true
        },

        /**
         * Function that opens ticket help pop up and fills it with receipt data 
         */
        help() {
            this.$store.dispatch(`receipt/openTicketHelp`, {
                receipt: this.receipt,
                userUrl: window.location.origin + this.$route.path
            })
        },

        /**
         * Function that detects selected month and stores its value in the receipt
         * @param {Int} month
         */
        setMonth(month) {
            const now = new Date()
            const year = now.getMonth() === 0 && month === 12 ? now.getFullYear() - 1 : now.getFullYear()

            if (this.receipt.month === month) {
                this.$store.dispatch(`receipt/setDateForReceipt`, {
                    receiptID: this.receipt.id,
                    year: null,
                    month: null
                })
            } else {
                this.$store.dispatch(`receipt/setDateForReceipt`, {
                    receiptID: this.receipt.id,
                    year: year,
                    month: month
                })
                this.showAutoSaveNotice()
            }
        },

        /**
         * Function that detects selected categoryID and stores its value in the receipt
         * @param {String} id
         */
        setCategory(id) {
            if (this.receipt.categoryID === id) {
                this.$store.dispatch(`receipt/setCategory`, {
                    receiptID: this.receipt.id,
                    categoryID: "NONE"
                })
            } else {
                this.$store.dispatch(`receipt/setCategory`, {
                    receiptID: this.receipt.id,
                    categoryID: id
                })
                this.showAutoSaveNotice()
            }
        },

        /**
         * Change event listener for file upload 
         * Adds new file to the receipt
         * @param {Event} ev
         */
        handleFileChanges(ev) {
            ([...ev.target.files]).forEach(f => {
                this.$store.dispatch(`receipt/addReceiptFile`, {
                    file: f,
                    receiptID: this.receipt.id
                })
            })
        },

        /**
         * Click event listener for delete button
         * Activates first file in array 
         */
        activateFirstFile() {
            this.activeFileID = this.receipt.files[0].id
        },

        /**
         * Handle click on delete file button. If user is about to delete the last file, show modal to confirm deletion of receipt.
         */
        handleDeleteFileClick() {
            if (this.receipt.files.length > 1) {
                this.$store.dispatch(`receipt/deleteReceiptFile`, {receiptFileID: this.activeFileID, receiptID: this.receipt.id})
                this.activateFirstFile()
            } else {
                this.confirmDeleteModalOpen = true
            }
        },

        /**
         * Function that detects selected project and stores its value in the receipt 
         * @param {String} name
         */
        async setProject(name) {
            await this.$store.dispatch(`receipt/setProjectForReceipt`, {
                receiptID: this.receipt.id,
                projectName: name
            })

            this.showAutoSaveNotice()
        },

        /**
         * Function that "unsets project" and sets value back to undefined 
         */
        async deleteProject() {
            await this.$store.dispatch(`receipt/setProjectForReceipt`, {
                receiptID: this.receipt.id,
                projectName: null
            })
            this.projectSearchTerm = ''

            return
        },

        /**
         * 
         */
        handleProjectEnterKey() {
            const projects = this.$store.state.receipt.projects

            projects.forEach(project => {
                if (project === this.projectSearchTerm) {
                    this.setProject(this.projectSearchTerm)
                    this.activeProjects = []
                    return
                }
            })

            this.addProjectToList()
        },

        /**
         * Keyup event listener for project input field
         * Adds new project to array when it doesn't exist yet
         */
        addProjectToList() {
            this.$store.state.receipt.projects.push(this.projectSearchTerm)
            this.activeProjects.push(this.projectSearchTerm)
            this.setProject(this.projectSearchTerm)
        },

        /**
         * Function that takes a string and returns a new string
         * @param {String} string this is the string that gets divided 
         * @param {String} value this is the entered value that is supposed to be displayed bold
         */
        createProjectAutoFillString(string, value) {
            let substrIndex = string.toLowerCase().indexOf(value.toLowerCase())
            if (substrIndex !== -1) {
                
                let firstString = string.substring(0, substrIndex)
                let middleString = string.substring(substrIndex + value.length, substrIndex)
                let lastString = string.substring(string.length - (string.length - substrIndex - value.length))
    
                this.autoFillString = firstString + '<strong>' + middleString + '</strong>' + lastString
                this.$refs.autoFillText.innerHTML = this.autoFillString
            }
        },

        /**
         * Tab keydown event listener for 'Projektbezug' input field
         * sets projectSearchTerm to current 
         */
        projectAutoFill(event) {
            event.preventDefault()

            this.projectSearchTerm = this.autoFillString.replace(/<[^>]*>?/gm, '');
        },

        /**
         * Down-arrow Keydown event listener for 'Projektbezug' input field 
         * Iterates through active projects and displays next active project name in inputfield
         */
        displayNextActiveProject() {
            if (this.activeProjects.length === 0) {
                return
            }
            
            let maxIndex = this.activeProjects.length - 1
            this.autoFillIndex = this.autoFillIndex === maxIndex ? 0 : this.autoFillIndex + 1

            this.createProjectAutoFillString(this.activeProjects[this.autoFillIndex], this.projectSearchTerm)
        },

        /**
         * Click event listener for 'Beleg zur Buchung freigeben' button
         * submits stored receipt information to the backend 
         */
        async handleApproveClick() {
            this.submitLoad = true
            await this.$store.dispatch(`receipt/setReceiptApprovedStatus`, {
                receiptID: this.receipt.id,
                approved: true
            })
            this.submitLoad = false
            this.$router.push({name: 'collection'})
        },

        async handleBookRevertClick() {
            await this.$store.dispatch(`receipt/setIsArchiveStatus`, {
                receiptID: this.receipt.id,
                archived: false
            })
        },

        async handleBookClick() {
            await this.$store.dispatch(`receipt/setIsArchiveStatus`, {
                receiptID: this.receipt.id,
                archived: true
            })
        },

        /**
         * Handle click on confirm in receipt delete confirmation modal
         */
        handleConfirmDeleteReceiptClick() {
            this.confirmDeleteModalOpen = false
            this.$emit('close-modal')
            this.$store.dispatch(`receipt/deleteReceipt`, this.receipt.id)
        },

        /**
         * Function that listens to changes for buttons and inputs 
         */
        showAutoSaveNotice() {
            clearTimeout(this.timer)

            this.resetNoticeIsActive = false
            this.autoSaveNoticeIsActive = true
            this.timer = setTimeout(() => { this.autoSaveNoticeIsActive = false }, TIMER)
        },

        /**
         * Click event listener for "Beleg zurücksetzen" link
         * Resets receipt and shows reset notice
         */
        resetReceipt() {
            clearTimeout(this.timer)

            this.autoSaveNoticeIsActive = false
            this.resetNoticeIsActive = true
            
            this.timer = setTimeout(() => { this.resetNoticeIsActive = false }, TIMER)

            this.$store.dispatch(`receipt/resetMetadata`, this.receipt.id)
        },

        async handleNeedInfoClick() {
            if (this.needInfoLoading) return
            this.needInfoLoading = true
            await this.$store.dispatch(`staff/requestInfoFromUser`, {receiptID: this.receipt.id})
            this.needInfoLoading = false
            this.clientIsInformed = true
        }
    },

}
</script>

<style lang="scss">
    @import 'src/assets/style/variables.scss';

    .message {
        position: absolute;
        top: 80px;
        left: 30px;
        width: 420px;
        padding: 15px 15px 15px 50px;
        border-radius: 30px 30px 30px 0;
        background-color: $brand-blue;
        color: #fff;
        z-index: 20;
        font-size: 16px;
        line-height: 22px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        a {
            color: #fff;
            font-family: "Gotham-Medium";
        }
        p {
            padding-bottom: 30px;
            &:last-child {
                padding: 0;
            }
        }
        .hint-icon {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            color: $brand-blue;
            border-radius: 50%;
        }
    }

    .info {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $brand-blue;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        cursor: pointer;
        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 220px;
            height: auto;
            align-items: center;
            text-align: left;
            padding: 15px;
            font-size: 12px;
            line-height: 15px;
            background-color: $brand-blue;
            border-radius: 30px 30px 30px 0;
            display: none;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
            @media screen and (min-width: $medium-screen) {
                width: 290px;
                font-size: 16px;
                line-height: 22px;
            }
        }
        &:hover .info__content {
            display: flex;
        }
        svg path {
            fill: #fff;
        }
    }

    .overlay.white {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        background-color: #fff;
        opacity: 0.5;
        z-index: 10;
        border-radius: $brand-border-r;
        pointer-events: none;
    }
    .inactive .btn--rounded {
        pointer-events: none;
    }
    
    .receipt-modal-body {
        .hint {
            padding-bottom: 30px;
            font-size: 12px;
        }
        > .input-field {
            @media screen and (min-width: $medium-screen) {
                width: 40%;
            }
        }
        .flex-content {
            @media screen and (min-width: $large-screen) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
            .receipt-image-area {
                @media screen and (min-width: $large-screen) {
                    width: 50%;
                    margin-top: -100px;
                }
                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 10px;
                    @media screen and (min-width: $large-screen) {
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding: 0;
                    }
                    .export-btn {
                        cursor: pointer;
                        z-index: 20;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $brand-blue;
                        border-radius: 50%;
                        @media screen and (min-width: $medium-screen) {
                            width: 48px;
                            height: 48px;
                        }
                        @media screen and (min-width: $large-screen) {
                            width: 30px;
                            height: 30px;
                        }
                        &:hover {
                            background-color: $hover-blue;
                        }
                        svg {
                            width: 17px;
                            height: 17px;
                            @media screen and (min-width: $medium-screen) {
                                width: 23px;
                                height: 23px;
                            }
                            @media screen and (min-width: $large-screen) {
                                width: 15px;
                                height: 15px;
                            }
                            path {
                                fill: #fff;
                            }
                        }
                    }
                    .delete-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        background-color: $error;
                        border-radius: 50%;
                        margin-left: 15px;
                        cursor: pointer;
                        @media screen and (min-width: $medium-screen) {
                            width: 48px;
                            height: 48px;
                        }
                        @media screen and (min-width: $large-screen) {
                            width: 30px;
                            height: 30px;
                        }
                        svg {
                            @media screen and (min-width: $medium-screen) {
                                width: 23px;
                                height: 23px;
                            }
                            @media screen and (min-width: $large-screen) {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
                > .flex-content {
                    margin-bottom: 10px;
                    @media screen and (min-width: $medium-screen) {
                        margin-bottom: 15px;
                    }
                }
                .receipt-image-wrapper {
                    background-color: $default-grey;
                    border-radius: 30px 30px 30px 0;
                    width: 100%;
                    height: 40vw;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    z-index: 20;
                    @media screen and (min-width: $large-screen) {
                        width: 480px;
                        height: 500px;
                    }
                    .receipt-images {
                        width: calc(100% - 40px);
                        height: calc(100% - 20px);
                        overflow-x: hidden;
                        @media screen and (min-width: $medium-screen) {
                            width: calc(100% - 120px);
                            height: calc(100% - 60px);
                        }
                        @media screen and (min-width: $large-screen) {
                            width: calc(100% - 60px);
                            height: calc(100% - 30px);
                        }
                        img {
                            width: 100%;
                            border-bottom: 5px solid $default-grey;
                        }
                    }
                }
                .receipt-files {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 90%;
                    .file {
                        width: 35px;
                        height: 50px;
                        border: 2px solid $default-grey;
                        border-radius: 5px 5px 5px 0;
                        margin-right: 15px;
                        margin-bottom: 10px;
                        cursor: pointer;
                        background-position-x: center;
                        background-position-y: top;
                        background-size: cover;
                        background-repeat: no-repeat;
                        @media screen and (min-width: $medium-screen) {
                            width: 50px;
                            height: 70px;
                            margin-right: 30px;
                        }
                        @media screen and (min-width: $large-screen) {
                            margin-right: 15px;
                        }
                        &.active {
                            border-color: $brand-blue;
                        }
                    }
                    .add-file {
                        position: relative;
                        width: 35px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px dotted $default-grey;
                        border-radius: 10px 10px 10px 0;
                        background-color: $light-grey;
                        cursor: pointer;
                        &:hover {
                            border-color: $hover-blue;
                            svg path {
                                fill: $hover-blue;
                            }
                        }
                        @media screen and (min-width: $medium-screen) {
                            width: 50px;
                            height: 70px;
                            border-radius: 10px 10px 10px 0;
                        }
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 20px;
                            height: auto;
                            pointer-events: none;
                            path {
                                fill: $default-grey;
                            }
                        }
                        input {
                            opacity: 0;
                        }
                        .chooser-label {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }
                    }
                }
            }
            .file-placeholder-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
            .receipt-category-area {
                padding-top: 30px;
                @media screen and (min-width: $large-screen) {
                    width: 50%;
                }
                .headline {
                    display: flex;
                    align-items: center;
                }
                h4 {
                    display: inline;
                }
                .btn--rounded {
                    cursor: pointer;
                    font-size: 12px;
                    line-height: 22px;
                    margin-bottom: 10px;
                    @media screen and (min-width: $medium-screen) {
                        font-size: 14px;
                        padding: 5px 15px;
                    }
                    @media screen and (min-width: $large-screen) {
                        font-size: 16px;
                        padding: 8px 15px;
                    }
                    &:hover {
                        background: $hover-blue;
                        color: #fff;
                    }
                    &.active {
                        background: $brand-blue;
                        color: #fff;
                        &:hover {
                            background: $hover-blue;
                            svg.checkmark {
                                display: none;
                            }
                            svg.delete-btn {
                                display: block;
                            }
                        }
                        svg.checkmark {
                            display: block;
                        }
                    }
                    &.person {
                        padding-left: 0;
                        display: flex;
                        align-items: center;
                        background: transparent;
                        color: $brand-blue;
                        font-family: "Gotham-Medium";
                        font-size: 12px;
                        margin-right: 5px;
                        @media screen and (min-width: $medium-screen) {
                            font-size: 14px;
                        }
                        &:hover {
                            background: transparent;
                            color: $hover-blue;
                            svg path {
                                color: $hover-blue;
                            }
                        }
                        svg {
                            width: 20px;
                            height: 20px;
                            path {
                                fill: $brand-blue;
                            }
                        }
                    }
                    svg {
                        display: none;
                        path {
                            fill: #fff;
                        }
                    }
                    &.project-btn {
                        svg {
                            display: block;
                        }
                    }
                }
                .categories__buttons {
                    padding-top: 10px;
                    padding-bottom: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    &.projects {
                        display: block;
                        > div:last-child {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                    .categories__input {
                        position: relative;
                        margin-bottom: 20px;
                        border: 1px dotted $default-grey;
                        border-radius: 20px;
                        display: inline-block;
                        @media screen and (min-width: $medium-screen) {
                            margin-bottom: 30px;
                        }
                        &.focused {
                            border-color: $hover-blue;
                        }
                        &:hover {
                            border-color: $hover-blue;
                        }
                        input {
                            width: 210px;
                            height: 100%;
                            padding: 7.5px 15px 7.5px 40px;
                            background: transparent;
                            font-family: "Gotham-Medium";
                            font-size: 12px;
                            outline: none;
                            border: none;
                            @media screen and (min-width: $medium-screen) {
                                width: 255px;
                                padding: 7px 15px 7px 40px;
                                font-size: 14px;
                            }
                            @media screen and (min-width: $large-screen) {
                                padding: 8.5px 15px 8.5px 40px;
                                font-size: 16px;
                            }
                            &.hidden {
                                opacity: 0;
                            }
                            &::placeholder {
                                font-family: "Gotham-Regular";
                                color: $default-grey;
                                font-size: 12px;
                                @media screen and (min-width: $medium-screen) {
                                    font-size: 14px;
                                }
                                @media screen and (min-width: $large-screen) {
                                    font-size: 16px;
                                }
                            }
                            &:hover {
                                border-color: $hover-blue;
                                + svg path {
                                    fill: $hover-blue;
                                }
                            }
                            &:focus {
                                border-color: $hover-blue;
                            }
                        }
                        svg {
                            position: absolute;
                            left: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            &.active {
                                path {
                                    fill: $hover-blue;
                                }
                            }
                            path {
                                fill: $default-grey;
                            }
                        }
                        .searchterm {
                            position: absolute;
                            left: 40px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 12px;
                            font-family: "Gotham-Medium";
                            pointer-events: none;
                            @media screen and (min-width: $medium-screen) {
                                font-size: 14px;
                            }
                            @media screen and (min-width: $large-screen) {
                                font-size: 16px;
                            }
                            &.active {
                                color: $default-grey;
                            }
                            strong {
                                color: $body-text;
                            }
                        }
                    }
                    p {
                        font-size: 12px;
                        padding-bottom: 15px;
                    }
                }
                .project-btn {
                    padding-left: 40px;
                    position: relative;
                    &:hover {
                        svg.close-btn {
                            display: inline-block;
                            margin-bottom: 2px;
                        }
                        svg.checkmark {
                            display: none;
                        }
                    }
                    &.hidden {
                        display: none;
                    }
                    &.active {
                        padding-left: 15px;
                    }
                    svg {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        &.close-btn {
                            display: none;
                        }
                        &.checkmark, &.close-btn {
                            position: static;
                            transform: translateY(0);
                            margin-right: 10px;
                        }
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    .staff-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn {
            margin-left: 15px;
        }
        .text-content {
            @media screen and (min-width: $medium-screen) {
                max-width: 100px;
            }
            @media screen and (min-width: $large-screen) {
                max-width: none;
            }
        }
    }

    .footer-buttons {
        display: flex;
        justify-content: flex-end;
    }
    .footer-link {
        font-size: 12px;
        line-height: 22px;
        font-family: "Gotham-Medium";
        display: flex;
        align-items: center;
        @media screen and (min-width: $medium-screen) {
            font-size: 14px;
        }
        &:hover {
            svg path {
                fill: $hover-blue;
            }
        }
        svg {
            margin-right: 10px;
            path {
                fill: $brand-blue;
            }
        }
        &--desktop {
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: flex;
            }
        }
        &--mobile {
            @media screen and (min-width: $medium-screen) {
                display: none;
            }
        }
    }

    .text-content {
        font-size: 10px;
        @media screen and (min-width: $medium-screen) {
            font-size: 12px;
        }
    }
    .autosave-hint {
        display: flex;
        align-items: center;
        @media screen and (min-width: $large-screen) {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
        }
        .checkmark {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $brand-blue;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            @media screen and (min-width: $medium-screen) {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
            svg {
                width: 12px;
                height: 12px;
                path {
                    fill: #fff;
                }
            }
        }
        .text-content {
            max-width: 150px;
            @media screen and (min-width: $medium-screen) {
                max-width: 250px;
            }
            a {
                cursor: pointer;
            }
            &--mobile {
                @media screen and (min-width: $medium-screen) {
                    display: none;
                }
            }
            &--desktop {
                display: none;
                @media screen and (min-width: $medium-screen) {
                    display: inline;
                }
            }
        }
    }
    .btn--blue {
        margin-left: 30px;
        position: relative;
        .loadingspinner {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        span.hidden {
            opacity: 0;
        }
        a, a:hover {
            color: #FFF;
        }
        &.btn--desktop {
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: inline-block;
            }
        }
        &.btn--mobile {
            margin-left: 0;
            @media screen and (min-width: $medium-screen) {
                display: none;
            }
        }
    }

    .addmetadata_coll-fields {
        padding-top: 10px;
        padding-bottom: 5px;
    }
    .additionalProperties {
        padding-bottom: 30px;
    }
    .add-button {
        padding-top: 10px;
        .btn--rounded {
            display: inline-block;
        }
    }

</style>