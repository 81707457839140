import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import routes from './routes'
    
import { getFreshDeskSSOUrl } from '../graphql/api'

import { FILTER_CURRENT_MONTH, FILTER_PREVIOUS_MONTH } from '../store/actions/'
import { toggleButton } from '../lib/freshdeskWidget'


Vue.use(Router)

const router = new Router({
    routes: routes,
    mode: 'history'
})

router.beforeEach(async (to, from, next) => {

    if (store.state.authState === undefined) {
        await store.dispatch('auth/fetchUserData')
    }

    const isSignedIn = store.state.auth.state === 'signedIn'
    const isStaff = store.getters['auth/isStaff']

    console.log('from', from)
    console.log('to', to)
    console.log('auth', store.state.auth.state)
    console.log('signed in', isSignedIn)


    if (!isSignedIn) {
        store.commit('auth/setLoading', false)
        if (to.name !== 'login') {
            return next({
                name: 'login',
                // query: {
                //     target: to.name
                // }
            })
        } else {
            store.commit('setPageLoading', false)
            return next()
        }
    }

    if (isSignedIn) {
        if (window.widgetLoaded) {
            store.dispatch('prefillWidget')
        }

        // redirect to target page (defined in "target" URL query parameter) if user is logged in
        // if (from.name === 'login' && from.query.target) {
        //     console.log('navigate from query')
        //     return next({
        //         name: from.query.target
        //     })
        // }

        // redirect to home page if user is logged in and navigates to login page
        if (to.name === 'login') {
            return next({
                name: 'home'
            })
        }

        if (to.name === 'freshdesk') {
            toggleButton(false)
            const redirect = await getFreshDeskSSOUrl(to.query.redirect_uri, to.query.state, to.query.nonce)
            window.location = redirect
        }

        store.commit('setPageLoading', false)

        // if user is staff, redirect to staff page on navigation to home
        if (isStaff) {
            if (to.name === 'home') {
                return next({
                    name: 'staff'
                })
            }
            if (to.name === 'staff') {
                store.dispatch('staff/fetchUsers')
            }

            // user selected
            if (to.query.username || from.query.username) {
                await store.dispatch('staff/fetchUsers')
                store.dispatch('staff/selectUser', to.query.username || from.query.username)
            }

        } else {
            // redirect regular user to home page on navigation to staff
            if (to.name === 'staff') {
                return next({
                    name: 'home'
                })
            }
        }


        // hide menu on navigation
        store.commit('setMenuHidden', true)

        // reset checkboxes on navigation
        store.dispatch('resetCheckboxes')

        // reset search status on navigation
        store.commit(`receipt/setSearchStatus`, false)

        // reset scroll position on navigation
        window.scrollTo(0,0)

        //
        // data fetching
        //

        // init store
        if (!store.state.receipt.initialized) {
            await store.dispatch('receipt/init')
            await store.dispatch('report/init')
        }
    
        // receipts collection
        if (to.name === 'collection') {
            store.commit(`receipt/setIsArchive`, false)
            await store.dispatch(`receipt/resetFilters`)
        }

        // receipts archive
        if (to.name && to.name.includes('archive')) {
            store.commit(`receipt/setIsArchive`, true)

            if (to.params.year && to.params.month) {
                store.dispatch(`receipt/filterMonthRange`, {
                    fromDateMonth: to.params.month || 1,
                    toDateMonth: to.params.month || 12
                })
                store.dispatch(`receipt/filterYearRange`, {
                    fromDateYear: to.params.year,
                    toDateYear: to.params.year,
                })
            } else {
                store.dispatch(`receipt/resetFilters`)
            }
        }

        if (to.name === 'currentMonthReceiptlist') {
            store.commit(`receipt/setIsArchive`, true)
            store.commit(`receipt/setFilterCategoryID`, to.params.categoryID)
            store.dispatch(`receipt/${FILTER_CURRENT_MONTH}`)
        } else if (to.name === 'lastMonthReceiptlist') {
            store.commit(`receipt/setIsArchive`, true)
            store.commit(`receipt/setFilterCategoryID`, to.params.categoryID)
            store.dispatch(`receipt/${FILTER_PREVIOUS_MONTH}`)
        }

        // receipt detail (modal)
        if (to.name && to.name.includes('receipt detail')) { // matches 'receipt detail' and 'receipt detail (direct link)' routes
            console.log('load again')
            if (await store.dispatch('receipt/openReceiptDetailModal', to.params.id)) {
                return next()
            } else {
                return next({
                    name: 'collection'
                })
            }
        } else if (from.name && from.name.includes('receipt detail')) {
            store.dispatch('receipt/closeReceiptDetailModal')
            return next()
        }

        // reports
        if (to.path && to.name === 'categoryReports') {
            store.dispatch('report/resetFilters')
            store.commit('report/setFilterCategoryID', to.params.categoryID)
            store.dispatch('report/load')
            store.dispatch('report/setupSubscriptions')
        }

        // documents
        if (to.path && to.path.includes('documents')) {
            store.dispatch('documents/load')
            store.dispatch('documents/setupSubscriptions')
        }


        return next()
    }



})

export default router
