<template>
<div id="app">
  <div class="loading" v-if="$store.state.pageLoading || $route.name === 'freshdesk'">
    <div class="spinner-wrapper">
      <LoadingSpinner color="blue"/>
    </div>
  </div>
  <div v-if="$route.name === 'login'" class="login-screen">
      <router-view></router-view>
  </div>
  <div v-if="!$store.state.pageLoading && $route.name !== 'login' && $route.name !== 'freshdesk'" class="app-container">
    <!-- <header class="header-area">
      <h1>Summarum Belege-Service</h1>
    </header> -->
    <Topbar v-on:show-menu="openMenu" v-on:toggle-menu="toggleMenu" v-on:start-tour="startTour"></Topbar>
    <Navigation :class="{hidden: $store.state.menuHidden, inactive: !activeMenu}"></Navigation>

    <div class="overlay" :class="{active: !$store.state.menuHidden}" @click="closeMenu"></div>
    <main class="main-area" :class="{expanded: !activeMenu}">
      <router-view v-on:start-tour="startTour" @upload-receipt="openReceiptUploadModal"></router-view>

      <Tour class="tour" :class="{fadeaway: !activeTour, hidden: hiddenTour}" v-on:close-modal="endTour" />
    </main>

    <CreateReceipt v-if="activeReceiptUploadModal" @close-modal="closeReceiptUploadModal" />
    <ReceiptDetail
      v-if="$store.state.receipt.detailModalOpen"
      @close-modal="closeReceiptDetailModal"
      :receipt="$store.state.receipt.detailReceipt"
      />
  </div>
</div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Topbar from './components/Topbar.vue'
import Tour from './components/Tour'
import CreateReceipt from './components/CreateReceipt'
import ReceiptDetail from './components/ReceiptList/ReceiptDetail'
import LoadingSpinner from './components/partials/LoadingSpinner'

export default {
    name: 'App',
    components: {
        Navigation,
        Topbar,
        Tour,
        CreateReceipt,
        ReceiptDetail,
        LoadingSpinner
    },
    data: () => {
        return {
            activeMenu: true,
            activeTour: false,
            hiddenTour: true,
            activeReceiptUploadModal: false
        }
    },
    methods: {
        /**
         * Clickevent function that opens the menu on small screens when user clicks on hamburger icon
         */
        openMenu() {
            this.$store.commit('setMenuHidden', false)
        },

        /** 
         * Clickevent function that closes the menu on small screens when user clicks outside the menu
         */
        closeMenu() {
            this.$store.commit('setMenuHidden', true)
        },

        /**
         * Clickevent function that toggles the menu styles on desktop screen 
         * User can "close" the menu -> only icons visible 
         */
        toggleMenu() {
            this.activeMenu = !this.activeMenu
            console.log(this.activeMenu)
        },

        startTour() {
            this.$store.state.receipt.activeModal = true
            this.activeTour = true
            this.hiddenTour = false
        },

        endTour() {
            this.$store.state.receipt.activeModal = false
            this.activeTour = false
            setTimeout(() => { this.hiddenTour = true }, 500)
        },

        closeReceiptDetailModal() {
            if (this.$route.name === 'receipt detail (direct link)') {
                this.$router.push('home')
            } else if (this.$route.name.includes('receipt detail')) {
                // go back to previous routing 'level', e.g. a month category folder
                const routeLevelUp = this.$route.matched[this.$route.matched.length - 2]
                this.$router.push({name: routeLevelUp.name})
            }
        },

        openReceiptUploadModal() {
            this.activeReceiptUploadModal = true
            this.$store.state.receipt.activeModal = true
        },
        closeReceiptUploadModal() {
            this.activeReceiptUploadModal = false
            this.$store.state.receipt.activeModal = false
        }
    },
    computed: {
        modalState() {
            return this.$store.state.receipt.activeModal
        }
    },
    watch: {
        /**
         * Listener for 'Projektbezug' input field
         * Displays project button when project contains search value 
         * @param {String} val
         */
        modalState: function(val) {
            if (val) document.documentElement.style.overflow = "hidden"
            else document.documentElement.style.overflow = "auto"
        }
    }
}
</script>

<style lang="scss">
  @import 'src/assets/style/variables.scss';
  @import 'src/assets/style/fonts.scss';
  @import 'src/assets/style/headings.scss';
  @import 'src/assets/style/buttons.scss';
  @import 'src/assets/style/main.scss';


  .main-area {
    background: #fff;
    border-radius: $brand-border-r;
    margin-bottom: 20px;
    min-height: calc(100vh - 20px - 63px); // 20px = margin bottom, 63px = top bar
    @media screen and (min-width: $medium-screen) {
      margin: 0 30px 30px 30px;
      min-height: calc(100vh - 30px - 100px); // 30px = margin bottom, 100px = top bar
    }
    @media screen and (min-width: $large-screen) {
      margin-left: 288px; // 288px = width of menu sidebar 
    }
    &.expanded {
      @media screen and (min-width: $large-screen) {
        margin-left: 80px;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $brand-deep-green;
    display: none;
    opacity: 0.8;
    z-index: 10;
    &.active {
      display: block;
    }
  }
  .login-screen {
    min-height: 100vh;
  }
  .tour {
      &.fadeaway {
          opacity: 0;
          transition: opacity 0.5s;
          &.hidden {
              display: none;
          }
      }
  }
  .loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .spinner-wrapper {
      width: 50px;
    }
  }
</style>
