<template>
    <div class="list-search">
        <div class="search-button">
            <svg width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4175 9.17999L6.6975 5.45996C7.5275 4.06996 7.3475 2.26 6.1575 1.06C5.4575 0.349998 4.5275 0 3.6075 0C2.6875 0 1.7575 0.349998 1.0575 1.06C-0.3525 2.47 -0.3525 4.75998 1.0575 6.16998C1.7575 6.87998 2.6875 7.22998 3.6075 7.22998C4.2475 7.22998 4.8775 7.04996 5.4475 6.70996L9.1675 10.43C9.3675 10.63 9.6875 10.63 9.8775 10.43L10.4175 9.88995C10.6175 9.68995 10.6175 9.36999 10.4175 9.17999ZM5.4675 5.46997C4.9675 5.96997 4.3075 6.23999 3.6075 6.23999C2.9075 6.23999 2.2475 5.96997 1.7475 5.46997C1.2475 4.96997 0.977498 4.30999 0.977498 3.60999C0.977498 2.90999 1.2475 2.25 1.7475 1.75C2.2475 1.25 2.9075 0.97998 3.6075 0.97998C4.3075 0.97998 4.9675 1.25 5.4675 1.75C5.9675 2.25 6.2375 2.90999 6.2375 3.60999C6.2375 4.31999 5.9675 4.97997 5.4675 5.46997Z" fill="#151917"/>
            </svg>
        </div>

        <input class="list-search-input" :class="{active: searchTerm}" placeholder="Suche" type="text" @keyup="handleSearchChange" v-model="searchTerm">
     
        <div class="close-button" v-if="searchTerm.length > 0" @click="closeSearch">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.8268 4.66868L12.1787 10.3167L6.53447 4.67246C6.25099 4.38899 5.79124 4.38259 5.5016 4.67223L4.72712 5.44671C4.44377 5.73006 4.43757 6.18981 4.72735 6.47958L10.3779 12.1301L4.73616 17.7719C4.44022 18.0552 4.44662 18.515 4.73009 18.7984L5.51121 19.5796C5.79469 19.863 6.25444 19.8694 6.54408 19.5798L12.1921 13.9318L17.8427 19.5823C18.1261 19.8658 18.5922 19.8659 18.8755 19.5825L19.6563 18.8018C19.9397 18.5184 19.9396 18.0524 19.6561 17.7689L14.0055 12.1183L19.6536 6.47031C19.9369 6.18696 19.9368 5.72091 19.6533 5.43744L18.8722 4.65631C18.5762 4.38543 18.1101 4.38533 17.8268 4.66868Z" fill="#141917"/>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SearchBar',
    props: {
        /**
         * vuex path to the commit that changes the boolean value that represents the search bar status
         * (true: search is active, false: search inactive)
         */
        activeSearchState: {
            type: String,
            required: true
        },
        /**
         * vuex action path to the action that commits the search. The action is called with the serch term as a parameter.
         */
        commitSearchAction: {
            type: String,
            required: true
        },
        /**
         * vuex action path to the action that closes the search. Is called on close button klick or on empty search field.
         */
        closeSearchAction: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            /**
             * Detects searchterm
             */
            searchTerm: "",
            /**
             * Timer for search input
             */
            keyUpTimer: null,
        }
    },
    computed: mapState({
        /**
         * Returns searchStatus
         */
        searchStatus(state) {
            return state.receipt.searchStatus
        },
    }),
    methods: {
        /**
         * Keyup event listener for input field
         */
        handleSearchChange() {
            if (this.searchTerm === "") {
                this.closeSearch()
                this.$store.commit(this.activeSearchState, false)
                return
            }
            clearTimeout(this.keyUpTimer)
            this.keyUpTimer = setTimeout(this.search, 300)

            this.$store.commit(this.activeSearchState, true)
        },

        /**
         * Function that reloads content based on search value
         */
        search() {
            this.$store.dispatch(this.commitSearchAction, this.searchTerm)
        },

        /**
         * Click event listener for close button
         * closes search, deletes searchterm and reloads content 
         */
        closeSearch() {
            this.$store.dispatch(this.closeSearchAction)
            this.searchTerm = ""
            this.$store.commit(this.activeSearchState, false)
        }
    }

}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .list-search {
        position: relative;
        display: inline-block;
        .search-button {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            svg path {
                fill: #fff;
            }
        }
        &-input {
            background-color: $default-grey;
            color: #fff;
            height: 100%;
            padding: 8px 15px 10px 45px;
            border: none;
            border-radius: 30px;
            min-height: 35px;
            min-width: 110px;
            max-width: 100px;
            transition: max-width 0.5s;
            outline: none;
            font-family: "Gotham-Regular";
            @media screen and (min-width: $medium-screen) {
                transition: min-width 0.5s;
                min-height: 41px;
                padding-top: 10px;
            }
            &.active {
                background-color: $hover-blue;
                max-width: 150px;
                transition: max-width 0.5s;
                padding-right: 40px;
                @media screen and (min-width: $medium-screen) {
                    max-width: 0;
                    min-width: 160px;
                    transition: min-width 0.5s;
                }
                @media screen and (min-width: $large-screen) {
                    min-width: 250px;
                }
            }
            &::placeholder {
                color: #fff;
                font-size: 12px;
                line-height: 22px;
                font-family: "Gotham-Regular";
                @media screen and (min-width: $medium-screen) {
                    font-size: 14px;
                }
                @media screen and (min-width: $large-screen) {
                    font-size: 16px;
                }
            }
            &::-moz-placeholder {
                opacity: 1;
            }
            &:focus {
                background-color: $hover-blue;
                max-width: 150px;
                transition: max-width 0.5s;
                @media screen and (min-width: $medium-screen) {
                    max-width: 0;
                    min-width: 160px;
                    transition: min-width 0.5s;
                }
                @media screen and (min-width: $large-screen) {
                    min-width: 250px;
                }
            }
        }
        .close-button {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            cursor: pointer;
            svg path {
                fill: #fff;
            }
        }
    }
</style>