<template>
    <div class="receiptview__subheader">
        <h3>{{title}}</h3>
        <a class="btn-arrow-link" @click="navigateBack">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.25 4.82943L4.26083 4.82943L7.5425 1.53859L6.25 0.246094L0.75 5.74609L6.25 11.2461L7.5425 9.95359L4.26083 6.66276L17.25 6.66276L17.25 4.82943Z" fill="#009EE0"/>
            </svg>
            {{backLinkName}}
        </a>
        
        <div v-if="exportBtn" class="btn--mobile" @click="$emit('click-btn')">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.19617 13.1527H18.8962C19.1462 13.1527 19.3562 12.9426 19.3562 12.6926V11.9926C19.3562 11.7426 19.1462 11.5327 18.8962 11.5327H13.8362V5.9626L16.7762 8.90266C16.9662 9.09266 17.2762 9.09266 17.4662 8.90266L17.9962 8.37263C18.1862 8.18263 18.1862 7.87257 17.9962 7.68257L13.8462 3.53267L13.3162 3.00264L13.3062 2.99263C13.1162 2.80263 12.8062 2.80263 12.6162 2.99263L12.0862 3.52266C12.0762 3.53266 12.0662 3.55257 12.0562 3.56257L7.94617 7.67256C7.75617 7.86256 7.75617 8.17262 7.94617 8.36262L8.47617 8.89265C8.66617 9.08265 8.97617 9.08265 9.16617 8.89265L12.1062 5.95259V11.5227H7.18619C6.93619 11.5227 6.72617 11.7326 6.72617 11.9826V12.6826C6.73617 12.9526 6.94617 13.1527 7.19617 13.1527ZM7.19617 16.5227H18.8962C19.1462 16.5227 19.3562 16.3126 19.3562 16.0626V15.3626C19.3562 15.1126 19.1462 14.9027 18.8962 14.9027H7.19617C6.94617 14.9027 6.73618 15.1126 6.73618 15.3626V16.0626C6.73618 16.3126 6.94617 16.5227 7.19617 16.5227ZM22.6362 2.86262H21.8962C21.6262 2.86262 21.4062 3.01262 21.4062 3.36262V21.9326H4.55618V3.37263C4.55618 3.02263 4.33616 2.86262 4.06616 2.86262H3.32617C3.05617 2.86262 2.83618 3.02263 2.83618 3.37263V23.1627C2.83618 23.4327 3.04616 23.6427 3.31616 23.6527H3.32617H22.6462H22.6562C22.9262 23.6427 23.1362 23.4327 23.1362 23.1627V3.35261C23.1262 3.01261 22.9062 2.86262 22.6362 2.86262ZM7.19617 19.8826H18.8962C19.1462 19.8826 19.3562 19.6726 19.3562 19.4226V18.7226C19.3562 18.4726 19.1462 18.2626 18.8962 18.2626H7.19617C6.94617 18.2626 6.73618 18.4726 6.73618 18.7226V19.4226C6.73618 19.6726 6.94617 19.8826 7.19617 19.8826Z" fill="#FFF"/>
            </svg>
        </div>
        <div v-if="btnName" class="btn btn--desktop" @click="$emit('click-btn')">
            {{btnName}}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReceiptviewSubheader',
        props: {
            title: {
                required: true,
                type: String
            },
            backLinkName: {
                required: true,
                type: String
            },
            btnName: {
                type: String
            },
            exportBtn: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            /**
             * Navigate 'up' to the parent route
             */
            navigateBack() {
                console.log(this.$route)
                const lastMatched = this.$route.matched[this.$route.matched.length - 1]
                console.log(lastMatched.parent)
                this.$router.push({path: lastMatched.parent.path})
            }
        }     
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receiptview__subheader {
        position: relative;
        margin: 7.5px;
        @media screen and (min-width: $medium-screen) {
            // padding-bottom: 100px;
        }
        @media screen and (min-width: $large-screen) {
            // padding-bottom: 15px;
            border: none;
        }
        h3 {
            @media screen and (min-width: $medium-screen) {
                font-size: 28px;
                line-height: 32px;
            }
            @media screen and (min-width: $large-screen) {
                font-size: 34px;
                line-height: 40px;
            }
        }
        .btn-arrow-link {
            @media screen and (min-width: $medium-screen) {
                padding-top: 20px;
            }
            @media screen and (min-width: $large-screen) {
                padding-top: 5px;
            }
        }
        p {
            padding-top: 5px;
            font-size: 12px;
            line-height: 15px;
            display: inline-block;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
                line-height: 22px;
                padding-top: 15px;
            }
            @media screen and (min-width: $large-screen) {
                padding-top: 5px;
            }
        }
        .btn--mobile {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $brand-blue;
            display: flex; 
            align-items: center;
            justify-content: center;
            @media screen and (min-width: $medium-screen) {
                display: none;
            }
            svg {
                width: 18px;
                height: 18px;
            }
        }
        .btn--desktop {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $brand-blue;
            color: #fff;
            font-family: "Gotham-Medium";
            @media screen and (min-width: $medium-screen) {
                display: block;
            }
        }
    }
</style>