<template>
<div class="additionalProperties">
    <div
        v-for="type in additionalMetadataTypes"
        :class="{second: type.id === bewirtetPersonenPropertyTypeID}"
        :key="type.id"
    >
        <div v-if="!type.min">
            <h4>{{type.name}}</h4>
            <div class="metadatafield">
                <MetadataField
                    :value="singleFieldValue(type.id)"
                    :type="type.type"
                    :label="type.itemLabel || type.name"
                    :required="type.required"
                    @change="handleMetadataUpdateByType(type.id, $event)"
                />
            </div>
        </div>
        <div v-if="type.min">
            <div v-if="type.id !== bewirtetPersonenPropertyTypeID || showBewirtetePersonen">
                <h4>{{type.name}}</h4>
                <div
                    v-for="(field, index) in additionalPropertiesByType(type.id)"
                    :key="field.id"
                    class="addmetadata_coll-fields"
                >
                    <MetadataField 
                        :value="field.value"
                        :type="field.type.type"
                        :label="type.itemLabel || type.name"
                        :required="type.required || type.id === bewirtetPersonenPropertyTypeID"
                        :removeAble="additionalPropertiesByType(type.id).length > 1 || index !== 0"
                        @change="handleMetadataUpdate(field.id, $event)"
                        @deleteMetadata="handleMetadataDelete(field.id)"
                    />
                </div>

                <div class="add-button">
                    <div
                        class="btn--rounded person"
                        v-if="!type.max || (type.max && max > additionalPropertiesByType(type.id).length)"
                        @click="handleMetadataCreate(type.id, '')"
                    >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.4326 12.102H13.1277V6.79715C13.1277 6.53092 12.9108 6.314 12.6446 6.314H11.9149C11.6487 6.314 11.4318 6.53092 11.4318 6.79715V12.102H6.11707C5.85084 12.102 5.63392 12.3189 5.63392 12.5851V13.3148C5.63392 13.581 5.85084 13.7979 6.11707 13.7979H11.4219V19.1028C11.4219 19.369 11.6388 19.5859 11.9051 19.5859H12.6347C12.9009 19.5859 13.1179 19.369 13.1179 19.1028V13.7979H18.4227C18.6889 13.7979 18.9058 13.581 18.9058 13.3148V12.5851C18.9157 12.3189 18.6988 12.102 18.4326 12.102ZM12.2797 0.949951C5.65363 0.949951 0.279785 6.32385 0.279785 12.95C0.279785 19.5761 5.65363 24.95 12.2797 24.95C18.9058 24.95 24.2797 19.5761 24.2797 12.95C24.2797 6.32385 18.9058 0.949951 12.2797 0.949951ZM12.2797 23.2343C6.61008 23.2343 1.99547 18.6196 1.99547 12.95C1.99547 7.28029 6.61008 2.66563 12.2797 2.66563C17.9494 2.66563 22.564 7.28029 22.564 12.95C22.5541 18.6196 17.9494 23.2343 12.2797 23.2343Z" fill="#151917"/>
                        </svg>

                        Weitere Person hinzufügen
                    </div> 
                </div>

            </div>


        </div>

    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

import MetadataField from '../../partials/MetadataField'

export default {
    name: 'AdditionalMetadata',
    components: {
        MetadataField,
    },
    data() {
        return {
            bewirtet: undefined,
            bewirtetPersonenPropertyTypeID: process.env.VUE_APP_BEWIRTET_PERSONEN_METADATA_TYPE_ID,
            bewirtetPropertyTypeID: process.env.VUE_APP_BEWIRTET_METADATA_TYPE_ID
        }
    },
    props: {
        receipt: Object,
    },
    computed: {
        ...mapState({
            categories(state) {
                return state.categories
            },
            additionalMetadataTypes(state) {
                if (this.receipt.categoryID) return state.receipt.categories[this.receipt.categoryID].additionalMetadata
                return []
            }
        }),
        showBewirtetePersonen() {
            const addPropertyBewirtet = this.additionalPropertiesByType(this.bewirtetPropertyTypeID)
            if (addPropertyBewirtet.length) {
                return addPropertyBewirtet[0].value
            }
            return false
        }
    },
    mounted() {
        this.$emit('isValid', this.isValid)
    },
    methods: {
        metadataFieldValue(metadataType) {
            const property = this.receipt.additionalProperties.find(p => p.type.id === metadataType.id)
            if (property) {
                return property.value
            } else {
                return undefined
            }
        },
        handleMetadataUpdate(metadataPropertyID, val) {
            this.$store.dispatch(`receipt/updateMetadataProperty`, {
                value: val,
                metadataPropertyID: metadataPropertyID
            })
        },
        handleMetadataUpdateByType(typeID, val) {
            const properties = this.additionalPropertiesByType(typeID)

            if (properties.length) {
                this.handleMetadataUpdate(properties[0].id, val)
            } else {
                this.handleMetadataCreate(typeID, val)
            }

            // create new field for "bewirtete person" if "bewirtet?" field is true
            if (typeID === this.bewirtetPropertyTypeID && val === true) {
                const bewirtetPersonenProperties = this.additionalPropertiesByType(this.bewirtetPersonenPropertyTypeID)
                if (!bewirtetPersonenProperties.length) this.handleMetadataCreate(this.bewirtetPersonenPropertyTypeID, '')
            }
        },
        handleMetadataCreate(metadataTypeID, val) {
            this.$store.dispatch(`receipt/createMetadataProperty`, {
                value: val,
                receiptID: this.receipt.id,
                metadataTypeID: metadataTypeID
            })
        },
        additionalPropertiesByType(typeID) {
            return this.receipt.additionalProperties.filter(p => p.type.id === typeID)
        },
        singleFieldValue(typeID) {
            return this.additionalPropertiesByType(typeID).length ? this.additionalPropertiesByType(typeID)[0].value : null
        },

        handleMetadataDelete(fieldID) {
            this.$store.dispatch(`receipt/deleteMetadataProperty`, fieldID)
        }
    },
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .additionalProperties{
        display: flex;
        flex-direction: column;

        .second {
            order: 2;
        }
    }
    .addmetadata_coll-fields {
        width: 90%;
        max-width: 400px;
    }
    .metadatafield {
        width: 400px;
        display: inline-block;
        input {
            border: 5px solid black;
        }
    }

    .add-button {
        display: flex;
    }
</style>