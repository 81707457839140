/**
 * This module that manages documents data.
 * */

import Vue from 'vue'

import { transformDocumentsList } from '../transformer'
import { listDocuments, createDocument, deleteDocument, updateDocumentSubscription, getS3URL } from '@/graphql/api'

import { downloadZip } from 'client-zip'

/**
 * Loads S3 images for an array of documents.
 * @param {any} documents
 */
const loadS3ImageURLsList = async (documents) => {
  return Promise.all(documents.map(async (document) => await loadS3ImageURLs(document)))
}

/**
 * Fetches the S3 URLs of all document files and the thumbnail
 * @param {any} receipt
 */
const loadS3ImageURLs = async (document) => {
  let urls = []
  for (const path of document.paths) {
    urls.push(await getS3URL(path, document.identityId))
  }
  document.urls = urls
  document.thumbnailUrl = document.thumbnail ? await getS3URL(document.thumbnail, document.identityId) : ''
  return document
}

export const documentModule = {
  namespaced: true,
  state: () => ({
    documents: {},
    listDocumentsBy: undefined,
    subscription: undefined
  }),
  mutations: {
    setListDocumentsBy(state, username) {
      state.listDocumentsBy = username
    },
    setDocuments(state, documents) {
      state.documents = transformDocumentsList(documents)
    },
    updateDocument(state, document) {
      Vue.set(state.documents, document.id, document)
    },
    setSubscription(state, subscription) {
      state.subscription = subscription
    }
    
  },

  actions: {
    /**
     * Fetches documents of the user set in listReceiptsBy.
     */
    async load({commit, getters}) {
      let documents = await listDocuments(getters.listDocumentsBy)
      documents = await loadS3ImageURLsList(documents)
      commit('setDocuments', documents)
    },

    /**
     * Creates a document for the currently selected user.
     * @param input {
     *   files {array}: Files to add to the document
     *   title {string}: Document title
     * }
     */
    async createDocument({rootState, rootGetters, commit}, {files, title}) {
      let newDocument = await createDocument(
        Object.values(files),
        rootState.auth.username,
        title,
        rootGetters['staff/selectedUser'].Username,
        rootGetters['staff/selectedUser'].Attributes['custom:identityId']
      )

      const document = await loadS3ImageURLs(newDocument)
      commit('updateDocument', document)
    },
    
    /** 
     * Delete a document
     * @param {string} documentID ID of the document to delete
     */
    async deleteDocument({dispatch}, documentID) {
      await deleteDocument(documentID)
      dispatch('load')
    },
    
    /**
     * Initiate a download of a document.
     * @param {string} documentID ID of the document files to downlad
     */
    async downloadDocument({state}, documentID) {
      let downloads = []
      if (!state.documents[documentID]) return console.error('could not find document with ID ', documentID)
      
      const documentObject = state.documents[documentID]
      for (const [index, url] of documentObject.urls.entries()) {
        try {
          const download = await fetch(url)
          const blob = await download.blob()
          const renamed = new File([blob], `${documentObject.originalFilenames[index]}`, {type: download.type})
          downloads.push(renamed)
        } catch(err) {
          console.error('could not download file', url)
        }
      }

      const blob = await downloadZip(downloads).blob()

      // make and click a temporary link to download the Blob
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)

      const filename = documentObject.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()
      link.download = `${filename}.zip`
      link.click()
      link.remove()
    },

    
    /**
     * Initiate download of documents by a list of IDs.
     * @param {string} documentIDs IDs of the documents to be downloaded
     */
    async downloadDocuments({state}, documentIDs) {
      if (!documentIDs.length) return

      let downloads = []

      for (const document of documentIDs.map(id => state.documents[id])) {
        for (const [index, url] of document.urls.entries()) {
          try {
            const download = await fetch(url)
            const blob = await download.blob()
            const folderName = document.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()
            const renamed = new File([blob], `${folderName}/${document.originalFilenames[index]}`, {type: download.type})
            downloads.push(renamed)
          } catch(err) {
            console.error('could not download file', url)
          }
        }
      }

      const blob = await downloadZip(downloads).blob()

      // make and click a temporary link to download the Blob
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)

      link.download = `summarum_documents.zip`
      link.click()
      link.remove()
    },

    
    /**
     * Initiate download of all documents.
     */
    async downloadAllDocuments({state, dispatch}) {
      dispatch('downloadDocuments', Object.keys(state.documents))
    },


    async setupSubscriptions({state, rootState, commit}) {
      if (state.subscription) state.subscription.unsubscribe()

      const subscription = await updateDocumentSubscription(state.listDocumentsBy, rootState.auth.username, async (document) => {
        document = await loadS3ImageURLs(document)
        commit('updateDocument', document)
      })

      commit('setSubscription', subscription)

    }

  },

  getters: {

    /**
     *  Returns an array of receipt objects.
     */
    documents: (state) => {
      return Object.values(state.documents)
    },

    listDocumentsBy: (state, getters, rootState) => {
      return state.listDocumentsBy || rootState.auth.username
    },

  }
}
