<template>
    <div class="list-item" :class="{disabled: $store.state.checkboxActive}" @click="onListItemClick($event)">
        <div>
            <div ref="receiptname" class="receipt__name">
                <ReceiptName
                    :checkbox-mode="$store.state.checkboxActive"
                    :checkbox-selected="$store.state.receipt.selectedReceiptIDs.includes(receipt.id)"
                    :receipt-title="receiptTitle"
                    :receipt-type="receiptType"
                    :receipt-month="receiptMonth"
                />
            </div>
            <div class="receipt__status">
                <ReceiptStatus :status="receiptStatus" @edit-receipt="$router.push({ path: `receipt/${receipt.id }`, append: true })"/>
            </div>
            <div class="receipt__type">
                <ReceiptType ref="type" :receipt-month="receiptMonth" :receipt-type="receiptType" />
            </div>
            <div class="receipt__actions">
                <ReceiptActions
                    ref="actions"
                    :isArchived="receipt.archived"
                    @edit-receipt="$router.push({ path: `receipt/${receipt.id }`, append: true })"
                    @delete-receipt="openDeleteModal"
                    @download="$store.dispatch(`receipt/downloadReceitpsByID`, [receipt.id])"
                />
            </div>
            <DeleteModal 
                v-if="deleteModalIsActive"
                @close-modal="closeDeleteModal"
                @delete-receipt="$store.dispatch(`receipt/deleteReceipt`, receipt.id)"
            > 
            </DeleteModal>
        </div>
    </div>
</template>

<script>

import DeleteModal from '../partials/modals/DeleteModal'
import ReceiptActions from './ListProperties/Receipt_Actions'
import ReceiptStatus from './ListProperties/Receipt_Status'
import ReceiptName from './ListProperties/Receipt_Name'
import ReceiptType from './ListProperties/Receipt_Type'

export default {
    name: 'ReceiptListItem',
    components: {
        DeleteModal,
        ReceiptActions,
        ReceiptStatus,
        ReceiptName,
        ReceiptType,
    },
    props: {
        receipt: {
            type: Object
        },
        receiptTitle: {
            type: String,
        },
        receiptMonth: {
            type: String,
        },
        receiptType: {
            type: String,
        },
        receiptStatus: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            /**
             * Detects if the deleting modal is active
             * Gets active when 'Löschen' Button has been clicked
             */
            deleteModalIsActive: false
        }
    },
    methods: {
        /**
         * Click event listener for whole list item
         * Only runs function when event.target is not the actions menu 
         * Opens receipt detail or selects checkboxes depending on the current checkbox state
         */
        onListItemClick(event) {
            if (this.$refs.actions.$refs.toggleBtn === event.target) {
                return event
            } else if (this.$store.state.checkboxActive) {
                this.$store.commit(`receipt/toggleSelectedReceiptID`, this.receipt.id)
                return event
            }

            this.$router.push({ path: `receipt/${this.receipt.id }`, append: true })
            this.$store.state.receipt.activeModal = true
        },

        openDeleteModal() {
            this.$store.state.receipt.activeModal = true
            this.deleteModalIsActive = true
        },

        closeDeleteModal() {
            this.$store.state.receipt.activeModal = false
            this.deleteModalIsActive = false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .list-item {
        cursor: pointer;
        -ms-user-select: None;
        -moz-user-select: None;
        -webkit-user-select: None;
        user-select: None;
        &:hover {
            background-color: $light-grey;
        }
        > div {
            display: flex;
            align-items: center;
            font-size: 10px;
            line-height: 15px;
            padding: 10px 0;
            border-bottom: 1px solid $default-grey;
            @media screen and (min-width: $medium-screen) {
                font-size: 14px;
                line-height: 22px;
                padding: 5px 0;
            }
        }
    }
    .receipt__name {
        width: 80%;
        @media screen and (min-width: $medium-screen) {
            width: 30%;
        }
    }
    .receipt__status {
        width: 20%;
        text-align: center;
        @media screen and (min-width: $medium-screen) {
            width: 30%;
            text-align: left;
        }
    }
    .receipt__type {
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
            width: 35%;
        }
    }
    .receipt__actions {
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: flex;
            justify-content: center;
            width: 5%;
        }
    }

    p {
        padding-bottom: 30px;
        font-size: 12px;
    }
</style>