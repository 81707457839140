<template>
    <div>        
        <div class="receiptlist-header">
            <div class="receiptlist-header__info">
                {{ infoString }}
                <span v-if="resetActive" class="resetfilter" @click="$store.dispatch(resetAction)">Filter zurücksetzen</span>
            </div>
            <div class="listview-btn--desktop">
                <ListViewToggleButton />
            </div>
        </div>

        <div v-if="gridView" class="view-wrapper">
            <FileGridView 
                v-if="!loading"
                :files="files"
                @download="download($event)"
                @delete="deleteFile($event)"
                :deleteActive="deleteActive"
            >
            </FileGridView>
            <div v-if="loading" class="loading-spinner">
                <LoadingSpinner color="blue" />
            </div>
        </div>

        <div v-if="!gridView" class="view-wrapper">
            <FileListView 
                :files="files"
                @download="download($event)"
                @delete="deleteFile($event)"
                :deleteActive="deleteActive"
                v-if="!loading"
            >
            </FileListView>
        </div>
    </div>
</template>

<script>
import ListViewToggleButton from '../partials/ListViewToggleButton'
import FileGridView from './File_GridView'
import FileListView from './File_ListView'
import LoadingSpinner from '../partials/LoadingSpinner'
import { mapState } from 'vuex'

export default {
    name: 'FileList',
    components: {
        ListViewToggleButton,
        FileGridView,
        FileListView,
        LoadingSpinner
    },
    props: {
        files: {
            type: Array,
            required: true
        },
        downloadAction: {
            type: String,
            required: true
        },
        deleteAction: {
            type: String,
            required: true
        },
        deleteActive: {
            type: Boolean,
            default: false
        },
        infoString: {
            type: String
        },
        resetActive: {
            type: Boolean,
            default: false
        },
        resetAction: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            /**
             * Detects if gridView is active 
             */
            gridView: state => state.gridView
        }),
    },
    methods: {
        download(fileID) {
            this.$store.dispatch(this.downloadAction, fileID)
        },
        deleteFile(fileID) {
            this.$store.dispatch(this.deleteAction, fileID)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    p {
        padding: 20px 7.5px;
    }
    .listview-btn--desktop {
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
        }
    }
    .receiptlist-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $default-grey;
        margin: 5px 7.5px 30px 7.5px;
        @media screen and (min-width: $medium-screen) {
            border-top: none;
            border-bottom: 1px solid $default-grey;
            padding-top: 30px;
            margin-top: 0;
        }
        &__info {
            font-size: 10px;
            line-height: 15px;
            padding: 5px 0;
        }
    }
    .resetfilter {
        margin-left: 30px;
        color: $brand-blue;
        cursor: pointer;
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: inline-block;
        }
        &:hover {
            color: $hover-blue;
        }
    }
    .view-wrapper {
        position: relative;
    }
    .loading-spinner {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
    }

</style>