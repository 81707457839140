<template>
    <li @click="$emit('click')">
        {{title}}
    </li>
</template>

<script>
export default {
    name: 'DropdownItem',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
    @import 'src/assets/style/variables.scss';

    li {
        list-style: none;
        padding: 10px 15px;
        &:hover {
            color: $hover-blue;
        }
    }
</style>