/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const receiptSearch = /* GraphQL */ `
  query ReceiptSearch($keyword: String!, $ownerID: ID!) {
    receiptSearch(keyword: $keyword, ownerID: $ownerID) {
      items {
        receiptID
      }
      nextToken
    }
  }
`;
export const reportSearch = /* GraphQL */ `
  query ReportSearch($keyword: String!, $userID: ID!) {
    reportSearch(keyword: $keyword, userID: $userID) {
      items {
        id
        title
        originalFilenames
        paths
        thumbnail
        month
        year
        categoryID
        identityId
        user
        editBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listReceipts = /* GraphQL */ `
  query ListReceipts(
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReceipt = /* GraphQL */ `
  query GetReceipt($id: ID!) {
    getReceipt(id: $id) {
      id
      title
      owner
      createdAt
      categoryID
      project
      month
      year
      archived
      approved
      requestAssistance
      needInfoTicketID
      editBy
      updatedAt
      files {
        nextToken
      }
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      additionalProperty {
        nextToken
      }
    }
  }
`;
export const listReceiptsByOwner = /* GraphQL */ `
  query ListReceiptsByOwner(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptsByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchReceipts = /* GraphQL */ `
  query SearchReceipts(
    $filter: SearchableReceiptFilterInput
    $sort: SearchableReceiptSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReceipts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getReceiptFile = /* GraphQL */ `
  query GetReceiptFile($id: ID!) {
    getReceiptFile(id: $id) {
      id
      originalFilename
      ocrResult
      path
      imagePaths
      receiptID
      identityId
      owner
      editBy
      createdAt
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
    }
  }
`;
export const listReceiptFiles = /* GraphQL */ `
  query ListReceiptFiles(
    $filter: ModelReceiptFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        originalFilename
        ocrResult
        path
        imagePaths
        receiptID
        identityId
        owner
        editBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchReceiptFiles = /* GraphQL */ `
  query SearchReceiptFiles(
    $filter: SearchableReceiptFileFilterInput
    $sort: SearchableReceiptFileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReceiptFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        originalFilename
        ocrResult
        path
        imagePaths
        receiptID
        identityId
        owner
        editBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const listReceiptCategorys = /* GraphQL */ `
  query ListReceiptCategorys(
    $id: ID
    $filter: ModelReceiptCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReceiptCategorys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReceiptCategory = /* GraphQL */ `
  query GetReceiptCategory($id: ID!) {
    getReceiptCategory(id: $id) {
      id
      name
      type
      order
      createdAt
      updatedAt
      additionalPropertyTypes {
        nextToken
      }
    }
  }
`;
export const listByOrder = /* GraphQL */ `
  query ListByOrder(
    $type: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMetadataPropertyTypes = /* GraphQL */ `
  query ListMetadataPropertyTypes(
    $filter: ModelMetadataPropertyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetadataPropertyTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMetadataPropertyType = /* GraphQL */ `
  query GetMetadataPropertyType($id: ID!) {
    getMetadataPropertyType(id: $id) {
      id
      name
      itemLabel
      type
      required
      min
      max
      createdAt
      updatedAt
      categories {
        nextToken
      }
    }
  }
`;
export const getMetadataProperty = /* GraphQL */ `
  query GetMetadataProperty($id: ID!) {
    getMetadataProperty(id: $id) {
      id
      name
      value
      typeID
      createdAt
      editBy
      receiptID
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      type {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const listMetadataPropertys = /* GraphQL */ `
  query ListMetadataPropertys(
    $filter: ModelMetadataPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetadataPropertys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        value
        typeID
        createdAt
        editBy
        receiptID
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchMetadataPropertys = /* GraphQL */ `
  query SearchMetadataPropertys(
    $filter: SearchableMetadataPropertyFilterInput
    $sort: SearchableMetadataPropertySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMetadataPropertys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        value
        typeID
        createdAt
        editBy
        receiptID
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      title
      originalFilenames
      paths
      thumbnail
      month
      year
      categoryID
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
      category {
        id
        name
        order
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        originalFilenames
        paths
        thumbnail
        month
        year
        categoryID
        identityId
        user
        editBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchReports = /* GraphQL */ `
  query SearchReports(
    $filter: SearchableReportFilterInput
    $sort: SearchableReportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        originalFilenames
        paths
        thumbnail
        month
        year
        categoryID
        identityId
        user
        editBy
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const listReportCategorys = /* GraphQL */ `
  query ListReportCategorys(
    $id: ID
    $filter: ModelReportCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReportCategorys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportCategory = /* GraphQL */ `
  query GetReportCategory($id: ID!) {
    getReportCategory(id: $id) {
      id
      name
      order
      type
      createdAt
      updatedAt
    }
  }
`;
export const listReportCategoryByOrder = /* GraphQL */ `
  query ListReportCategoryByOrder(
    $type: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportCategoryByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      title
      originalFilenames
      paths
      thumbnail
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        originalFilenames
        paths
        thumbnail
        identityId
        user
        editBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
