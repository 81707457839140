<template>
    <div>
        <div class="accordion">
            <div class="accordion-header" @click="$emit('open')">
                <div class="accordion-headline">
                    <h4>{{headline}}</h4>
                </div>
                <div class="accordion-description">{{description}}</div>

                <div class="accordion-toggle-btn">
                    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.892422 15.0762L7.38075 8.73622L0.892422 2.39622C0.564936 2.07622 0.564936 1.55622 0.892422 1.23622L1.79302 0.356211C2.1205 0.0362109 2.65266 0.0362109 2.96992 0.356211L9.9904 7.21622C10.0109 7.23622 10.0416 7.24622 10.062 7.26622L10.9626 8.14622C11.2901 8.46623 11.2901 8.98622 10.9626 9.29622L10.9524 9.30623L10.0518 10.1862L2.96992 17.1062C2.64243 17.4262 2.11026 17.4262 1.78277 17.1062L0.882178 16.2262C0.564926 15.9162 0.564936 15.3962 0.892422 15.0762Z" fill="#151917"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SettingsItem',
        props: {
            items: [],
            headline: String,
            description: String
        }
    }
</script> 

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .accordion {
        border-bottom: 1px solid $default-grey;
        margin: 0 7.5px; // 15px in total, wrapper already has 7.5px padding
        &-header {
            position: relative;
            cursor: pointer;
            -ms-user-select: None;
            -moz-user-select: None;
            -webkit-user-select: None;
            user-select: None;
            padding: 15px 0 15px 5px;
            @media screen and (min-width: $medium-screen) {
                padding: 18px 0 18px 5px;
            }
            &:hover {
                background: $light-grey;
                svg path {
                    fill: $hover-blue;
                }
            }
        }
        &-headline h2 {
            line-height: 22px;
            font-weight: bold;
            padding-bottom: 5px;
            max-width: 90%;
        }
        &-description {
            font-size: 10px;
            line-height: 15px;
            max-width: 90%;
            padding-top: 5px;
            @media screen and (min-width: $medium-screen) {
                font-size: 12px;
            }
        }
        &-toggle-btn {
            position: absolute;
            right: -15px;
            top: 50%;
            transform: translateY(-50%);
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
