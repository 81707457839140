<template>
    <Modal flexPosition="end" size="small" @close-modal="$emit('close-modal')" :above-modal="aboveModal">
        <template v-slot:headertext>
            Belege zusammenführen
        </template>
        <template v-slot:body>
            <p>Wenn Sie diese Belege zusammenführen, gehen die erfassten Metadaten verloren.</p>
            <p>Wollen Sie diese Belege wirklich zusammenführen?</p>
        </template>
        <template v-slot:footer>
            <div class="footer-links text--small text--medium">
                <button class="btn" @click.stop="$emit('close-modal')">Abbrechen</button>
                <button class="btn btn--red" @click.stop="$emit('merge-receipt')">Ja, jetzt zusammenführen</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '../Modal'

export default {
    name: 'MergeModal',
    props: {
        aboveModal: {
            type: Boolean,
            default: false // true if the modal is above another modal
        }
    },
    components: {
        Modal
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    p {
        font-size: 12px;
        padding-bottom: 10px;
        @media screen and (min-width: $medium-screen) {
            font-size: 14px;
            padding-bottom: 15px;
        }
        @media screen and (min-width: $large-screen) {
            font-size: 16px;
        }
    }
    .footer-links {
        display: flex;
        justify-content: space-between;
    }
    button {
        background: transparent;
        outline: none;
        font-family: "Gotham-Medium";
        @media screen and (min-width: $medium-screen) {
            font-family: "Gotham-Regular";
        }
        &.btn {
            padding: 0;
            font-size: 12px;
            line-height: 22px;
            background-color: transparent;
            border-color: transparent;
            @media screen and (min-width: $medium-screen) {
                border-color: $brand-blue;
                padding: 8px 13px;
                font-size: 16px;
            }
            &:hover {
                color: $hover-blue;
                @media screen and (min-width: $medium-screen) {
                    background-color: $brand-blue;
                    color: #fff;
                }
            }
        }
        &.btn--red {
            background-color: transparent;
            border-color: transparent;
            color: $error;
            @media screen and (min-width: $medium-screen) {
                background-color: $error;
                color: #fff;
            }
            &:hover {
                @media screen and (min-width: $medium-screen) {
                    background-color: $error;
                }
            }
        }
    }
</style>