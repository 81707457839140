<template>
    <div class="receiptlist">        
        <div v-if="!loading && !emptyCollectionState" class="receiptlist-header">
            <div class="receiptlist-header__info">
                <span>Angezeigt werden: {{ viewString }} </span>
                <span class="resetfilter" v-if="filterActive && resetBtnIsActive" @click="$store.dispatch(`receipt/resetFilters`)">Filter zurücksetzen</span>
            </div>
            <div class="receiptlist-header__info">
                <div v-if="activeSelection && $store.state.receipt.selectedReceiptIDs.length" class="selectioncount">
                    {{ $store.state.receipt.selectedReceiptIDs.length }} ausgewählt
                </div>
                <div class="listview-btn--desktop">
                    <ListViewToggleButton />
                </div>
            </div> 

        </div>


        <div v-if="gridView && !loading">
            <ReceiptGridView 
                :receipts="receipts"
            >
            </ReceiptGridView>
        </div>

        <div v-if="!gridView && !loading">
            <ReceiptListView 
                :receipts="receipts"
            >
            </ReceiptListView>
        </div>
        
        
        <p v-if="!receipts.length && searchStatus && !emptyCollectionState">Keine Suchergebnisse</p>
        <p v-if="!receipts.length && !searchStatus && !emptyCollectionState">
            <span v-if="$route.name === 'collection' || $route.name === 'archive'">Keine Belege vorhanden.</span>
            <span v-if="$route.name !== 'collection' && $route.name !== 'archive'">Keine freigegebenen Belege zur Buchung vorhanden.</span>
        </p>
        <div v-if="emptyCollectionState" class="empty-collection">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.4092 19.209C12.6545 19.4542 13.0547 19.4542 13.3 19.209L13.3129 19.1961L19.355 13.1571C19.6003 12.9119 19.6003 12.5118 19.355 12.2666L18.6708 11.5828C18.4255 11.3376 18.0252 11.3376 17.7799 11.5828L13.9843 15.3765V7.10492C13.9843 6.7565 13.7002 6.47263 13.3517 6.47263H12.3963C12.0477 6.47263 11.7637 6.7565 11.7637 7.10492V15.3765L7.96803 11.5828C7.72273 11.3376 7.32251 11.3376 7.07721 11.5828L6.39296 12.2666C6.14766 12.5118 6.14766 12.9119 6.39296 13.1571L12.4092 19.209Z" fill="#151917"/>
            </svg>

            <h2>Hier gibt es gerade nichts für dich zu tun!</h2>
            <p>Aber du hast bereits neue Belege gesammelt? Dann immer her damit!</p>
            <div class="btn btn--blue" @click="$emit('upload-receipt')">Beleg hier hochladen</div>
        </div>

        <div v-if="loading" class="loading-spinner">
            <LoadingSpinner color="blue" />
        </div>
    </div>
</template>

<script>
import ListViewToggleButton from '../partials/ListViewToggleButton'
import ReceiptGridView from './Receipt_GridView'
import ReceiptListView from './Receipt_ListView'
import LoadingSpinner from '../partials/LoadingSpinner'
import { mapState } from 'vuex'

export default {
    name: 'ReceiptList',
    components: {
        ListViewToggleButton,
        ReceiptGridView,
        ReceiptListView,
        LoadingSpinner,
    },
    props: {
        receiptsStoreAction: {
            type: String, 
        },
        receiptsCategoryID: {
            type: String, 
        },
        hasFilters: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        /**
         * Function that gets month as a number and returns its name as a string
         * @param {Int} n
         */
        getMonthString (n) {
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months[n]
        },

        /**
         * Click event listener for 'Filter zurücksetzen' Button 
         * Resets all filters and reloads content 
         */
        resetFilters() {
            this.$store.dispatch(`receipt/resetFilters`)
        }
    },
    computed: {
        ...mapState({
            /**
             * Function that gets all receipts 
             */
            receipts: state => state.receipt.receipts,

            /**
             * Function that returns the searchStatus
             */
            searchStatus: state => state.receipt.searchStatus,

            /**
             * Function that detects if gridView is active 
             * when false, listView is displayed 
             */
            gridView: state => state.gridView,
            
            /**
             * Detects if content is loading
             */
            loading: state => state.receipt.loading,

            /**
             * Detects if filters are active
             */
            filterActive(state, getters) {
                return getters[`receipt/filterActive`]
            },

            /**
             * Detects if Checkbox mode for list items is active
             */
            activeSelection: state => state.checkboxActive,
        }),

        emptyCollectionState() {
            return this.$route.name === 'collection' &&
                    !this.filterActive &&
                    !this.receipts.length &&
                    !this.searchStatus &&
                    !this.loading
        },

        resetBtnIsActive() {
            return this.$route.name === 'archive' || this.$route.name === 'collection'
        },

        /**
         * Function that adds active filters to the string 'Angezeigt werden: ...' 
         */
        viewString() {
            const currentFilterCategoryID = this.$store.state.receipt.filterCategoryID
            const currentFilterMonthNumber = this.$store.state.receipt.fromDateMonth
            const currentFilterYearNumber = this.$store.state.receipt.fromDateYear

            if (!currentFilterCategoryID && !currentFilterMonthNumber && !currentFilterYearNumber) {
                return "Alle Belege"
            } else {
                let str = ''
                if (currentFilterYearNumber) {
                    str += currentFilterYearNumber
                }
                if (currentFilterYearNumber && currentFilterMonthNumber || currentFilterYearNumber && currentFilterCategoryID) {
                    str += " | "
                }
                if (currentFilterMonthNumber) {
                    str += this.getMonthString(currentFilterMonthNumber - 1)
                }
                if (currentFilterMonthNumber && currentFilterCategoryID) {
                    str += " | "
                }
                if (currentFilterCategoryID) {
                    str += this.$store.state.receipt.categories[currentFilterCategoryID].name
                }
                return str
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    p {
        padding: 20px 7.5px;
    }
    .listview-btn--desktop {
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
        }
    }
    .receiptlist-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $default-grey;
        margin: 5px 7.5px 30px 7.5px;
        @media screen and (min-width: $medium-screen) {
            border-top: none;
            border-bottom: 1px solid $default-grey;
            padding-top: 30px;
            margin-top: 0;
        }
        &__info {
            font-size: 10px;
            line-height: 15px;
            padding: 5px 0;
            display: flex;
            align-items: center;
            .selectioncount {
                color: $brand-blue;
                font-size: 14px;
                line-height: 22px;
                display: none;
                margin-bottom: 3px;
                @media screen and (min-width: $medium-screen) {
                    display: block;
                }
            }
        }
    }
    .resetfilter {
        margin-left: 30px;
        color: $brand-blue;
        cursor: pointer;
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: inline-block;
        }
        &:hover {
            color: $hover-blue;
        }
    }
    .searchbar {
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
        }
    }
    .empty-collection {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        margin: auto;
        text-align: center;
        color: $body-text;
        @media screen and (min-width: $large-screen) {
            margin-left: 144px; // this is because the main area has a margin-left from 288px
            margin-top: 50px;
        }
        svg {
            width: 85px;
            height: 111px;
            padding-bottom: 20px;
            @media screen and (min-width: $medium-screen) {
                width: 85px;
                height: 111px;
            }
            path {
                fill: $body-text;
            }
        }
        p {
            font-size: 12px;
            line-height: 15px;
            padding: 10px 7.5px 20px 7.5px;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
                line-height: 22px;
            }
        }
        .btn {
            display: inline-block;
            margin: 0;
        }
    }

    .resetfilter {
        margin-left: 30px;
        color: $brand-blue;
        cursor: pointer;
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: inline-block;
        }
        &:hover {
            color: $hover-blue;
        }
    }

    .loading-spinner {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        @media screen and (min-width: $large-screen) {
            margin-left: 130px;
        }
    }

</style>