<template>
  <div class="btn" @click="$emit('click')">
    <span :class="{hidden: loading}">{{btnText}}</span>
    <div v-if="loading" class="loadingspinner">
      <LoadingSpinner />
    </div>
  </div>
</template>
<script>
import LoadingSpinner from '../partials/LoadingSpinner'

export default {
    name: 'Button',
    components: {
      LoadingSpinner
    },
    props: ['btnText', 'loading']
}
</script>

<style lang="scss">
.btn {
  position: relative;

  span.hidden {
    opacity: 0;
  }

  .loadingspinner {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}
</style>
