/**
 * This module manages receipt data. It handles fetching, subscriptions and search independently, so that multiple instances of this module can be used in the store at the same time.
 * */

import Vue from 'vue'
import { FILTER_CURRENT_MONTH, FILTER_PREVIOUS_MONTH } from '../actions/'
import { transformReceipt, transformReceiptList, transformCategoryList } from '../transformer'
import {
  listReceipts,
  getReceipt,
  createReceipt,
  createAndUploadReceiptFile,
  deleteReceipt,
  mergeReceipts,
  deleteReceiptFile,
  updateReceipt,
  listProjects,
  listCategories,
  updateReceiptSubscription,
  createReceiptFileSubscription,
  updateReceiptFileSubscription,
  searchReceipts,
  getReceipts,
  getFilteredReceipts,
  createMetadataProperty,
  updateMetadataProperty,
  deleteMetadataProperty,
  getS3URL
} from '@/graphql/api'

import { downloadZip } from 'client-zip'
import { saveAs } from 'file-saver'
import { toggleWidget, prefillWidgetTicketInfo } from '../../lib/freshdeskWidget'

/**
 * Loads S3 images for an array of receipts.
 * @param {any} receipts
 */
const loadS3ImageURLsList = async (receipts) => {
  return Promise.all(receipts.map(async (receipt) => await loadS3ImageURLs(receipt)))
}

/**
 * Fetches the S3 image URLs of all receipt file images.
 * @param {any} receipt
 */
const loadS3ImageURLs = async (receipt) => {
  for (const file of receipt.files.items) {
    if (file.imagePaths) {
      file.imagePaths = await Promise.all(file.imagePaths.map(async (path) => await getS3URL(path, file.identityId)))
    }
    file.path = await getS3URL(file.path, file.identityId)
  }
  return receipt
}

export const receiptModule = {
  namespaced: true,
  state: () => ({
    receipts: [], // array of all receipts that are currently displayed
    searchStatus: false, // Status of receipt search. True if receipts array contains results of a search.
    activeSearch: false, // Status of receipt search. True when search is active.
    loading: false,
    initialized: false, // true if receipt store module is initialized (categories and projects are loaded)
    projects: [],
    categories: [],

    selectedReceiptIDs: [], // IDs of the receipts that are selected by checkboxes in list view

    activeTourPanel: true, // variable to store Boolean that detects if tour panel is displayed in the 'Schreibtisch' view
    activeModal: false, // variable to store Boolean that detects if a modal is active/opened

    // receipt detail modal ("Beleg Zuordnen")
    detailModalOpen: false,
    detailReceipt: undefined,

    // 
    // filter states
    // 
    listReceiptsBy: undefined, // Username of the user whose receipts should be fetched or searched.
    isArchive: undefined, // if true, only fetches receipts that are isArchive

    filterCategoryID: undefined, // if set, receipts are filtered by category
    fromDateMonth: undefined, // date filter: 1-indexed number of the month (from)
    fromDateYear: undefined, // date filter: from year (format: YYYY)
    toDateMonth: undefined, // date filter: 1-indexed number of the month (to)
    toDateYear: undefined, // date filter: to year (format: YYYY)
  }),
  mutations: {
    setInitialized(state, init) {
      state.initialized = init
    },
    setListReceiptsBy(state, username) {
      state.listReceiptsBy = username
    },
    setReceipts(state, receipts) {
      state.receipts = transformReceiptList(receipts)
    },
    updateReceipt(state, receipt) {
      if (state.detailModalOpen && receipt.id === state.detailReceipt.id) {
        state.detailReceipt = transformReceipt(receipt)
      } else {
        const oldReceipt = state.receipts.find(r => r.id === receipt.id)
        if (oldReceipt) {
          Vue.set(state.receipts, state.receipts.findIndex(r => r.id === receipt.id), transformReceipt(receipt))
        } else {
          state.receipts.unshift(transformReceipt(receipt))
        }
      }
    },
    updateReceiptNoTransform(state, receipt) {
      if (state.detailModalOpen && receipt.id === state.detailReceipt.id) {
        state.detailReceipt = receipt
      } else {
        Vue.set(state.receipts, receipt.id, receipt)
      }
    },
    removeReceipt(state, receiptID) {
      Vue.delete(state.receipts, state.receipts.findIndex(receipt => receipt.id === receiptID))
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setSearchStatus(state, searchStatus) {
      state.searchStatus = searchStatus
    },
    setActiveSearchInput(state, activeSearch) {
      state.activeSearch = activeSearch
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    setCategories(state, categories) {
      state.categories = transformCategoryList(categories)
    },
    setSelectedReceiptIDs(state, receiptIDs) {
      state.selectedReceiptIDs = receiptIDs
    },
    toggleSelectedReceiptID(state, receiptID) {
      if (state.selectedReceiptIDs.includes(receiptID)) {
        state.selectedReceiptIDs.splice(state.selectedReceiptIDs.indexOf(receiptID), 1)
      } else {
        state.selectedReceiptIDs.push(receiptID)
      }
    },
    toggleAllReceiptsChecked(state) {
      if (state.selectedReceiptIDs.length === state.receipts.length) {
        state.selectedReceiptIDs = []
      } else {
        state.selectedReceiptIDs = state.receipts.map(receipt => receipt.id)
      } 
    },
    setIsArchive(state, isArchive) {
      state.isArchive = isArchive
    },
    setDetailModalOpen(state, open) {
      state.detailModalOpen = open
    },
    setDetailReceipt(state, receipt) {
      state.detailReceipt = transformReceipt(receipt)
    },
    removeDetailReceipt(state) {
      state.detailReceipt = undefined
    },
    setFilterCategoryID(state, categoryID) {
      state.filterCategoryID = categoryID
    },
    setFromDateMonth(state, month) {
      state.fromDateMonth = month;
    },
    setFromDateYear(state, year) {
      state.fromDateYear = year;
    },
    setToDateMonth(state, month) {
      state.toDateMonth = month;
    },
    setToDateYear(state, year) {
      state.toDateYear = year;
    },
  },

  actions: {
    /**
     * Performs fetching that only needs to be done once at first load: project and category data, sets up subscriptions
     */
    async init({commit, dispatch}) {
      await Promise.all([
        dispatch('loadCategories'),
        dispatch('loadProjects'),
        dispatch('subscribeReceipts')
      ])
      
      commit('setInitialized', true)
    },


    /**
     * Fetches receipts for the user set in state.listReceiptsBy. Dependent on the value of state.isArchive,
     * fetches only receipts that have a category (true) or receipts that don't have a category.
     * Saves receipts array in state.receipts.
     */
    async load({state, commit, getters}) {
      commit('setLoading', true)
      let receipts = await listReceipts(
        getters.listReceiptsBy,
        state.isArchive,
        state.fromDateYear,
        state.fromDateMonth,
        state.toDateYear,
        state.toDateMonth,
        state.filterCategoryID
      )
      receipts = await loadS3ImageURLsList(receipts)
      commit('setReceipts', receipts)
      commit('setLoading', false)
    },

    /**
      * Create a receipt. Creates one receipt for every file.
      * @param input {
      *   files {object}: files to upload
      *   month {number}: 1-indexed number of the month
      *   categoryID {string}: category ID of receipt
      * }
      *
    */
    async createReceipt({rootState, dispatch}, {files, month, categoryID}) {
      for (const fileKey in Object.keys(files)) {
        await createReceipt([files[fileKey]], rootState.auth.username, month, categoryID)
      }
      dispatch('load')
    },

    
    /**
      * Adds and uploads a file to an existing receipt. 
      * @param input {
      *   files {object}: file to upload
      *   receiptID {string}: ID of the receipt
      * }
     */
    async addReceiptFile({rootState}, {file, receiptID}) {
      await createAndUploadReceiptFile(file, receiptID, rootState.auth.username)
    },

    /**
     * Deletes a reciept and its receipt files.
     * @param {string} receiptID ID of the receipt to delete
     */
    async deleteReceipt({state, commit}, receiptID) {
      const receipt = state.receipts.find(receipt => receipt.id === receiptID)
      if (!receipt) return console.error('could not find receipt with ID ', receiptID)
      for (const file in receipt.files) {
        await deleteReceiptFile(receiptID, file.id)
      }
      await deleteReceipt(receiptID)
      commit('removeReceipt', receiptID)
    },

    /**
     * Deletes all selected receipts and their receipt files.
     */
    async deleteSelectedReceipts({state, dispatch}) {
      for (const receiptID of state.selectedReceiptIDs) {
        await dispatch(`deleteReceipt`, receiptID)
      }
    },

    /**
     * Deletes a receipt file.
     * @param {string} receiptID ID of the receipt
     * @param {string} receiptFileID ID of the receipt file 
     */
    deleteReceiptFile({state, commit}, {receiptID, receiptFileID}) {
      deleteReceiptFile(receiptFileID)
      let newReceipt = state.detailModalOpen ? state.detailReceipt : state.receipts.find(receipt => receipt.id === receiptID)
      newReceipt.files = newReceipt.files.filter(f => f.id !== receiptFileID)
      commit('updateReceiptNoTransform', newReceipt)
    },

    /**
     * Resets metadata (category, project and additional metadata) of a receipt.
     */
    async resetMetadata({state, commit, rootState}, receiptID) {
      const receipt = state.detailModalOpen && state.detailReceipt.id === receiptID ?
        state.detailReceipt: state.receipts.find(r => r.id === receiptID)
      if (receipt.additionalProperties.length) {
        for (const property of receipt.additionalProperties) {
          await deleteMetadataProperty(property.id)
        }
      }

      let updatedReceipt = await updateReceipt(receiptID, {
        categoryID: "NONE",
        project: null
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
     * Reset category and date filters.
     */
    resetFilters({commit, dispatch}) {
      commit('setFilterCategoryID', undefined)
      commit('setFromDateMonth', undefined)
      commit('setFromDateYear', undefined)
      commit('setToDateMonth', undefined)
      commit('setToDateYear', undefined)
      
      dispatch('load')
    },

    /**
      * Filter receipts by category ID.
      * @param {string} ID of the category
      */
    filterCategoryID({commit, dispatch}, categoryID) {
      commit('setFilterCategoryID', categoryID)
      dispatch('load')
    },

    /**
      * Filter recipts by a month range.
      * @param input {
      *   fromDateMonth {number}: 1-indexed number of the month (from)
      *   toDateMonth {number}: 1-indexed number of the month (to)
      * }
      *
    */
    filterMonthRange({commit, dispatch}, {fromDateMonth, toDateMonth}) {
      commit('setFromDateMonth', fromDateMonth)
      commit('setToDateMonth', toDateMonth)

      dispatch('load')
    },

    /**
      * Filter recipts by a year range.
      * @param input {
      *   fromDateYear {number}: from year (format: YYYY)
      *   toDateYear {number}: to year (format: YYYY)
      * }
      *
    */
    filterYearRange({commit, dispatch}, {fromDateYear, toDateYear}) {
      commit('setFromDateYear', fromDateYear)
      commit('setToDateYear', toDateYear)

      dispatch('load')
    },

    /**
     * For the date filter, set the range to the current month.
     */
    [FILTER_CURRENT_MONTH]({commit, dispatch}) {
      const now = new Date()
      commit('setFromDateMonth', now.getMonth() + 1)
      commit('setFromDateYear', now.getFullYear())
      commit('setToDateMonth', now.getMonth() + 1)
      commit('setToDateYear', now.getFullYear())

      dispatch('load')
    },

    /**
     * For the date filter, set the range to the previous month.
     */
    [FILTER_PREVIOUS_MONTH]({commit, dispatch}) {
      const now = new Date()
      const lastMonth = new Date(now.setMonth(now.getMonth() - 1))
      commit('setFromDateMonth', lastMonth.getMonth() + 1)
      commit('setFromDateYear', lastMonth.getFullYear())
      commit('setToDateMonth', lastMonth.getMonth() + 1)
      commit('setToDateYear', lastMonth.getFullYear())

      dispatch('load')
    },

    /**
    * Sets the date of a receipt.
    * @param input {
    *   year {number}: new year (format: YYYY)
    *   month {number}: 1-indexed number of month
    * }
    */
    async setDateForReceipt({commit, rootState}, {receiptID, year, month}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        year: year,
        month: month
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },
    
    /**
    * Set the approved status of a receipt.
    * @param input {
    *   receiptID {String}: ID of the receipt to be updated
    *   approved {boolean} approved status
    * }
    */
    async setReceiptApprovedStatus({commit, rootState}, {receiptID, approved}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        approved: approved
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
    * Set the requestAssistance status of a receipt.
    * @param input {
    *   receiptID {String}: ID of the receipt to be updated
    *   requestAssistance {boolean} assistance ('summarum macht's') status
    * }
    */
    async serReceiptAssistanceStatus({commit, rootState}, {receiptID, requestAssistance}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        requestAssistance: requestAssistance
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
    * Request assistance/ initiate "summarum macht's" workflow for a user's inbox/Schreibtisch.
    * Sets the "requestApproval" attribute to true for all receipts in inbox that are status missingData ('Aktion erforderlich').
    */
    async requestAssistance({state, dispatch}) {
      for (const receipt of state.receipts) {
        if (receipt.status === 'missingData') {
          await dispatch('serReceiptAssistanceStatus', {
            receiptID: receipt.id,
            requestAssistance: true
          })
        }
      }
    },

    /**
     * Set the needInfoTicketID field for a receipt.
     * @param input {
     *   receiptID {string}: ID of the receipt to be updated
     *   ticketID {string}: FreshDesk ticket ID
     * }
     */
    async setNeedInfoTicketID({commit, rootState}, {receiptID, ticketID}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        needInfoTicketID: ticketID
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
     * Merges multiple receipts that are selected with checkboxes.
     */
    async mergeReceipts({state, rootState, commit}) {
      const firstReceipt = state.receipts.find(receipt => receipt.id === state.selectedReceiptIDs[0])
      let receiptFileIDs = []
      for (const receiptID of state.selectedReceiptIDs) {
        receiptFileIDs = receiptFileIDs.concat(state.receipts.find(receipt => receipt.id === receiptID).files.map(f => f.id))
      }
      await mergeReceipts(rootState.auth.username, state.selectedReceiptIDs, receiptFileIDs, firstReceipt.title)
      console.log(state.selectedReceiptIDs.slice(1))
      for (const receiptID of state.selectedReceiptIDs.slice(1)) {
        commit('removeReceipt', receiptID)
      }
    },

    /**
     * Set a receipt object to be shown in the receipt modal. loads image URLs.
     * @param {string} receiptID ID of the receipt
     */
    async openReceiptDetailModal({commit, dispatch}, receiptID) {
      const receipt = await getReceipt(receiptID)

      if (receipt && receipt.files.items.length) {
        const receiptLoadedImages = await loadS3ImageURLs(receipt)
        commit('setDetailReceipt', receiptLoadedImages)
        commit(`setDetailModalOpen`, true)
        return true
      } else {
        dispatch('closeReceiptDetailModal')
        return false
      }
    },

    /**
     * Closes the receipt detail modal.
     */
    closeReceiptDetailModal({commit}) {
      commit(`setDetailModalOpen`, false)
      commit(`setDetailReceipt`, {})
    },

    /**
      * Fetches receipts for a provided user. Saves receipts for that user in state.receipts.
      * @param {string} username
      */
    async loadReceiptsForUser({commit, dispatch}, username) {
      commit('setListReceiptsBy', username)
      dispatch('load')
    },

    /*
     * Fetches all projects for the current user.
    */
    async loadProjects({commit, rootState}) {
      const projects = await listProjects(rootState.auth.username)
      const unique = [...new Set(projects)]
      commit('setProjects', unique)
    },

    /**
     * Fetches available receipt categories.
     */
    async loadCategories({commit}) {
      commit('setCategories', await listCategories())
    },

    /**
    * Set the project field of a receipt.
    * @param input {
    *   receiptID {string}: ID of the receipt to be updated
    *   projectName {string} project name
    * }
    */
    async setProjectForReceipt({commit, rootState, dispatch}, {receiptID, projectName}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        project: projectName
      }, rootState.auth.username)
      await dispatch(`loadProjects`)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
      * Sets the category of a receipt.
      * @param input {
      *   receiptID {String}: ID of the receipt to be changed
      *   categoryID {String}: ID of the category the receipt should be assigned to
      * }
      *
    */
    async setCategory({commit, rootState}, {receiptID, categoryID}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        categoryID: categoryID
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
      * Sets the category and month of selected receipts.
      * @param input {
      *   categoryID {String}: ID of the category the receipt should be assigned to
      *   month: {number}: 1-indexed month number
      * }
      *
    */
    async setMonthCategoryReceipts({commit, state, rootState}, {categoryID, month}) {
      for (const receiptID of state.selectedReceiptIDs) {
        let updatedReceipt = await updateReceipt(receiptID, {
          categoryID: categoryID,
          month: month
        }, rootState.auth.username)
        updatedReceipt = await loadS3ImageURLs(updatedReceipt)
        commit('updateReceipt', updatedReceipt)
      }
    },

    /**
      * Sets the title of a receipt.
      * @param input {
      *   receiptID {String}: ID of the receipt to be changed
      *   title {String}: New title
      * }
      *
    */
    async setTitle({commit, rootState}, {receiptID, title}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        title: title
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
      * Set the isArchive status of a receipt.
      * @param input {
      *   receiptID {String}: ID of the receipt to be changed
      *   categoryID {Boolean}: isArchive status
      * }
     */
    async setIsArchiveStatus({commit, rootState}, {receiptID, archived}) {
      let updatedReceipt = await updateReceipt(receiptID, {
        archived: archived
      }, rootState.auth.username)
      updatedReceipt = await loadS3ImageURLs(updatedReceipt)
      commit('updateReceipt', updatedReceipt)
    },

    /**
     * Search a receipt with a search term. The result array is set in state.receipts. Search status is set to true. If a user is defined in state.listReceiptsBy is set, only the receipts of this user are searched.
     * @param {string} term search term
     */
    async commitSearch({state, commit, getters}, term) {
      let resultIDs = await searchReceipts(
        term,
        getters.listReceiptsBy
      )

      if (resultIDs.length) {
        const results = await getFilteredReceipts(
          resultIDs,
          getters.listReceiptsBy,
          state.isArchive,
          state.fromDateYear,
          state.fromDateMonth,
          state.toDateYear,
          state.toDateMonth,
          state.filterCategoryID  
        )
        const receipts = await loadS3ImageURLsList(results)
        commit('setReceipts', receipts)
      } else {
        commit('setReceipts', [])
      }
      commit('setSearchStatus', true)
    },

    /**
    * Closes the current search. Sets the state.searchStatus to false and fetches all receipts.
    */
    closeSearch({dispatch, commit}) {
      dispatch('load')
      commit('setSearchStatus', false)
    },

    /**
     * Create a metadtata property for a receipt. Properties are of a set type (metadataProperyType) and can assume
     * any value that is JSON-serializable.
     * @param input {
     *    receiptID {string}: ID of the receipt
     *    metadataTypeID {string}: ID of the metadata type
     *    value {any}: metadata property value (must be JSON-serializable)
     * }
     */
    async createMetadataProperty({commit, rootState}, {receiptID, metadataTypeID, value}) {
      const property = await createMetadataProperty(receiptID, metadataTypeID, value, rootState.auth.username)
      const receipt = await loadS3ImageURLs(property.receipt)
      commit('updateReceipt', receipt)
    },

    /**
     * Updates an existing metadata property. The value must be JSON-serializable.
     * @param input {
     *    metadataPropertyID {string}: ID of the metadata property
     *    value {any}: new metadata property value (must be JSON-serializable)
     * }
     */
    async updateMetadataProperty({commit, rootState}, {metadataPropertyID, value}) {
      const property = await updateMetadataProperty(metadataPropertyID, value, rootState.auth.username)
      const receipt = await loadS3ImageURLs(property.receipt)
      commit('updateReceipt', receipt)
    },

    /**
     * Deletes a metadata property field.
     * @param metadataPropertyID ID of the metadata property to delete
     */
    async deleteMetadataProperty({commit, state}, metadataPropertyID) {
      await deleteMetadataProperty(metadataPropertyID)

      let updatedReceipt = state.detailReceipt
      updatedReceipt.additionalProperties = updatedReceipt.additionalProperties.filter(a => a.id !== metadataPropertyID)
      commit('updateReceiptNoTransform', updatedReceipt)

      // fetch receipt again to update validation status
      let receipt = await getReceipt(updatedReceipt.id)
      receipt = await loadS3ImageURLs(receipt)
      commit('updateReceipt', receipt)

    },

    async subscribeReceipts({state, commit, getters}) {
      await createReceiptFileSubscription(getters.listReceiptsBy, async (receipt) => {
        receipt = await loadS3ImageURLs(receipt)
        commit('updateReceipt', receipt)
      })
      await updateReceiptFileSubscription(getters.listReceiptsBy, async (receipt) => {
        receipt = await loadS3ImageURLs(receipt)
        commit('updateReceipt', receipt)
      })


      // await createReceiptSubscription(getters.listReceiptsBy, state.isArchive, async (receipt) => {
      //   receipt = await loadS3ImageURLs(receipt)
      //   commit('updateReceipt', receipt)
      // })
      //
      // update subscripion
      await updateReceiptSubscription(getters.listReceiptsBy, state.isArchive, async (receipt) => {
        receipt = await loadS3ImageURLs(receipt)
        commit('updateReceipt', receipt)
      })
      console.log('subscribed')
    },

    
    /**
     * Open the FreshDesk widget and prefill information about a specific receipt.
     * To reset prefilled receipt-specific info, pass empty strings to receiptID and url.
     * @param input {
     *    receipt {object}: receipt object
     *    url {any}: URL to the receipt
     * }
     */
    async openTicketHelp({dispatch, getters}, {receipt, userUrl}) {
      dispatch('prefillWidget', null, {root: true})
      prefillWidgetTicketInfo(receipt.id, receipt.title, `${userUrl}?username=${getters.listReceiptsBy}`)
      toggleWidget(true)
    },

    /**
     * Initiate a download of a single receipt file.
     * @param input {
     *    receiptID {string}: ID of the receipt
     *    receiptFileID {any}: ID of the receipt file
     * }
     */
    async downloadReceiptFile({state}, { receiptID, receiptFileID }) {
      const receiptFile = state.detailModalOpen ?
        state.detailReceipt.files.find(f => f.id === receiptFileID) :
        state.receipts.find(r => r.id === receiptID).files.find(f => f.id === receiptFileID)
      const download = await fetch(receiptFile.path)
      const blob = await download.blob()
      saveAs(blob, receiptFile.originalFilename)
    },

    
    /**
     * Initiate a download of all checkbox-selected receipts.
     */
    async downloadSelectedReceipts({state, dispatch}) {
      console.log(state.selectedReceiptIDs)
      dispatch('downloadReceitpsByID', state.selectedReceiptIDs)
    },

    /**
     * Initiate a download of a full archive over all categories. If monthOffset is specified, only downloads receipts from a specific month.
     * @param {int} monthOffset if set, only downloads receipts from a single month. (0 for current, -1 for last etc.)
     */
    async downloadArchiveReceipts({dispatch, getters}, monthOffset) {
      let date = new Date()
      if (monthOffset) date = new Date(date.setMonth(date.getMonth() + monthOffset))

      let receipts = await listReceipts(
        getters.listReceiptsBy,
        true,
        date.getFullYear(),
        date.getMonth() + 1,
        date.getFullYear(),
        date.getMonth() + 1
      )
      receipts = await loadS3ImageURLsList(receipts)
      receipts = transformReceiptList(receipts)
      console.log(receipts)

      dispatch('downloadReceipts', Object.values(receipts))
    },

    /**
     * Initiates a download of the a set of receipts. Fetches the receipts from the server if some or all
     * requested receipts are not already stored in state.receipts.
     * @param {array} receiptIDs IDs of the receipts to be downloaded
     */
    async downloadReceitpsByID({dispatch, state}, receiptIDs) {
      let receipts
      const fetchedReceipts = state.receipts.filter(r => receiptIDs.includes(r.id))
      if (fetchedReceipts.length != receiptIDs.length) {
        const results = await getReceipts(receiptIDs)
        let receipts = await loadS3ImageURLsList(receipts)
        receipts = transformReceiptList(results)
      } else {
        receipts = fetchedReceipts
      }

      dispatch('downloadReceipts', receipts)
    },

    /**
     * Initiates a download of the a set of receipts.
     * @param {object} reciepts array of receipt objects
     */
    async downloadReceipts({state}, receipts) {
      let downloads = []
      state === state

      for (const receipt of receipts) {
          const receiptsFiles = receipt.files

          for (const file of receiptsFiles) {
            try {
              const download = await fetch(file.path)
              const blob = await download.blob()
              // const title = receipt.title.replace(/\.[^/.]+$/, "").replace('/', '')
              const renamed = new File([blob], `${receipt.categoryName}/${file.originalFilename}`, {type: download.type})
              downloads.push(renamed)

            } catch(err) {
              console.error('could not download file', file)
            }
          } 
      }
      
      const blob = await downloadZip(downloads).blob()

      // make and click a temporary link to download the Blob
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = "summarum_export.zip"
      link.click()
      link.remove()

    }
  },

  getters: {

    listReceiptsBy(state, getters, rootState) {
      return state.listReceiptsBy || rootState.auth.username
    },

    filterActive(state) {
      return state.filterCategoryID || state.fromDateMonth || state.fromDateYear || state.toDateMonth || state.toDateYear
    },

    /**
     * True if any of the selected receipts via selectedReceiptIDs have user-defined metadata.
     */
    selectedReceiptsHaveMetadata(state) {
      return state.selectedReceiptIDs.some(id => {
        const receipt = state.receipts.find(r => r.id === id)
        return receipt.project || receipt.categoryID
      })
    },
  }
}
