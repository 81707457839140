<template>
<div>
        <div class="wrapper">
            <header>
             <img src="../../../public/img/SUMMARUM_Logo.svg" alt="">
            </header>
            <div class="wrapper-content">
                <div class="spinner" v-if="loading">
                    <LoadingSpinner color="blue" />
                </div>

                <div v-if="!loading">
                    <div class="login" v-if="view === 'login'">
                        <h3>
                            Log dich ein!
                        </h3>
                        <form action="">
                            <div class="formfields">
                                <input type="email" placeholder="E-Mail-Adresse" v-model="email" required autocomplete="username">
                                <input type="password" placeholder="Passwort" v-model="password" required autocomplete="current-password">
                            </div>
                            <div v-if="error" class="error">
                                <div class="hint">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2744 17.7702H11.2744V15.7702H13.2744V17.7702Z" fill="#58584D"/>
                                        <path d="M13.2744 13.7702H11.2744V7.7702H13.2744V13.7702Z" fill="#58584D"/>
                                    </svg>
                                </div>

                                <strong>{{errorString(error)}}</strong>
                            </div>
                            <a href="" @click.prevent="forgotPassword" class="forgotpassword-link">Passwort vergessen?</a>

                            <input class="submit-btn" type="submit" @click.prevent="signIn" value="Einloggen">
                        </form>
                        <!-- <div class="register">
                            Du hast noch keinen Zugang? <br>
                            <a href="">Jetzt registrieren.</a>
                        </div> -->
                    </div>

                    <div class="password-reset" v-if="view === 'completeProfile'">
                        <h3>
                            Account vervollständigen
                        </h3>
                        <p>Bitte geben Sie ihr neues Passwort ein.</p>
                        <form action="">
                            <div class="formfields">
                                <!-- <input type="text" placeholder="Vorname" v-model="givenName" required>
                                <input type="text" placeholder="Nachname" v-model="familyName" required> -->
                                <input type="password" placeholder="Passwort" v-model="newPassword" required autocomplete="new-password">
                                
                                <div v-if="$store.getters['auth/accountCompletionNameRequired']">
                                    <input type="text" placeholder="Vorname" v-model="newFirstName" required>
                                    <input type="text" placeholder="Nachname" v-model="newLastName" required>
                                </div>

                                <a href="" @click.prevent="reset">Zurück</a>
                                
                            </div>

                            <div v-if="error" class="error">
                                <div class="hint">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2744 17.7702H11.2744V15.7702H13.2744V17.7702Z" fill="#58584D"/>
                                        <path d="M13.2744 13.7702H11.2744V7.7702H13.2744V13.7702Z" fill="#58584D"/>
                                    </svg>
                                </div>
                                <strong>{{errorString(error)}}</strong>
                            </div>
                            <input class="submit-btn" @click.prevent="completeAccount" type="submit" value="Passwort ändern">
                        </form>

                        <p v-if="success === 'change'">Das Passwort wurde erfolgreich geändert.</p>
                    </div>

                    <div class="password-reset" v-if="view === 'resetPassword'">
                        <h3>
                            Passwort zurücksetzen
                        </h3>
                        <form action="">
                            <div class="formfields">
                                <input type="email" placeholder="E-Mail-Adresse" v-model="resetEmail" required autocomplete="username">
                                <a href="" @click.prevent="reset">Zurück</a>
                            </div>


                            <p v-if="success === 'reset'">Wir haben dir per E-Mail einen Link geschickt, unter dem du das Passwort zurücksetzen kannst.</p>
                            <div v-if="error" class="error">
                                <div class="hint">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2744 17.7702H11.2744V15.7702H13.2744V17.7702Z" fill="#58584D"/>
                                        <path d="M13.2744 13.7702H11.2744V7.7702H13.2744V13.7702Z" fill="#58584D"/>
                                    </svg>
                                </div>
                                <strong>{{errorString(error)}}</strong>
                            </div>
                            <input class="submit-btn" @click.prevent="requestResetCode" type="submit" value="Passwort zurücksetzen">
                        </form>

                    </div>
                    <div class="password-change" v-if="view === 'changePassword'">
                        <h3>
                            Passwort zurücksetzen
                        </h3>
                        <form action="">
                            <p>Wir haben dir per E-Mail einen Code geschickt, mit dem du das Passwort hier zurücksetzen kannst.</p>
                            <div class="formfields">
                                <input type="text" placeholder="Code" v-model="resetCode" required >
                                <input type="password" placeholder="Neues Passwort" v-model="newPassword" required autocomplete="new-password">
                                <!-- <input type="password" placeholder="Passwort wiederholen" required> -->
                            </div>
                            <a href="" @click.prevent="reset">Zurück</a>
                            <input class="submit-btn" @click.prevent="changePasswordWithResetCode" type="submit" value="Passwort ändern">

                            <p v-if="success === 'change'">Das Passwort wurde erfolgreich geändert.</p>
                            <div v-if="error" class="error">
                                <div class="hint">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2744 17.7702H11.2744V15.7702H13.2744V17.7702Z" fill="#58584D"/>
                                        <path d="M13.2744 13.7702H11.2744V7.7702H13.2744V13.7702Z" fill="#58584D"/>
                                    </svg>
                                </div>

                                <strong>{{errorString(error)}}</strong>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  import LoadingSpinner from '../partials/LoadingSpinner'

  export default {
    name: 'Login',
    components: {
        LoadingSpinner
    },
    data() {
        return {
            email: '',
            password: '',
            newPassword: '',
            resetEmail: '',
            resetCode: '',
            familyName: '',
            givenName: '',
            newFirstName: '',
            newLastName: ''
        }
    },
    computed: {
        ...mapState('auth', [
            'view',
            'error',
            'success',
            'loading'
        ]),
    },
    methods: {
        /**
         * Click event listener for 'Einloggen' button
         * Logs user in to the service 
         */
        async signIn() {
            await this.$store.dispatch('auth/signIn', {
                email: this.email,
                password: this.password
            })
        },

        reset() {
            this.$store.commit('auth/setView', 'login')
            this.$store.commit('auth/setError', null)
            this.email =  ''
            this.password =  ''
            this.newPassword =  ''
            this.resetEmail =  ''
            this.resetCode =  ''
            this.familyName =  ''
            this.givenName =  ''
        },

        /**
         * Click event listener for 'Passwort ändern'
         * Changes password to new one 
         */
        async completeAccount() {
            await this.$store.dispatch('auth/completeAccount', {
                newPassword: this.newPassword,
                newFirstName: this.newFirstName,
                newLastName: this.newLastName
            })
            if (!this.error) this.reset()
        },

        async forgotPassword() {
            this.$store.commit('auth/setView', 'resetPassword')
            this.$store.commit('auth/setError', null)
        },

        /**
         * Click event listener to reset reset code for password reset 
         */
        async requestResetCode() {
            try {
                await this.$store.dispatch('auth/requestResetCode', this.resetEmail)
            } catch (err) {
                console.error('could not reset password', err)
            }
        },

        async changePasswordWithResetCode() {
            try {
                await this.$store.dispatch('auth/resetPasswordWithCode', {
                    email: this.resetEmail,
                    code: this.resetCode,
                    newPassword: this.newPassword
                })
                this.reset()
            } catch (err) {
                console.error('could not reset password', err)
            }
        },

        errorString(errorType) {
            const errorStrings = {
                auth: "E-Mail Adresse oder Passwort falsch",
                change: "Das Passwort konte nicht geändert werden",
                completeProfile: "Das Profil konte nicht vervollständigt werden",
                requestCode: "Das Passwort konnte nicht zurückgesetzt werden",
                passwordValid: "Das Passwort muss mindestens 8 Zeichen lang sein",
                userNotFound: "Kein Nutzer unter dieser E-Mail Adresse gefunden",
                passwordEmpty: "Das Passwort darf nicht leer sein",
                credentialsEmpty: "Die Zugangsdaten dürfen nicht leer sein"
            }
            return errorStrings[errorType]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .wrapper {
        min-height: calc(100vh - 30px); // 30px because 15px top + bottom margin
        background-color: #fff;
        border-radius: $brand-border-r;
        margin: 15px auto;
        max-width: 537px;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $medium-screen) {
            min-height: 0;
            height: 594px;
            width: 538px;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        header {
            height: 80px;
            background-color: $light-grey;
            border-radius: $brand-border-r;
            display: flex;
            align-items: center;
            @media screen and (min-width: $medium-screen) {
                height: 100px;
            }
            .logo {
                margin-left: 15px;
                width: 160px;
                height: 30px;
                background-color: #fff;
                @media screen and (min-width: $medium-screen) {
                    margin-left: 60px;
                }
            }
        }
        &-content {
            flex-grow: 1;
            padding: 30px;
            display: flex;
            flex-direction: column;
            > div, > div > div {
                display: contents;
            }
            @media screen and (min-width: $medium-screen) {
                padding: 30px 60px 40px 60px;
            }
            h3 {
                @media screen and (min-width: $medium-screen) {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
            .spinner {
                display: block;
                width: 50px;
                height: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            form {
                margin-top: 30px;
                padding-bottom: 10px;
                display: contents;
                @media screen and (min-width: $medium-screen) {
                    margin-top: 40px;
                }
                .formfields {
                    padding-bottom: 10px;
                    padding-top: 20px;
                    input {
                        font-size: 16px;
                        width: 100%;
                        height: 40px;
                        border: none;
                        border-bottom: 1px solid $light-grey;
                        margin-bottom: 30px;
                        outline: none;
                        font-family: "Gotham-Regular";
                        box-shadow: none;
                        @media screen and (min-width: $medium-screen) {
                            font-size: 16px;
                        }
                        &:last-child {
                            margin: 0;
                        }
                        &::placeholder {
                            font-size: 16px;
                            color: $default-grey;
                            font-family: "Gotham-Regular";
                            @media screen and (min-width: $medium-screen) {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }
                    }
                }
                .error {
                    color: $error;
                    padding: 5px 0 15px 0;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: "Gotham-Medium";
                    .hint {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: $error;
                        margin-right: 10px;
                        svg path {
                            fill: #fff;
                        }
                    }
                }
                .submit-btn {
                    margin-top: auto !important;
                    display: block;
                    width: 100%;
                    background-color: $brand-blue;
                    border-radius: $button-border-r;
                    color: #fff;
                    padding: 10px 0;
                    border: none;
                    font-size: 16px;
                    line-height: 22px;
                    cursor: pointer;
                    outline: none;
                    font-family: "Gotham-Medium";
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }
            a {
                font-size: 12px;
                line-height: 22px;
                @media screen and (min-width: $medium-screen) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .register {
                font-size: 12px;
                line-height: 22px;
                padding-top: 30px;
                @media screen and (min-width: $medium-screen) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    .password-reset {
        p {
            padding-top: 30px;
        }
    }
</style>