/**
 * This modules handles Cognito authentication, login states and some cognito user data
 * */

import { Auth } from 'aws-amplify'
import { setFeatureFlag } from '@/graphql/api'
import router from '@/router'

export const authModule = {
    namespaced: true,
    state: {
        state: undefined, // State of user authentication. Possible states: ["signedIn", "signedOut"]
        user: undefined, // Cognito user object
        username: undefined, // Cognito username

        // login screen
        view: 'login', // 'login', 'completeProfile', 'resetPassword' or 'changePassword'
        loading: false, // auth loading state
        error: null, // 'auth', 'reset', 'completeProfile', 'passwordEmpty', 'credentialsEmpty', 'passwordValid', 'requestCode'
        success: null, // 'reset' (passowrd reset successful), 'change' (password change successful), 'completeProfile'
    },
    mutations: {
        setUser(state, authData) {
            state.user = authData
            state.username = authData.username
        },
        setState(state, authState) {
            state.state = authState
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        resetUserState(state) {
            state.user = undefined
        },
        setView(state, view) {
            state.view = view
        },
        setError(state, errorType) {
            state.error = errorType
        },
        setSuccess(state, success) {
            state.success = success
        }
    },
    actions: {
        /**
         * Sign the user in. If successfull, routes to home. Changes view accordingly if 
         * user needs to enter a new password.
         * @param {string} email
         * @param {string} password
         */
        async signIn({commit, state}, {email, password}) {
            commit('setError', null)
            commit('setLoading', true)

            if (!email && !password) {
                commit('setLoading', false)
                return commit('setError', 'credentialsEmpty')
            }

            if (!password) {
                commit('setLoading', false)
                return commit('setError', 'passwordEmpty')
            }

            try {
                commit('setUser', await Auth.signIn(email, password))
                
                if (state.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    commit('setView', 'completeProfile')
                    commit('setLoading', false)
                } else {
                    try { await router.push({ name: 'home' }) } catch (e) {console.log(e)} // surpress navigation guard error
                }

                commit('setError', null)
            } catch (error) {
                commit('setError', 'auth')
                console.error('error signing in', error)
                commit('setLoading', false)
            }
        },

        /**
         * Start password reset workflow.
         * @param {string} email
         */
        async requestResetCode({commit}, email) {
            commit('setError', null)
            commit('setLoading', true)
            try {
                await Auth.forgotPassword(email)
                commit('setView', 'changePassword')
            } catch (error) {
                if (error.code === 'UserNotFoundException') {
                    commit('setError', 'userNotFound')
                } else {
                    commit('setError', 'requestCode')
                }
                console.error('error with password reset', error)
            }
            commit('setLoading', false)
        },

        /**
         * Set wthe password with a password reset code.
         * @param {string} email
         * @param {string} code
         * @param {string} newPassword
         */
        async resetPasswordWithCode({commit}, {email, code, newPassword}) {
            commit('setError', null)
            commit('setLoading', true)

            if (!newPassword) {
                commit('setError', 'passwordEmpty')
                commit('setLoading', false)
                return
            }

            try {
                await Auth.forgotPasswordSubmit(email, code, newPassword)

                // login user with new credentials
                commit('setSuccess', 'change')
                commit('setUser', await Auth.signIn(email, newPassword))
                try { await router.push({ name: 'home' }) } catch (e) {console.log(e)} 
            } catch (error) {
                if (error.code === 'InvalidParameterException' || error.code === 'InvalidPasswordException') {
                    commit('setError', 'passwordValid')
                } else {
                    commit('setError', 'change')
                }
                console.error('error with password reset', error)
            }
            commit('setLoading', false)
        },

        /**
         * Confirm a new password of a user. This is needed when an account is created outside the
         * registration form, i.e. in the AWS cognito console.
         * @param {string} newPassword new password
         * @param {string} newFirstName new first name (can be omitted if name is not required for completion/ is already set)
         * @param {string} newLastName new last name (can be omitted if name is not required for completion/ is already set)
         */
        async completeAccount({state, commit, getters}, {newPassword, newFirstName, newLastName} ) {
            commit('setLoading', true)
            commit('setError', null)

            if (!newPassword) {
                commit('setError', 'passwordEmpty')
                commit('setLoading', false)
                return
            }

            try {
                if (getters.accountCompletionNameRequired) {
                    await Auth.completeNewPassword(state.user, newPassword, {family_name: newLastName, given_name: newFirstName})
                } else {
                    await Auth.completeNewPassword(state.user, newPassword)
                }
                commit('setSuccess', 'change')
                router.push({ name: 'home' })
            } catch(error) {
                if (error.code === 'InvalidPasswordException' || error.code === 'InvalidPasswordException') {
                    commit('setError', 'passwordValid')
                } else {
                    commit('setError', 'completeProfile')
                }
                console.error('password could not be updated', error)
                commit('setLoading', false)
            }
        },
    
        /**
         * Updates the IdentityId costom attribute for the current user. 
         * The IdentityId is needed to acces S3 objects for this user.
         */
        async updateIdentityId({state}) {
            await Auth.updateUserAttributes(state.user, {
                "custom:identityId": (await Auth.currentUserCredentials()).identityId,
            });
        },

        /**
         * Fetch auth data from Amplify Auth and update state accordingly.
         */
        async fetchUserData({commit, dispatch}) {
            try {
                const user = await Auth.currentAuthenticatedUser({bypassCache: true })

                // user is logged in
                commit('setUser', user)
                commit('setState', 'signedIn')
                dispatch('updateIdentityId')

                // if(process.env.NODE_ENV === 'production') {
                //     //dispatch('setFreshChatUserData')
                // }

            } catch (err) {
                console.log(err)
                // user is logged out/ authentication error
                commit('setState', 'signedOut')
                commit('resetUserState')
            }
        },

        /**
         * Sign out current user. Store state changes are handled in setupAuth function via the onAuthUIStateChange event.
         * */
        async logout({dispatch}) {
            await Auth.signOut()
            await dispatch('fetchUserData')
            router.push({name: 'login'})
        },

        /**
         * Set a feature flag for the current user.
         */
        async setFeatureFlag({state}, {feature, value}) {
            await setFeatureFlag(state.user, feature, value)
        },

        /**
         * Set user attributes for the freshworks chat widget.
         */
        setFreshChatUserData({state}) {
            // To set unique user id in your system when it is available
            // window.fcWidget.setExternalId("john.doe1987");

            window.fcWidget.user.setProperties({
                firstName: state.user.attributes.given_name,
                lastName: state.user.attributes.family_name,
                email: state.user.attributes.email,
            });
        }


    },
    getters: {
        session(state) {
            return state.user.signInUserSession
        },

        /**
         * Returns true if the currently authenticated user belongs to the 'staff' cognito group.
         * @returns {bool} true if user is staff, false if not
         */
        isStaff(state, getters) {
            if(state.user) {
                const groups = getters.session.accessToken.payload['cognito:groups']
                return groups && groups.includes('staff')
            }
            return false
        },

        /**
         * Returns an object of feature flag attributes present for the current user.
         * @returns {object} feature flags, key: name, value: boolean feature flag enabled
         */
        featureFlags(state) {
            let featureFlags = {}
            if (!state.user) return {}
            for (let attribute in state.user.attributes) {
                const val = state.user.attributes[attribute]
                if (attribute.includes('custom:') &&
                    (val === '0' || val === '1')) {
                    featureFlags[attribute.substr(7)] = state.user.attributes[attribute] === '1'
                }
            }
            return featureFlags
        },

        accountCompletionNameRequired(state) {
            try {
                return state.user.challengeParam.requiredAttributes.includes('family_name')
            } catch (err) {
                return false
            }
        }
    }
}
