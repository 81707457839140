<template>
    <div class="mainmenu-info" :class="{hidden: !isActive}">
      <div class="close-btn" @click="closeInfo">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8268 4.66868L12.1787 10.3167L6.53447 4.67246C6.25099 4.38899 5.79124 4.38259 5.5016 4.67223L4.72712 5.44671C4.44377 5.73006 4.43757 6.18981 4.72735 6.47958L10.3779 12.1301L4.73616 17.7719C4.44022 18.0552 4.44662 18.515 4.73009 18.7984L5.51121 19.5796C5.79469 19.863 6.25444 19.8694 6.54408 19.5798L12.1921 13.9318L17.8427 19.5823C18.1261 19.8658 18.5922 19.8659 18.8755 19.5825L19.6563 18.8018C19.9397 18.5184 19.9396 18.0524 19.6561 17.7689L14.0055 12.1183L19.6536 6.47031C19.9369 6.18696 19.9368 5.72091 19.6533 5.43744L18.8722 4.65631C18.5762 4.38543 18.1101 4.38533 17.8268 4.66868Z" fill="#fff"/>
        </svg>
      </div>
      <div class="mainmenu-info-text">
        <div class="icon">
          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3994 22.7989C9.48204 22.7989 7.59569 22.3152 5.91485 21.3928C4.23402 20.4703 2.81302 19.1387 1.78334 17.5214C0.753661 15.904 0.148572 14.053 0.0240634 12.1397C-0.100445 10.2264 0.25965 8.31262 1.07103 6.57543C1.8824 4.83824 3.11885 3.33376 4.66595 2.20122C6.21305 1.06867 8.02082 0.344654 9.92199 0.096153C11.8232 -0.152348 13.7563 0.0826988 15.5425 0.779544C17.3287 1.47639 18.9103 2.61252 20.141 4.0828C20.2432 4.20493 20.2927 4.36267 20.2787 4.52131C20.2646 4.67996 20.1881 4.82652 20.066 4.92876C19.9438 5.031 19.7861 5.08053 19.6274 5.06647C19.4688 5.0524 19.3222 4.97589 19.22 4.85376C17.7084 3.047 15.618 1.81918 13.3038 1.37882C10.9897 0.938459 8.59443 1.31272 6.5249 2.43804C4.45537 3.56335 2.83917 5.37034 1.9508 7.5521C1.06242 9.73386 0.956648 12.1559 1.65144 14.4068C2.34622 16.6577 3.79874 18.5987 5.76228 19.9001C7.72583 21.2016 10.0793 21.7832 12.4231 21.5463C14.7668 21.3094 16.9563 20.2684 18.6196 18.6003C20.283 16.9322 21.3176 14.7398 21.5479 12.3954C21.5634 12.2363 21.6415 12.0898 21.765 11.9883C21.8885 11.8867 22.0472 11.8384 22.2063 11.8539C22.3655 11.8694 22.5119 11.9475 22.6135 12.071C22.715 12.1945 22.7633 12.3533 22.7478 12.5124C22.4819 15.3342 21.17 17.9546 19.0699 19.8581C16.9699 21.7616 14.2337 22.8106 11.3994 22.7989V22.7989Z" fill="#141917"/>
            <path d="M4.71 8.85555C4.55087 8.86232 4.39558 8.80559 4.27828 8.69786C4.16099 8.59012 4.09129 8.4402 4.08453 8.28108C4.07776 8.12196 4.13449 7.96667 4.24222 7.84937C4.34996 7.73207 4.49988 7.66237 4.659 7.65561C5.87501 7.55188 7.0117 7.00912 7.85684 6.12869C7.96881 6.02234 8.11748 5.96326 8.2719 5.96375C8.42633 5.96423 8.57462 6.02425 8.68592 6.1313C8.79722 6.23834 8.86296 6.38419 8.86946 6.53848C8.87595 6.69277 8.82271 6.84362 8.7208 6.95965C7.674 8.04935 6.26656 8.72109 4.76099 8.84955L4.71 8.85555Z" fill="#141917"/>
            <path d="M17.891 8.85569H17.84C16.3344 8.72723 14.927 8.05549 13.8802 6.96579C13.8214 6.90995 13.7745 6.84279 13.7424 6.76834C13.7103 6.6939 13.6936 6.61372 13.6934 6.53264C13.6931 6.45157 13.7093 6.37129 13.7409 6.29664C13.7726 6.222 13.819 6.15454 13.8774 6.09834C13.9359 6.04214 14.0051 5.99836 14.0809 5.96966C14.1567 5.94095 14.2376 5.92791 14.3186 5.93132C14.3996 5.93473 14.479 5.95452 14.5522 5.9895C14.6253 6.02447 14.6906 6.07391 14.7441 6.13483C15.5894 7.01504 16.726 7.55775 17.942 7.66175C18.1011 7.66851 18.251 7.73821 18.3587 7.85551C18.4665 7.97281 18.5232 8.1281 18.5164 8.28722C18.5097 8.44634 18.44 8.59626 18.3227 8.70399C18.2054 8.81173 18.0501 8.86846 17.891 8.86169V8.85569Z" fill="#141917"/>
            <path d="M15.2996 13.4995C14.8843 13.4995 14.4783 13.3763 14.133 13.1456C13.7876 12.9148 13.5185 12.5869 13.3596 12.2032C13.2006 11.8195 13.159 11.3973 13.2401 10.9899C13.3211 10.5826 13.5211 10.2084 13.8148 9.91473C14.1084 9.62105 14.4826 9.42106 14.8899 9.34003C15.2973 9.25901 15.7195 9.30059 16.1032 9.45953C16.4869 9.61847 16.8149 9.88761 17.0456 10.2329C17.2763 10.5783 17.3995 10.9843 17.3995 11.3996C17.3995 11.9565 17.1783 12.4906 16.7845 12.8844C16.3906 13.2782 15.8565 13.4995 15.2996 13.4995V13.4995ZM15.2996 10.4996C15.1216 10.4996 14.9476 10.5524 14.7996 10.6513C14.6516 10.7502 14.5363 10.8907 14.4682 11.0552C14.4 11.2196 14.3822 11.4006 14.4169 11.5752C14.4517 11.7497 14.5374 11.9101 14.6632 12.0359C14.7891 12.1618 14.9495 12.2475 15.124 12.2822C15.2986 12.317 15.4796 12.2991 15.644 12.231C15.8084 12.1629 15.949 12.0476 16.0479 11.8996C16.1468 11.7516 16.1996 11.5776 16.1996 11.3996C16.1996 11.1609 16.1047 10.932 15.936 10.7632C15.7672 10.5944 15.5383 10.4996 15.2996 10.4996V10.4996Z" fill="#141917"/>
            <path d="M7.4998 13.4995C7.08448 13.4995 6.67849 13.3763 6.33316 13.1456C5.98783 12.9148 5.71868 12.5869 5.55975 12.2032C5.40081 11.8195 5.35923 11.3973 5.44025 10.9899C5.52128 10.5826 5.72127 10.2084 6.01495 9.91473C6.30862 9.62105 6.68279 9.42106 7.09013 9.34003C7.49747 9.25901 7.91969 9.30059 8.3034 9.45953C8.6871 9.61847 9.01506 9.88761 9.2458 10.2329C9.47654 10.5783 9.5997 10.9843 9.5997 11.3996C9.5997 11.9565 9.37846 12.4906 8.98465 12.8844C8.59084 13.2782 8.05673 13.4995 7.4998 13.4995V13.4995ZM7.4998 10.4996C7.32181 10.4996 7.14781 10.5524 6.99981 10.6513C6.85181 10.7502 6.73647 10.8907 6.66835 11.0552C6.60023 11.2196 6.58241 11.4006 6.61714 11.5752C6.65186 11.7497 6.73757 11.9101 6.86344 12.0359C6.9893 12.1618 7.14965 12.2475 7.32423 12.2822C7.4988 12.317 7.67975 12.2991 7.8442 12.231C8.00864 12.1629 8.1492 12.0476 8.24809 11.8996C8.34697 11.7516 8.39976 11.5776 8.39976 11.3996C8.39976 11.1609 8.30494 10.932 8.13616 10.7632C7.96739 10.5944 7.73848 10.4996 7.4998 10.4996Z" fill="#141917"/>
            <path d="M21.5987 11.0994C20.9623 11.0994 20.3518 10.8466 19.9018 10.3965C19.4517 9.94643 19.1989 9.33601 19.1989 8.69952C19.1908 8.47089 19.2265 8.24283 19.3039 8.02755C19.6518 7.15459 21.0258 4.88371 21.0858 4.78771C21.1392 4.69955 21.2145 4.62665 21.3044 4.57607C21.3943 4.52548 21.4956 4.4989 21.5987 4.4989C21.7019 4.4989 21.8032 4.52548 21.8931 4.57607C21.9829 4.62665 22.0582 4.69955 22.1117 4.78771C22.1687 4.88371 23.5456 7.15459 23.8936 8.02755C23.971 8.24283 24.0066 8.47089 23.9986 8.69952C23.9986 9.33601 23.7458 9.94643 23.2957 10.3965C22.8456 10.8466 22.2352 11.0994 21.5987 11.0994ZM21.5987 6.27564C21.1675 6.98642 20.774 7.71939 20.4198 8.47153C20.4028 8.54627 20.3958 8.62293 20.3988 8.69952C20.3988 9.01776 20.5252 9.32297 20.7503 9.548C20.9753 9.77304 21.2805 9.89946 21.5987 9.89946C21.917 9.89946 22.2222 9.77304 22.4472 9.548C22.6723 9.32297 22.7987 9.01776 22.7987 8.69952C22.8018 8.62293 22.7947 8.54626 22.7777 8.47153C22.4236 7.71933 22.0301 6.98634 21.5987 6.27564Z" fill="#141917"/>
            <path d="M16.4992 18.299H6.29968C6.14056 18.299 5.98795 18.2358 5.87543 18.1233C5.76292 18.0108 5.69971 17.8582 5.69971 17.6991V16.4991C5.69971 16.0218 5.88934 15.5639 6.22689 15.2264C6.56444 14.8889 7.02225 14.6992 7.49962 14.6992H15.2992C15.7766 14.6992 16.2344 14.8889 16.572 15.2264C16.9095 15.5639 17.0991 16.0218 17.0991 16.4991V17.6991C17.0991 17.8582 17.0359 18.0108 16.9234 18.1233C16.8109 18.2358 16.6583 18.299 16.4992 18.299ZM6.89965 17.0991H15.8992V16.4991C15.8992 16.34 15.836 16.1874 15.7235 16.0749C15.611 15.9624 15.4584 15.8992 15.2992 15.8992H7.49962C7.3405 15.8992 7.18789 15.9624 7.07537 16.0749C6.96286 16.1874 6.89965 16.34 6.89965 16.4991V17.0991Z" fill="#141917"/>
          </svg>
        </div>   

        <span class="mainmenu-info-text--faded">{{headline}}</span> <br>
        {{subline}}
        <div class="additional-info">
          {{additionalInfo}}
        </div>
      </div>
      <Button :btn-text="btnText" :loading="loading" @click="$emit('btnclick')"></Button>
    </div>
</template>

<script> 
import Button from './partials/Button.vue'

  export default {
      name: 'MenuInfo',
      components: {
        Button
      },
      data: () => {
          return {
              isActive: true
          }
      },
      props: {
          headline: String,
          subline: String,
          additionalInfo: String,
          btnText: String,
          loading: Boolean
      },
      methods: {
          /** 
           * Clickevent listener for close button
           * closes the little info window when the close button is clicked 
           * this functionality is only given for desktop (no closing option for small screens)
           */
          closeInfo() {
              this.isActive = false
          }
      }
  }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .mainmenu-info {
      display: none;
      margin: 15px 20px;
      color: #fff;
      font-size: 12px;
      line-height: 1.25;
      position: absolute;
      bottom: 0;
      width: 285px;
      @media screen and (min-width: $medium-screen) {
        display: block;
        margin: 30px;
        padding: 15px;
        background: $brand-deep-green;
        font-size: 16px;
        line-height: 22px;
        border-radius: 30px 0 30px 30px;
        border: 2px solid $brand-blue;
      }
      @media screen and (min-width: $large-screen) {
        margin: 20px;
        margin-bottom: 30px;
        width: 248px;
      }
      &.hidden {
          opacity: 0;
          transition: all 0.3s;
          display: none;
      }
      .close-btn {
        position: absolute; 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0; 
        cursor: pointer;
        display: none;
        &:hover {
          svg path {
            fill: $hover-blue;
          }
        }
        @media screen and (min-width: $medium-screen) {
          display: flex;
        }
      }
      .additional-info {
        display: none;
        font-size: 12px;
        line-height: 15px;
        padding-top: 10px;
        max-width: 178px;
        @media screen and (min-width: $medium-screen) {
          display: block;
        }
      }
      &-text {
        padding-bottom: 10px;
        &--faded {
          color: $brand-blue;
          font-family: "Gotham-Medium";
        }
        .icon {
          margin-bottom: 10px;
          svg {
            width: 40px;
            height: 40px;
            path {
              fill: #fff;
            }
          }
        }
      }
      .btn {
        width: 157px;
        background-color: $brand-blue;
        color: #fff;
        @media screen and (min-width: $medium-screen) {
          font-size: 14px;
        }
        &:hover {
          background-color: $hover-blue;
          border-color: $hover-blue;
        }
        @media screen and (min-width: $medium-screen) {
          width: 209px;
        }
        @media screen and (min-width: $large-screen) {
          width: 180px;
        }
      }
    }
</style>