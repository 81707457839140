/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMetadataPropertyType = /* GraphQL */ `
  mutation CreateMetadataPropertyType(
    $input: CreateMetadataPropertyTypeInput!
    $condition: ModelMetadataPropertyTypeConditionInput
  ) {
    createMetadataPropertyType(input: $input, condition: $condition) {
      id
      name
      itemLabel
      type
      required
      min
      max
      createdAt
      updatedAt
      categories {
        nextToken
      }
    }
  }
`;
export const updateMetadataPropertyType = /* GraphQL */ `
  mutation UpdateMetadataPropertyType(
    $input: UpdateMetadataPropertyTypeInput!
    $condition: ModelMetadataPropertyTypeConditionInput
  ) {
    updateMetadataPropertyType(input: $input, condition: $condition) {
      id
      name
      itemLabel
      type
      required
      min
      max
      createdAt
      updatedAt
      categories {
        nextToken
      }
    }
  }
`;
export const deleteMetadataPropertyType = /* GraphQL */ `
  mutation DeleteMetadataPropertyType(
    $input: DeleteMetadataPropertyTypeInput!
    $condition: ModelMetadataPropertyTypeConditionInput
  ) {
    deleteMetadataPropertyType(input: $input, condition: $condition) {
      id
      name
      itemLabel
      type
      required
      min
      max
      createdAt
      updatedAt
      categories {
        nextToken
      }
    }
  }
`;
export const createReceipt = /* GraphQL */ `
  mutation CreateReceipt(
    $input: CreateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    createReceipt(input: $input, condition: $condition) {
      id
      title
      owner
      createdAt
      categoryID
      project
      month
      year
      archived
      approved
      requestAssistance
      needInfoTicketID
      editBy
      updatedAt
      files {
        nextToken
      }
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      additionalProperty {
        nextToken
      }
    }
  }
`;
export const updateReceipt = /* GraphQL */ `
  mutation UpdateReceipt(
    $input: UpdateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    updateReceipt(input: $input, condition: $condition) {
      id
      title
      owner
      createdAt
      categoryID
      project
      month
      year
      archived
      approved
      requestAssistance
      needInfoTicketID
      editBy
      updatedAt
      files {
        nextToken
      }
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      additionalProperty {
        nextToken
      }
    }
  }
`;
export const deleteReceipt = /* GraphQL */ `
  mutation DeleteReceipt(
    $input: DeleteReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    deleteReceipt(input: $input, condition: $condition) {
      id
      title
      owner
      createdAt
      categoryID
      project
      month
      year
      archived
      approved
      requestAssistance
      needInfoTicketID
      editBy
      updatedAt
      files {
        nextToken
      }
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      additionalProperty {
        nextToken
      }
    }
  }
`;
export const createReceiptFile = /* GraphQL */ `
  mutation CreateReceiptFile(
    $input: CreateReceiptFileInput!
    $condition: ModelReceiptFileConditionInput
  ) {
    createReceiptFile(input: $input, condition: $condition) {
      id
      originalFilename
      ocrResult
      path
      imagePaths
      receiptID
      identityId
      owner
      editBy
      createdAt
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
    }
  }
`;
export const updateReceiptFile = /* GraphQL */ `
  mutation UpdateReceiptFile(
    $input: UpdateReceiptFileInput!
    $condition: ModelReceiptFileConditionInput
  ) {
    updateReceiptFile(input: $input, condition: $condition) {
      id
      originalFilename
      ocrResult
      path
      imagePaths
      receiptID
      identityId
      owner
      editBy
      createdAt
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
    }
  }
`;
export const deleteReceiptFile = /* GraphQL */ `
  mutation DeleteReceiptFile(
    $input: DeleteReceiptFileInput!
    $condition: ModelReceiptFileConditionInput
  ) {
    deleteReceiptFile(input: $input, condition: $condition) {
      id
      originalFilename
      ocrResult
      path
      imagePaths
      receiptID
      identityId
      owner
      editBy
      createdAt
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
    }
  }
`;
export const createReceiptCategory = /* GraphQL */ `
  mutation CreateReceiptCategory(
    $input: CreateReceiptCategoryInput!
    $condition: ModelReceiptCategoryConditionInput
  ) {
    createReceiptCategory(input: $input, condition: $condition) {
      id
      name
      type
      order
      createdAt
      updatedAt
      additionalPropertyTypes {
        nextToken
      }
    }
  }
`;
export const updateReceiptCategory = /* GraphQL */ `
  mutation UpdateReceiptCategory(
    $input: UpdateReceiptCategoryInput!
    $condition: ModelReceiptCategoryConditionInput
  ) {
    updateReceiptCategory(input: $input, condition: $condition) {
      id
      name
      type
      order
      createdAt
      updatedAt
      additionalPropertyTypes {
        nextToken
      }
    }
  }
`;
export const deleteReceiptCategory = /* GraphQL */ `
  mutation DeleteReceiptCategory(
    $input: DeleteReceiptCategoryInput!
    $condition: ModelReceiptCategoryConditionInput
  ) {
    deleteReceiptCategory(input: $input, condition: $condition) {
      id
      name
      type
      order
      createdAt
      updatedAt
      additionalPropertyTypes {
        nextToken
      }
    }
  }
`;
export const createReceiptCategoryMetadataPropertyType = /* GraphQL */ `
  mutation CreateReceiptCategoryMetadataPropertyType(
    $input: CreateReceiptCategoryMetadataPropertyTypeInput!
    $condition: ModelReceiptCategoryMetadataPropertyTypeConditionInput
  ) {
    createReceiptCategoryMetadataPropertyType(
      input: $input
      condition: $condition
    ) {
      id
      categoryID
      propertyTypeID
      createdAt
      updatedAt
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      propertyType {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReceiptCategoryMetadataPropertyType = /* GraphQL */ `
  mutation UpdateReceiptCategoryMetadataPropertyType(
    $input: UpdateReceiptCategoryMetadataPropertyTypeInput!
    $condition: ModelReceiptCategoryMetadataPropertyTypeConditionInput
  ) {
    updateReceiptCategoryMetadataPropertyType(
      input: $input
      condition: $condition
    ) {
      id
      categoryID
      propertyTypeID
      createdAt
      updatedAt
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      propertyType {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteReceiptCategoryMetadataPropertyType = /* GraphQL */ `
  mutation DeleteReceiptCategoryMetadataPropertyType(
    $input: DeleteReceiptCategoryMetadataPropertyTypeInput!
    $condition: ModelReceiptCategoryMetadataPropertyTypeConditionInput
  ) {
    deleteReceiptCategoryMetadataPropertyType(
      input: $input
      condition: $condition
    ) {
      id
      categoryID
      propertyTypeID
      createdAt
      updatedAt
      category {
        id
        name
        type
        order
        createdAt
        updatedAt
      }
      propertyType {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMetadataProperty = /* GraphQL */ `
  mutation CreateMetadataProperty(
    $input: CreateMetadataPropertyInput!
    $condition: ModelMetadataPropertyConditionInput
  ) {
    createMetadataProperty(input: $input, condition: $condition) {
      id
      name
      value
      typeID
      createdAt
      editBy
      receiptID
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      type {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const updateMetadataProperty = /* GraphQL */ `
  mutation UpdateMetadataProperty(
    $input: UpdateMetadataPropertyInput!
    $condition: ModelMetadataPropertyConditionInput
  ) {
    updateMetadataProperty(input: $input, condition: $condition) {
      id
      name
      value
      typeID
      createdAt
      editBy
      receiptID
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      type {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const deleteMetadataProperty = /* GraphQL */ `
  mutation DeleteMetadataProperty(
    $input: DeleteMetadataPropertyInput!
    $condition: ModelMetadataPropertyConditionInput
  ) {
    deleteMetadataProperty(input: $input, condition: $condition) {
      id
      name
      value
      typeID
      createdAt
      editBy
      receiptID
      updatedAt
      receipt {
        id
        title
        owner
        createdAt
        categoryID
        project
        month
        year
        archived
        approved
        requestAssistance
        needInfoTicketID
        editBy
        updatedAt
      }
      type {
        id
        name
        itemLabel
        type
        required
        min
        max
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      title
      originalFilenames
      paths
      thumbnail
      month
      year
      categoryID
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
      category {
        id
        name
        order
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      title
      originalFilenames
      paths
      thumbnail
      month
      year
      categoryID
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
      category {
        id
        name
        order
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      title
      originalFilenames
      paths
      thumbnail
      month
      year
      categoryID
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
      category {
        id
        name
        order
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReportCategory = /* GraphQL */ `
  mutation CreateReportCategory(
    $input: CreateReportCategoryInput!
    $condition: ModelReportCategoryConditionInput
  ) {
    createReportCategory(input: $input, condition: $condition) {
      id
      name
      order
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateReportCategory = /* GraphQL */ `
  mutation UpdateReportCategory(
    $input: UpdateReportCategoryInput!
    $condition: ModelReportCategoryConditionInput
  ) {
    updateReportCategory(input: $input, condition: $condition) {
      id
      name
      order
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportCategory = /* GraphQL */ `
  mutation DeleteReportCategory(
    $input: DeleteReportCategoryInput!
    $condition: ModelReportCategoryConditionInput
  ) {
    deleteReportCategory(input: $input, condition: $condition) {
      id
      name
      order
      type
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      title
      originalFilenames
      paths
      thumbnail
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      title
      originalFilenames
      paths
      thumbnail
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      title
      originalFilenames
      paths
      thumbnail
      identityId
      user
      editBy
      createdAt
      updatedAt
      owner
    }
  }
`;
