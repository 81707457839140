<template>
    <div class="toggle-button" @click="handleClick">
        <div v-if="gridView">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.1603 11.36H9.49028C9.24028 11.36 9.03027 11.57 9.03027 11.82V12.52C9.03027 12.77 9.24028 12.98 9.49028 12.98H18.1603C18.4103 12.98 18.6203 12.77 18.6203 12.52V11.82C18.6203 11.57 18.4203 11.36 18.1603 11.36ZM18.1603 14.73H9.49028C9.24028 14.73 9.03027 14.94 9.03027 15.19V15.89C9.03027 16.14 9.24028 16.35 9.49028 16.35H18.1603C18.4103 16.35 18.6203 16.14 18.6203 15.89V15.19C18.6203 14.93 18.4203 14.73 18.1603 14.73ZM18.1603 8H9.49028C9.24028 8 9.03027 8.21 9.03027 8.46V9.16C9.03027 9.41 9.24028 9.62 9.49028 9.62H18.1603C18.4103 9.62 18.6203 9.41 18.6203 9.16V8.46C18.6203 8.21 18.4203 8 18.1603 8Z" fill="#151917"/>
                <path d="M7.16 11.36H6.46001C6.21001 11.36 6 11.57 6 11.82V12.52C6 12.77 6.21001 12.98 6.46001 12.98H7.16C7.41 12.98 7.62 12.77 7.62 12.52V11.82C7.62 11.57 7.42 11.36 7.16 11.36ZM7.16 14.73H6.46001C6.21001 14.73 6 14.94 6 15.19V15.89C6 16.14 6.21001 16.35 6.46001 16.35H7.16C7.41 16.35 7.62 16.14 7.62 15.89V15.19C7.62 14.93 7.42 14.73 7.16 14.73ZM7.16 8H6.46001C6.21001 8 6 8.21 6 8.46V9.16C6 9.41 6.21001 9.62 6.46001 9.62H7.16C7.41 9.62 7.62 9.41 7.62 9.16V8.46C7.62 8.21 7.42 8 7.16 8Z" fill="#151917"/>
            </svg>
        </div>
        <div v-if="!gridView">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.35571 8.99561H8.35571V4.99561H4.35571V8.99561ZM10.3557 20.9956H14.3557V16.9956H10.3557V20.9956ZM8.35571 20.9956H4.35571V16.9956H8.35571V20.9956ZM4.35571 14.9956H8.35571V10.9956H4.35571V14.9956ZM14.3557 14.9956H10.3557V10.9956H14.3557V14.9956ZM16.3557 4.99561V8.99561H20.3557V4.99561H16.3557ZM14.3557 8.99561H10.3557V4.99561H14.3557V8.99561ZM16.3557 14.9956H20.3557V10.9956H16.3557V14.9956ZM20.3557 20.9956H16.3557V16.9956H20.3557V20.9956Z" fill="#58584D"/>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ListViewToggleButton',
    computed: {
        ...mapState({
            /**
             * Detects if gridView is active 
             * if not, listview is displayed
             */
            gridView: state => state.gridView
        })
    }, 
    methods: {
        /**
         * Click event listener for toggle button
         * Displays receipt items in grid view or list view depending on gridView state
         */
        handleClick() {
            if (this.gridView) {
                this.$store.commit('setCheckboxActive', false)
            }
            this.$store.commit('setGridView', !this.gridView)
        }
    }
}
</script>

<style lang="scss" scoped>
    .toggle-button {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -ms-user-select: None;
        -moz-user-select: None;
        -webkit-user-select: None;
        user-select: None;
    }
</style>