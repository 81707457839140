<template>
    <div class="receipt__status">
        <div ref="missingdata" v-if="status === 'missingData'" class="receipt__status--red">    
            Aktion erforderlich
        </div>
        <div v-if="status === 'requestAssistance'" class="receipt__status--blue">
            <svg class="checkmark icon--desktop" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
            </svg>
            summarum macht's
        </div>
        <div v-if="status === 'booked'"  class="receipt__status--blue">
            <svg class="checkmark icon--desktop" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
            </svg>
            gebucht
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReceiptStatus',
    props: {
        status: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receipt__status {
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        &--red {
            background-color: $error;
            color: #fff;
            padding: 5px 10px;
            border-radius: 0 15px;
            &:hover {
                cursor: pointer;
            }
            &.hovered {
                background-color: $error-hover;
            }
        }
        &--blue {
            color: $brand-blue;
            font-weight: bold;
            display: flex;
            align-items: center;
            .icon--desktop {
                display: none;
                @media screen and (min-width: $medium-screen) {
                    display: block;
                    margin-right: 5px;
                }
            }
            svg path {
                fill: $brand-blue;
            }
        }
    }
</style>