/**
 * Creates a normalized object from receiptList GraphQL responses.
 * @param {object} receiptResponse response from listReceipt GraphQL queries
 * @returns {object} normalized object with key: receiptID and value: receipt data
 */
export const transformReceiptList = (receiptsResponse) => receiptsResponse.map(transformReceipt)


/**
 * Transforms a GraphQL Receipt response.
 * @param {any} receiptResponse
 * @returns {object} transformed receipt object
 */
export const transformReceipt = (receiptResponse) => {

    const receiptMissingData = missingData(
        receiptResponse.month,
        receiptResponse.year, 
        receiptResponse.category,
        receiptResponse.additionalProperty ? receiptResponse.additionalProperty.items : [],
        receiptResponse.category && receiptResponse.category.additionalPropertyTypes.items.map(i => i.propertyType)
    )

    return {
        id: receiptResponse.id,
        title: receiptResponse.title,
        owner: receiptResponse.owner,
        month: receiptResponse.month,
        year: receiptResponse.year,
        approved: receiptResponse.approved,
        categoryID: receiptResponse.category ? receiptResponse.category.id : null,
        categoryName: receiptResponse.category ? receiptResponse.category.name : null,
        archived: receiptResponse.archived,
        project: receiptResponse.project,
        requestAssistance: receiptResponse.requestAssistance,
        needInfoTicketID: receiptResponse.needInfoTicketID,
        files: receiptResponse.files ? receiptResponse.files.items.map(transformReceiptFile) : [],
        status: receiptStatus(
            receiptResponse.archived,
            receiptResponse.requestAssistance,
            receiptResponse.approved,
            receiptMissingData
        ),
        missingData: receiptMissingData,
        additionalProperties: receiptResponse.additionalProperty ? additionalProperties(receiptResponse.additionalProperty) : [],
        createdAt: receiptResponse.createdAt,
    }

};

function additionalProperties(add) {
    const properties = add.items.map(item => {
        item.value = JSON.parse(item.value)
        return item
    })
    return properties.sort((a, b) => {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        return 0;
    });
}

function missingData(month, year, categoryID, additionalProperties, additionalMetadataTypes) {
    if (!month || !year || !categoryID) return true
    
    // check if additional metadata is valid (all required fields are filled)
    if (additionalMetadataTypes.length) {
        const additionalMetadataValid = additionalMetadataTypes.every(type => {
            if (type.required) {
                return additionalProperties.find(property => property.type.id === type.id)
            } else {
                return true
            }
        })

        // special case: "bewiertet personen" is required when "bewirtet?" is true
        const bewirtetProperty = additionalProperties.find(property => property.type.id === process.env.VUE_APP_BEWIRTET_METADATA_TYPE_ID)
        if (bewirtetProperty) {
            if ( JSON.parse(bewirtetProperty.value) ) {
                const bewirtetPersonenProperty = additionalProperties.filter(property => property.type.id === process.env.VUE_APP_BEWIRTET_PERSONEN_METADATA_TYPE_ID)
                if (!bewirtetPersonenProperty.length) return true

                // check for empty fields
                if (!bewirtetPersonenProperty.every(property => JSON.parse(property.value).length > 0)) return  true
            }
        }

        if (!additionalMetadataValid) return true
    }
    return false
}

function receiptStatus(archived, requestAssistance, approved, missingData) {
    if (archived) return 'booked'
    if (approved) return ''
    if (requestAssistance) return 'requestAssistance'
    if (missingData) return 'missingData'
    return ''
}


/**
 * Transform receipt file object.
 * @param {object} receiptFile response from receiptFile/ receipt GraphQL queries
 * @returns {object} transformed receiptFile object
 */
export const transformReceiptFile = (receiptFile) => ({
    id: receiptFile.id,
    ocrResult: receiptFile.ocrResult,
    path: receiptFile.path,
    imagePaths: receiptFile.imagePaths,
    originalFilename: receiptFile.originalFilename,
    identityId: receiptFile.identityId
});


/**
 * Creates a normalized object from the listReceiptCategorys GraphQL response.
 * @param {object} categoriesResponse response from listReceiptCategorys GraphQL queries
 * @returns {object} normalized object with the category ID as key
 */
export const transformCategoryList = (categoriesResponse) => {
    return Object.fromEntries(
        categoriesResponse.map(category => ([
            category.id,
            transformCategory(category)
        ]))
    );
};

/**
 * Transforms a ReceiptCategory for use in vuex.
 * @param {object} categoryResponse
 * @returns {object} transformed category object
 */
export const transformCategory = (categoryResponse) => ({
    id: categoryResponse.id,
    name: categoryResponse.name,
    additionalMetadata: categoryResponse.additionalPropertyTypes.items.map(item => ({
        id: item.propertyType.id,
        name: item.propertyType.name,
        itemLabel: item.propertyType.itemLabel,
        type: item.propertyType.type,
        required: item.propertyType.required,
        min: item.propertyType.min,
        max: item.propertyType.max
    }))
})

/**
 * Creates a normalized, id-indexed object from the listReports GraphQL response.
 * @param  {array} reports Response array from listReports query
 */
export const transformReportsList = (reports) => {
    return Object.fromEntries(
        reports.map(report => ([
            report.id,
            transformReport(report)
        ]))
    );
}

/**
 * Transforms a Report for use in vuex.
 */
export const transformReport = (report) => ({
    id: report.id,
    title: report.title,
    thumbnailUrl: report.thumbnailUrl,
    urls: report.urls,
    originalFilenames: report.originalFilenames,
    categoryID: report.categoryID
})

/**
 * Creates a normalized, id-indexed object from the listDocuments GraphQL response.
 * @param  {array} documents Response array from listDocuments query
 */
 export const transformDocumentsList = (documents) => {
    return Object.fromEntries(
        documents.map(document => ([
            document.id,
            transformDocument(document)
        ]))
    );
}

/**
 * Transforms a Document for use in vuex.
 */
export const transformDocument = (document) => ({
    id: document.id,
    title: document.title,
    thumbnailUrl: document.thumbnailUrl,
    urls: document.urls,
    originalFilenames: document.originalFilenames
})

