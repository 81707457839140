<template>
<div class="filter__wrapper">
        <div class="filter">
            <div v-if="yearActive || monthActive" class="filter__button">
                <div class="btn--rounded" :class="{active: filterActive}" @click="activeMobileFilter = true">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.64226 6.69L12.5883 1.64251H2.41174L6.39927 6.69C6.48235 6.82054 6.52388 6.95108 6.52388 7.08162V11.607L8.47612 12.6078V7.03811C8.47612 6.90757 8.55919 6.77703 8.64226 6.69ZM14.1251 1.46846L9.55607 7.25567V13.478C9.55607 13.9131 9.1407 14.1742 8.80841 14.0002L5.77622 12.4772C5.61007 12.3902 5.44393 12.2161 5.44393 11.9551V7.25567L0.874878 1.46846C0.584121 1.12036 0.833342 0.554688 1.29025 0.554688H13.7098C14.1667 0.554688 14.4159 1.07684 14.1251 1.46846Z" fill="#FAFAF9"/>
                    </svg>
                    Filter
                </div>
                <div class="filter__content filter__content--mobile" v-if="activeMobileFilter">
                    <div class="filter__content-header">
                        <h3>Filter</h3>
                        <div class="close-btn" @click="activeMobileFilter = false">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#58584D"/>
                            </svg>
                        </div>
                    </div>
                    <div class="dropdowns">
                        <Dropdown class="dropdown--mobile"
                            v-if="yearActive"
                            @selected="handleYearSelect"
                            :selectedItemID="$store.state.report.fromDateYear"
                            :placeholder="'Jahr'"
                            :listItems="years"/>
                        <Dropdown class="dropdown--mobile"
                            v-if="monthActive"
                            @selected="handleMonthSelect"
                            :selectedItemID="$store.state.report.fromDateMonth"
                            :placeholder="'Monat'"
                            :listItems="months"/>
                    </div>
                    <div class="filter__content-footer">
                        <a @click="$store.dispatch('report/resetTimeFilters')" href="#">zurücksetzen</a>
                    </div>
                </div>
                <div class="filter__content--desktop">
                    <Dropdown class="dropdown--desktop smaller"
                        v-if="yearActive"
                        @selected="handleYearSelect"
                        :selectedItemID="$store.state.report.fromDateYear"
                        :placeholder="'Jahr'"
                        :listItems="years"/>
                    <Dropdown class="dropdown--desktop smaller"
                        v-if="monthActive"
                        @selected="handleMonthSelect"
                        :selectedItemID="$store.state.report.fromDateMonth"
                        :placeholder="'Monat'"
                        :listItems="months"/>
                </div>  
            </div>
            <!-- <div v-if="!gridView" class="btn--rounded" :class="{active: activeSelection}" @click="$store.commit('setCheckboxActive', !activeSelection)">
                <svg class="filter-btn" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2798 0.939941C5.65365 0.939941 0.279785 6.31379 0.279785 12.9399C0.279785 19.566 5.65365 24.9399 12.2798 24.9399C18.9059 24.9399 24.2798 19.566 24.2798 12.9399C24.2699 6.30393 18.9059 0.939941 12.2798 0.939941ZM12.2798 23.2144C6.6101 23.2144 1.99548 18.5997 1.99548 12.93C1.99548 7.26037 6.6101 2.64575 12.2798 2.64575C17.9494 2.64575 22.5641 7.26037 22.5641 12.93C22.5542 18.6096 17.9494 23.2144 12.2798 23.2144Z" fill="#151917"/>
                    <path d="M16.9732 8.55194L16.3421 8.18712C16.1153 8.04907 15.8195 8.13781 15.6815 8.36459L11.2345 16.0754L9.01593 12.2397C8.87789 12.013 8.59194 11.9242 8.35529 12.0622L7.72423 12.4271C7.49745 12.5651 7.41856 12.8609 7.54675 13.0877L10.3865 18.0179C10.4753 18.1658 10.633 18.2545 10.7908 18.2545H11.6782C11.836 18.2446 11.9839 18.1559 12.0726 18.0179L17.1507 9.2225C17.2887 8.98585 17.2098 8.68999 16.9732 8.55194Z" fill="#151917"/>
                </svg>
                <div class="delete-btn">
                    <svg width="16" height="16" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1825 8.96257L6.38249 5.16252L10.1825 1.36247C10.3725 1.17247 10.3725 0.862529 10.1825 0.672529L9.65248 0.1425C9.46248 -0.0475 9.15248 -0.0475 8.96248 0.1425L5.16249 3.94255L1.3625 0.1425C1.1725 -0.0475 0.862499 -0.0475 0.672499 0.1425L0.1425 0.672529C-0.0475 0.862529 -0.0475 1.17247 0.1425 1.36247L3.94249 5.16252L0.1425 8.96257C-0.0475 9.15257 -0.0475 9.46251 0.1425 9.65251L0.672499 10.1825C0.862499 10.3725 1.1725 10.3725 1.3625 10.1825L5.16249 6.38249L8.96248 10.1825C9.15248 10.3725 9.46248 10.3725 9.65248 10.1825L10.1825 9.65251C10.3725 9.46251 10.3725 9.15257 10.1825 8.96257Z" fill="#151917"/>
                    </svg>
                </div>
                Auswahl
            </div> -->
            <SearchBar
                activeSearchState="report/setActiveSearchInput"
                commitSearchAction="report/commitSearch"
                closeSearchAction="report/closeSearch"
            ></SearchBar>
        </div>
        <div class="listview-btn--mobile">
            <ListViewToggleButton />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import ListViewToggleButton from '../partials/ListViewToggleButton'
import SearchBar from '../partials/SearchBar'
import Dropdown from '../partials/Dropdown'

export default {
    name: 'FilterReports',
    components: {
        ListViewToggleButton,
        Dropdown,
        SearchBar
    },
    props: {
        monthActive: {
            type: Boolean,
            default: false
        },
        yearActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            /**
             * Variable that detects if mobile filter are hidden
             */
            activeMobileFilter: false
        }
    },
    computed: {
        ...mapState({
            /**
             * Detects if Checkbox mode for list items is active
             */
            // activeSelection: state => state.checkboxActive,
            /**
             * Detects if the gridView mode for listed items is active or not 
             */
            gridView: state => state.gridView
        }),

        /**
         * Function that returns an Array with all month names (january - december)
         */
        months() {
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months.map((month, index) => ({id: index + 1, name: month}))
        },

        /**
         * Function that returns all years from 2021 based on the current year
         * example: when it's 2023 the expected output is 2021, 2022, 2023
         */
        years() {
            const startYear = 2021
            const now = new Date()
            const nowYear = now.getFullYear()
            const yearsArr = Array.apply(0, Array(nowYear - startYear + 1))
            return yearsArr.map((i, index) => {
                const y = index + startYear
                return {
                    name: y.toString(),
                    id: y
                }
            })
        },

        /**
         * returns true if any user-set filters are active
         */
        filterActive() {
            const yearFilter = this.yearActive ? this.$store.state.report.fromDateYear : null
            const monthFilter = this.monthActive ? this.$store.state.report.fromDateMonth : null
            return yearFilter || monthFilter
        }
    }, 
    methods: {
        /**
         * Function that detects which month is selected, sets this as a filter and reloads the content based on this filter
         * @param {Int} month 
         */
        handleMonthSelect(month) {
            this.$store.dispatch(`report/filterMonthRange`, {
                fromDateMonth: month,
                toDateMonth: month
            })
        },

        /**
         * Function that detects which month is selected, sets this as a filter and reloads the content based on this filter
         * @param {Int} year 
         */
        handleYearSelect(year) {  
            this.$store.dispatch(`report/filterYearRange`, {
                fromDateYear: year,
                toDateYear: year,
            })
        },

        /**
         * Click event listener for 'zurücksetzen' button
         * Function that resets all filters and reloads content 
         */
        resetFilters() {
            this.$store.dispatch(`report/resetFilters`)
        },
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .filter {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 7.5px;
            margin-top: 30px;
        }
        &__button {
            display: flex;
            font-size: 12px;
            line-height: 22px;
            .btn--rounded {
                display: flex;
                align-items: center;
                min-height: 35px;
                @media screen and (min-width: $medium-screen) {
                    display: none;
                }
                &:hover {
                    background: $hover-blue;
                    cursor: pointer;
                }
                &.active {
                    background-color: $brand-blue;
                }
            }
        }
        &__content {
            position: fixed;
            background: #fff;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 10;
            &--mobile {
                @media screen and (min-width: $medium-screen) {
                    display: none;
                }
            }
            &-header {
                background-color: $light-grey;
                border-radius: 0 0 0 30px;
                height: 65px;
                padding-left: 15px;
                display: flex;
                align-items: center;
                position: relative;
                .close-btn {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
            &-footer {
                position: fixed;
                bottom: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 200px;
                .btn {
                    background-color: $brand-blue;
                    color: #fff;
                    margin-top: 30px;
                }
                a {
                    outline: none;
                }
            }
            &--desktop {
                .dropdown--desktop {
                    width: 230px;
                    display: none;
                    margin-right: 10px;
                    &.smaller {
                        width: 150px;
                    }
                    @media screen and (min-width: $medium-screen) {
                        display: inline-block;
                    }
                }
            }
        }
        .searchbar {
            margin-right: 10px;
        }
        > .btn--rounded {
            font-size: 12px;
            line-height: 22px;
            cursor: pointer;
            display: none;
            @media screen and (min-width: $medium-screen) {
                padding: 5px 15px;
                font-size: 16px;
                border-radius: 30px;
                min-height: 41px;
                display: flex;
            }
            &:hover {
                background-color: $hover-blue;
                color: #fff;
            }
            &.active {
                background-color: $brand-blue;
            }
            svg path {
                fill: #fff;
            }
            svg rect {
                stroke: #fff;
            }
        }
    }
    .listview-btn--mobile {
        margin-bottom: 5px;
        @media screen and (min-width: $medium-screen) {
            display: none;
        }
    }

    .dropdowns {
        position: fixed;
        top: 100px;
        left: 0;
        width: 100%;
        padding: 15px;
        .dropdown--mobile {
            margin-bottom: 30px;
        }
    }
</style>