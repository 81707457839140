<template>
    <div class="collection">

        <ReceiptviewHeader
            title="Schreibtisch"
            btnText="Beleg hier hochladen"
            @click-btn="$emit('upload-receipt')"
            icon="import"
        >
            <template v-slot:text>
                <p>
                    Auf dem Schreibtisch findest du alle aktuellen To-dos und die entsprechenden Belege. Hier kannst du sie vorsortieren und zuordnen. Und natürlich kannst du uns jederzeit Aufgaben übertragen. Deine Belege wandern im Anschluss an deine Buchungsfreigabe in den aktuellen Monat oder ins Belegarchiv.
                </p>
            </template>
        </ReceiptviewHeader>

        <FilterCollection v-if="!emptyCollectionState" />
        <ReceiptList
            @upload-receipt="$emit('upload-receipt')"
        />
        
        <!-- This is the fixed mobile button for uploading receipts -->
        <div v-if="!emptyCollectionState" class="btn btn--blue addreceipt" @click="$emit('upload-receipt')">
            Beleg hier hochladen
        </div>
    </div>
</template>
<script>
import ReceiptviewHeader from '../partials/ReceiptView_Header'
import FilterCollection from '../filter/Filter_Collection'
import ReceiptList from '../ReceiptList/ReceiptList'

import { mapState } from 'vuex'

export default {
    name: 'Collection',
    components: {
        ReceiptviewHeader,
        FilterCollection,
        ReceiptList,
    },
    data() {
        return {
            /**
             * Variable that stores uploaded files in Array 
             */
            uploadFiles: [],
        }
    },
    methods: {
        /**
         * Change event listener for mobile upload button
         * Function that uploads files and automatically creates receipt
         */
        handleFileChanges(ev) {
            this.uploadFiles = [...ev.target.files]
            this.createReceipt()
        },

        /**
         * Function that creates a receipt and stores it in backend
         */
        async createReceipt() {
            try {
                await this.$store.dispatch(`receipt/createReceipt`, {
                    files: this.uploadFiles
                })
                this.uploadFiles = []
            } catch(err) {
                console.error(err)
            }
        }
    },
    computed: {
        ...mapState({
            /**
             * Function that gets all receipts 
             */
            receipts: state => state.receipt.receipts,

            /**
             * Detects if filters are active
             */
            filterActive(state, getters) {
                return getters[`receipt/filterActive`]
            },

            /**
             * Detects if Checkbox mode for list items is active
             */
            activeSearch: state => state.receipt.activeSearch,

            /**
             * Detects if content is loading
             */
            loading: state => state.receipt.loading,
        }),

        /**
         * Detects if emptyCollectionState is true or false
         * this is the "Hier gibt es noch nichts zu tun" screen 
         */
        emptyCollectionState() {
            return this.$route.name === 'collection' &&
                !this.filterActive &&
                !this.receipts.length &&
                !this.loading &&
                !this.activeSearch 
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .collection {
        padding: 7.5px;
        @media screen and (min-width: $medium-screen) {
            padding: 22.5px;
        }
        p {
            max-width: 90%;
            @media screen and (min-width: $medium-screen) {
                max-width: 60%;
            }
        }
        .addreceipt.btn {
            position: fixed;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            margin: 0;
            @media screen and (min-width: $medium-screen) {
                display: none;
            }
            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                border-radius: 0 15px;
            }
        }
        .footer-links {
            .btn {
                display: inline-block;
                border: none;
                padding: 0;
                padding-left: 10px;
                @media screen and (min-width: $medium-screen) {
                    padding: 8px 13px;
                    border: 1px solid $brand-blue;
                    margin-left: 30px;
                }
                &:hover {
                    background: #fff;
                    color: $hover-blue;
                    @media screen and (min-width: $medium-screen) {
                        background: $hover-blue;
                        color: #fff;
                        border-color: $hover-blue;
                    }
                }
                &--blue {
                    border: none;
                    padding: 0;
                    padding-left: 10px;
                    background: #fff;
                    color: $brand-blue;
                    @media screen and (min-width: $medium-screen) {
                        padding: 8px 13px;
                        border: 1px solid $brand-blue;
                        background: $brand-blue;
                        color: #fff;
                    }
                    &:hover {
                        color: $hover-blue;
                        @media screen and (min-width: $medium-screen) {
                            background: $hover-blue;
                            color: #fff;
                            border-color: $hover-blue;
                        }
                    }
                }
            }
        }
    }
</style>
