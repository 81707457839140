<template>
    <div class="staff-wrapper">
        <h1>User-Liste</h1>

        <div class="list">
            <div class="list-topbar">
                <div class="username">Username</div>
                <div class="email">E-Mail</div>
                <div class="firstname">Vorname</div>
                <div class="lastname">Nachname</div>
                <div class="status">Status</div>
                <div class="receipt">
                    Belege anschauen
                </div>
            </div>
            <div 
                v-for="user in users"
                :key="user.Username"
                class="list-item"
            >
                <div class="username">{{ shorten(user.Username) }}</div>
                <div class="email">{{ user.Attributes.email }}</div>
                <div class="firstname">{{ user.Attributes.given_name }}</div>
                <div class="lastname">{{ user.Attributes.family_name }}</div>
                <div class="status">{{ user.UserStatus }}</div>
                <div class="receipt">
                    <div class="link" @click="$router.push({ query: { username: user.Username } })">
                        Belege anzeigen
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

// import { API, graphqlOperation } from 'aws-amplify'
// import * as user_subscriptions from '../graphql/user_subscriptions';

export default {
    name: 'StaffHome',
    data() {
        return {
            allReceipts: [],
            userReceipts: [],
            activeUser: undefined,
            subscription: undefined
        }
    },
    computed: {
      ...mapState({
          /**
           * Function that returns Array with users
           */
          users: state => Object.values(state.staff.users) 
      })
    },
    methods: {
        /**
         * Function that takes a string and returns a new string based on its length
         */
        shorten(name) {
            if (name.length > 15) {
               return name.substring(0,15) + '....' + name.substr(name.length - 3)
            } else {
                return name
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .staff-wrapper {
        padding: 15px;
        font-size: 12px;
        @media screen and (min-width: $medium-screen) {
            font-size: 14px;
            padding: 30px;
        }
    }
    .list {
        padding-top: 30px;
        > div {
            display: flex;
            border-bottom: 1px solid $default-grey;
            &.list-topbar {
                padding: 10px 0;
                font-family: "Gotham-Medium";
            }
            &.list-item {
                padding: 10px 0;
            }
        }
        .username {
            width: 50%;
            @media screen and (min-width: $medium-screen) {
                width: 20%;
            }
        }
        .email {
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: block;
                width: 25%;
            }
        }
        .firstname {
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: block;
                width: 12.5%;
            }
        }
        .lastname {
            width: 30%;
            @media screen and (min-width: $medium-screen) {
                width: 12.5%;
            }
        }
        .status {
            display: none;
            @media screen and (min-width: $medium-screen) {
                display: block;
                width: 15%;
            }
        }
        .receipt {
            width: 20%;
            .link {
                color: $brand-blue;
                cursor: pointer;
                &:hover {
                    color: $hover-blue;
                }
            }
            @media screen and (min-width: $medium-screen) {
                width: 15%;
            }
        }
    }
</style>