import Vue from 'vue'
import Vuex from 'vuex'

import { authModule, receiptModule, reportModule, staffModule, documentModule } from './modules'

import { prefillWidgetUserInfo } from '../lib/freshdeskWidget'
import { submitTicket } from '../graphql/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuHidden: true,
    gridView: true,
    checkboxActive: false,
    pageLoading: true
  },
  mutations: {
    setMenuHidden(state, hidden) {
      state.menuHidden = hidden
    },
    setGridView(state, status) {
      state.gridView = status
    },
    setCheckboxActive(state, status) {
      state.checkboxActive = status
    },
    setPageLoading(state, status) {
      state.pageLoading = status
    }
  },
  actions: {    
    /**
     * Prefills user info (Name and E-Mail) in FreshDesk widget
     */
    async prefillWidget({state}) {
      prefillWidgetUserInfo(
        state.auth.user.attributes.email,
        state.auth.user.attributes.given_name + ' ' + state.auth.user.attributes.family_name
      )
    },

    async submitAssistance({state, dispatch}) {
      await dispatch('receipt/requestAssistance')

      const fullName = state.auth.user.attributes.given_name + ' ' + state.auth.user.attributes.family_name
      const inboxUrl = `${window.location.origin}/collection?username=${state.auth.username}`
      await submitTicket(
        `[SUMMARUM MACHT'S] Nutzer ${fullName}`,
        `Summarum macht's Anfrage von ${fullName}: ${inboxUrl}`,
        ['summarum-machts']
      )
    },

    async requestUpgrade({state}) {
      const fullName = state.auth.user.attributes.given_name + ' ' + state.auth.user.attributes.family_name
      await submitTicket(
        `[UPGRADE-ANFRAGE] Nutzer ${fullName}`,
        `User ${fullName} wünscht Upselling. Bitte Kontakt aufnehmen.`,
        ['upgrade']
      )
    },

    resetCheckboxes({commit}) {
      commit(`receipt/setSelectedReceiptIDs`, [])
    },
  },

  modules: {
    auth: authModule,
    receipt: receiptModule,
    staff: staffModule,
    report: reportModule,
    documents: documentModule
  }
})
