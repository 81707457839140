/**
 * This module that manages reports data.
 * */

import Vue from 'vue'

import { transformReportsList } from '../transformer'
import { listReports, searchReports, createReport, deleteReport, listReportCategories, updateReportSubscription, getS3URL } from '@/graphql/api'

import { downloadZip } from 'client-zip'

/**
 * Loads S3 images for an array of reports.
 * @param {any} reports
 */
const loadS3ImageURLsList = async (reports) => {
  return Promise.all(reports.map(async (report) => await loadS3ImageURLs(report)))
}

/**
 * Fetches the S3 URLs of all report files and the thumbnail
 * @param {any} receipt
 */
const loadS3ImageURLs = async (report) => {
  let urls = []
  for (const path of report.paths) {
    urls.push(await getS3URL(path, report.identityId))
  }
  report.urls = urls
  report.thumbnailUrl = report.thumbnail ? await getS3URL(report.thumbnail, report.identityId) : ''
  return report
}

export const reportModule = {
  namespaced: true,
  state: () => ({
    reports: {},
    categories: [],
    loading: false,
    activeSearch: false, // Status of report search. True when search is active.

    filterCategoryID: undefined, // if set, reports are filtered by category
    fromDateMonth: undefined, // date filter: 1-indexed number of the month (from)
    fromDateYear: undefined, // date filter: from year (format: YYYY)
    toDateMonth: undefined, // date filter: 1-indexed number of the month (to)
    toDateYear: undefined, // date filter: to year (format: YYYY)

    listReportsBy: undefined,
    subscription: undefined
  }),
  mutations: {
    setListReportsBy(state, username) {
      state.listReportsBy = username
    },
    setReports(state, reports) {
      state.reports = transformReportsList(reports)
    },
    updateReport(state, report) {
      Vue.set(state.reports, report.id, report)
    },
    setLoading(state, loadingState) {
      state.loading = loadingState
    },
    setActiveSearchInput(state, activeSearch) {
      state.activeSearch = activeSearch
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setFilterCategoryID(state, categoryID) {
      state.filterCategoryID = categoryID
    },
    setFromDateMonth(state, month) {
      state.fromDateMonth = month;
    },
    setFromDateYear(state, year) {
      state.fromDateYear = year;
    },
    setToDateMonth(state, month) {
      state.toDateMonth = month;
    },
    setToDateYear(state, year) {
      state.toDateYear = year;
    },
    setSubscription(state, subscription) {
      state.subscription = subscription
    }
    
  },

  actions: {
    /**
     * Initialize the reports module: Load category data and reports
     */
    async init({dispatch}) {
      dispatch('loadCategories')
    },

    /**
     * Fetches reports of the user set in listReceiptsBy.
     */
    async load({commit, getters, state}) {
      commit('setLoading', true)
      let reports = await listReports(
        getters.listReportsBy,
        state.fromDateYear,
        state.fromDateMonth,
        state.toDateYear,
        state.toDateMonth,
        state.filterCategoryID
      )
      reports = await loadS3ImageURLsList(reports)
      commit('setReports', reports)
      commit('setLoading', false)
    },

    /**
     * Fetches available report categories.
     */
    async loadCategories({commit}) {
      commit('setCategories', await listReportCategories())
    },

    /**
      * Filter reports by a month range.
      * @param input {
      *   fromDateMonth {number}: 1-indexed number of the month (from)
      *   toDateMonth {number}: 1-indexed number of the month (to)
      * }
      *
    */
    filterMonthRange({commit, dispatch}, {fromDateMonth, toDateMonth}) {
      commit('setFromDateMonth', fromDateMonth)
      commit('setToDateMonth', toDateMonth)
      dispatch('load')
    },

    /**
      * Filter reports by a year range.
      * @param input {
      *   fromDateYear {number}: from year (format: YYYY)
      *   toDateYear {number}: to year (format: YYYY)
      * }
      *
    */
    filterYearRange({commit, dispatch}, {fromDateYear, toDateYear}) {
      commit('setFromDateYear', fromDateYear)
      commit('setToDateYear', toDateYear)
      dispatch('load')
    },

    /**
     * Reset category and date filters.
     */
    resetFilters({commit}) {
      commit('setFilterCategoryID', undefined)
      commit('setFromDateMonth', undefined)
      commit('setFromDateYear', undefined)
      commit('setToDateMonth', undefined)
      commit('setToDateYear', undefined)
    },

    /**
     * Reset category and date filters.
     */
    resetTimeFilters({commit, dispatch}) {
      commit('setFromDateMonth', undefined)
      commit('setFromDateYear', undefined)
      commit('setToDateMonth', undefined)
      commit('setToDateYear', undefined)
      
      dispatch('load')
    },

    /**
     * Search reports
     * @param {string} term search term
     */
    async commitSearch({getters, state, commit}, term) {
      let result = await searchReports(
        term,
        getters.listReportsBy
      )
      let filteredResult = result.filter(report => report.categoryID === state.filterCategoryID)
      if (filteredResult.length) {
        filteredResult = await loadS3ImageURLsList(filteredResult)
        commit('setReports', filteredResult)
      }
    },

    /**
    * Closes the current search. Sets the state.searchStatus to false and fetches all reports.
    */
    closeSearch({dispatch, commit}) {
      dispatch('load')
      commit('setActiveSearchInput', false)
    },

    /**
     * Creates a report for the currently selected user. The report category is inferred from the currently set category filter.
     * @param input {
     *   files {array}: Files to add to the report
     *   title {string}: Report title
     *   month {number}: report month (1-indexed); optional, can be null
     *   year {number}: report year (optional, can be null)
     * }
     */
    async createReport({rootState, rootGetters, commit, state}, {files, title, month, year}) {
      let newReport = await createReport(
        Object.values(files),
        rootState.auth.username,
        title,
        state.filterCategoryID,
        month,
        year,
        rootGetters['staff/selectedUser'].Username,
        rootGetters['staff/selectedUser'].Attributes['custom:identityId']
      )

      const report = await loadS3ImageURLs(newReport)
      commit('updateReport', report)
    },

    /** 
     * Delete a report
     * @param {string} reportID ID of the report to delete
     */
    async deleteReport({dispatch}, reportID) {
      await deleteReport(reportID)
      dispatch('load')
    },
    
    /**
     * Initiate a download of a report.
     * @param {string} reportID ID of the report files to downlad
     */
    async downloadReport({state}, reportID) {
      let downloads = []
      if (!state.reports[reportID]) return console.error('could not find report with ID ', reportID)
      
      const report = state.reports[reportID]
      for (const [index, url] of report.urls.entries()) {
        try {
          const download = await fetch(url)
          const blob = await download.blob()
          const renamed = new File([blob], `${report.originalFilenames[index]}`, {type: download.type})
          downloads.push(renamed)
        } catch(err) {
          console.error('could not download file', url)
        }
      }

      const blob = await downloadZip(downloads).blob()

      // make and click a temporary link to download the Blob
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)

      const filename = report.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()
      link.download = `${filename}.zip`
      link.click()
      link.remove()
    },

    
    /**
     * Initiate download of reports by a list of IDs.
     * @param {array} reports report object array
     */
    async downloadReports({state}, reports) {
      if (!reports.length) return

      let downloads = []

      const titleSafe = (title) => title.replace(/[^a-z0-9]/gi, '_').toLowerCase()

      for (const report of reports) {
        let folderName = titleSafe(report.title)
        const duplicateTitles = reports.filter(r => titleSafe(r.title) === folderName)
        if (duplicateTitles.length > 1) {
          const currIndex = duplicateTitles.findIndex(r => r.id === report.id)
          folderName += `_${currIndex + 1}`
        }

        for (const [index, url] of report.urls.entries()) {
          try {
            const download = await fetch(url)
            const blob = await download.blob()
            const categoryName = state.categories.find(c => c.id === report.categoryID).name
            const renamed = new File([blob], `${categoryName}/${folderName}/${report.originalFilenames[index]}`, {type: download.type})
            downloads.push(renamed)
          } catch(err) {
            console.error('could not download file', url)
          }
        }
      }

      const blob = await downloadZip(downloads).blob()

      // make and click a temporary link to download the Blob
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)

      link.download = `summarum_reports.zip`
      link.click()
      link.remove()
    },

    
    /**
     * Initiate download of all reports that are currently displayed.
     */
    async downloadShownReports({state, dispatch}) {
      dispatch('downloadReports', Object.values(state.reports))
    },

    /**
     * Initiate a download of repports from all categories.
     */
    async downloadAllReports({getters, dispatch}) {
      let reports = await listReports(
        getters.listReportsBy
      )
      reports = await loadS3ImageURLsList(reports)
      dispatch('downloadReports', reports)
    },


    async setupSubscriptions({state, rootState, commit}) {
      if (state.subscription) state.subscription.unsubscribe()

      const subscription = await updateReportSubscription(state.listReportsBy, rootState.auth.username, async (report) => {
        report = await loadS3ImageURLs(report)
        commit('updateReport', report)
      })

      commit('setSubscription', subscription)

    }

  },

  getters: {

    /**
     *  Returns an array of receipt objects.
     */
    reports: (state) => {
      return Object.values(state.reports)
    },

    listReportsBy: (state, getters, rootState) => {
      return state.listReportsBy || rootState.auth.username
    },

  }
}
