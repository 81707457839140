<template>
    <div>
        <div :class="['modal-overlay', {'above-modal': aboveModal}]" @click.stop="closeModal"></div>

        <transition name="modal">
            <div class="modal" :class="{small: size === 'small', xlarge: size === 'large', 'above-modal': aboveModal}">
                <div class="modal-container">

                    <div class="modal-header">
                        <div class="close-btn" @click.stop="closeModal">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.8268 4.66868L12.1787 10.3167L6.53447 4.67246C6.25099 4.38899 5.79124 4.38259 5.5016 4.67223L4.72712 5.44671C4.44377 5.73006 4.43757 6.18981 4.72735 6.47958L10.3779 12.1301L4.73616 17.7719C4.44022 18.0552 4.44662 18.515 4.73009 18.7984L5.51121 19.5796C5.79469 19.863 6.25444 19.8694 6.54408 19.5798L12.1921 13.9318L17.8427 19.5823C18.1261 19.8658 18.5922 19.8659 18.8755 19.5825L19.6563 18.8018C19.9397 18.5184 19.9396 18.0524 19.6561 17.7689L14.0055 12.1183L19.6536 6.47031C19.9369 6.18696 19.9368 5.72091 19.6533 5.43744L18.8722 4.65631C18.5762 4.38543 18.1101 4.38533 17.8268 4.66868Z" fill="#fff"/>
                            </svg>
                        </div>
                        <p>
                            <slot name="headertext"></slot>
                        </p>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                        </slot>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            size: {
                type: String,
                default: 'large' // can also be "small"
            },
            headerText: {
                type: String,
            },
            aboveModal: {
                type: Boolean,
                default: false // true if the modal is above another modal
            }
        },
        methods: {
            closeModal() {
                this.$store.state.receipt.activeModal = false
                this.$emit('close-modal')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .modal {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        max-width: 1000px;
        width: 100%;
        z-index: 2147483649; // weird number because of the "hilfe" z-index
        background: $bg-surface;
        @media screen and (min-width: $medium-screen) {
            min-height: 0;
            border-radius: $brand-border-r;
        }
        &.above-modal {
            z-index: 105;
        }

        &-container {
            display: flex;
            flex-direction: column;
            min-height: 0;
            height: 100%;
        }

        &.small {
            min-height: 0;
            width: 250px;
            height:200px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: $brand-border-r;
            z-index: 300;
            @media screen and (min-width: $medium-screen) {
                width: 600px;
                height: 400px;
            }
            .modal-header {
                height: 40px;
                font-size: 12px;
                line-height: 13px;
                @media screen and (min-width: $medium-screen) {
                    height: 85px;
                    font-size: 25px;
                    line-height: 30px;
                }
            }
            .modal-body {
                border-radius: $brand-border-r;
                padding-top: 50px;
                overflow: visible;
                font-size: 10px;
                margin-bottom: 150px;
                @media screen and (min-width: $medium-screen) {
                    padding-top: 100px;
                }
            }
            .modal-footer {
                border: none;
                border-radius: $brand-border-r;
                z-index: 0;
            }
        }
        &.xlarge {
            z-index: 2147483648;
            @media screen and (min-width: $large-screen) {
                max-width: 950px; 
                max-height: 650px;
                overflow: hidden;
            }

            max-height: 650px;
            height: calc(100vh - 100px);
        }
        &-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 50;
            background: rgba(0, 0, 0, 0.6);

            &.above-modal {
                z-index: 201;
            }
        }
        &-header {
            position: fixed;
            width: 100%;
            height: 60px;
            background-color: $light-grey;
            border-radius: 0 0 0 30px;
            padding-left: 15px;
            display: flex;
            align-items: center;
            z-index: 30;
            @media screen and (min-width: $medium-screen) {
                border-radius: $brand-border-r;
                padding-left: 30px;
                height: 77px;
            }
            p {
                font-size: 12px;
                line-height: 20px;
                font-family: "Gotham-Bold";
                @media screen and (min-width: $medium-screen) {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
            .close-btn {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                z-index: 100;
                @media screen and (min-width: $medium-screen) {
                    right: 15px;
                }
                svg path {
                    fill: $brand-deep-green;
                }
                &:hover {
                    svg path {
                        fill: $hover-blue;
                    }
                }
            }
        }
        &-body {
            flex-grow: 1;
            padding: 15px;
            padding-top: 75px; // because of 60px header + 15px white space
            overflow-y: scroll;
            cursor: auto;
            background-color: $bg-surface;
            margin-bottom: 60px;
            border-radius: $brand-border-r;
            position: relative;
            @media screen and (min-width: $medium-screen) {
                padding: 30px;
                padding-top: 95px;
                margin-bottom: 50px;
            }
        }
        &-footer {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 0 15px 5px 15px;
            background-color: $bg-surface;
            border-top: 1px solid $default-grey;
            z-index: 20;
            cursor: auto;
            border-radius: 0 0 0 30px;
            padding: 15px 30px;
        }
    }

</style>
