<template>
    <div class="settings" >
        <h2>Einstellungen</h2>

        <div class="loadingspinner" v-if="this.billwerkSettingsActive == undefined">
            <LoadingSpinner  color="blue"/>
        </div>
        <div v-if="this.billwerkSettingsActive !== undefined">
            <SettingsItem 
                v-if="this.billwerkSettingsActive"
                headline="Account"
                description="Hier kannst du deine persönlichen Daten verändern und deinen Vertrag kündigen."
                @open="handleBillwerkSelfServiceClick"
            />
            <SettingsItem 
                headline="Abo"
                description="Hier kannst du einen Paketwechsel vornehmen."
                @open="$router.push({name: 'pricepackage'})"
            />
            <!-- <SettingsItem 
                headline="Passwort ändern"
                description="Neues Passwort fällig? Dann hier entlang."
                @open="$router.push({name: 'payment'})"
            />
            <SettingsItem 
                headline="2-Faktor-Authentication"
                description="Aktiviere die 2-Faktor-Authentification, um dein Profil noch besser abzusichern."
                @open="$router.push({name: 'authentication'})"
            /> -->
            <SettingsItem 
                headline="Datenschutz"
                description="Hier kannst du unsere AGB und unsere Datenschutzerklärung einsehen."
                @open="$router.push({name: 'dataprivacy'})"
            />
            <!-- <SettingsItem 
                headline="Account löschen"
                description="Du willst uns verlassen? Schadeschade! Aber vielleicht sehen wir uns ja bald wieder."
                @open="$router.push({name: 'dataprivacy'})"
            /> -->
        </div>
    </div>
</template>

<script>
    import SettingsItem from '../../partials/SettingsItem.vue'
    import LoadingSpinner from '../../partials/LoadingSpinner'

    import { getBillwerkSelfServiceURL } from '../../../graphql/api'

    export default {
        name: 'Navigation',
        components: {
            SettingsItem,
            LoadingSpinner
        },
        data() {
            return {
                billwerkSettingsURL: '',
                billwerkSettingsActive: undefined
            }
        },
        async mounted() {
            try {
                this.billwerkSettingsURL = await getBillwerkSelfServiceURL()
                this.billwerkSettingsActive = true
            } catch (err) {
                this.billwerkSettingsActive = false
            }
        },
        methods: {
            handleBillwerkSelfServiceClick() {
                window.open(this.billwerkSettingsURL, '_blank')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .settings {
        position: relative;

        h2 {
            padding-top: 7.5px; // wrapper has already 7.5px padding
            padding-bottom: 30px;
            margin: 0 7.5px; // wrapper has already 7.5px padding
        }

        .loadingspinner {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;

            div {                
                width: 40px;
                height: 40px;
            }
        }
    }
</style>