<template>  
    <div class="spinner-container" :class="{blue: color === 'blue'}">
        <svg viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10"></circle>
        </svg>
    </div>

</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: {
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';


    .spinner-container {
        > svg {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;            
            > circle {
                stroke: #fff;
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35px;
                animation: dash 1.5s ease-in-out infinite;
                stroke-linecap: round;
            }
        }

        &.blue {
            svg circle {
                stroke: $brand-blue;
            }
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0px;
        }

        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }

        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }


</style>