<template>
    <div class="list">
        <div v-if="receipts.length" class="list__topbar">
            <div class="sort-btn receipt__name" @click="sortReceiptsBy(receipts, 'title')">
                Dateiname 
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.632488 5.52699C0.774988 5.66949 1.00749 5.66949 1.14999 5.52699L3.35499 3.32199V8.12949C3.35499 8.33199 3.51999 8.49699 3.72249 8.49699H4.27749C4.47999 8.49699 4.64499 8.33199 4.64499 8.12949V3.32199L6.84999 5.52699C6.99249 5.66949 7.22499 5.66949 7.36749 5.52699L7.76499 5.12949C7.90749 4.98699 7.90749 4.75449 7.76499 4.61199L4.65999 1.50699L4.26249 1.10949V1.10199C4.11999 0.959492 3.88749 0.959492 3.74499 1.10199L3.34749 1.49949C3.33249 1.50699 3.33249 1.52199 3.32499 1.52949L0.234988 4.61949C0.0924878 4.76199 0.0924878 4.99449 0.234988 5.13699L0.632488 5.52699Z" fill="#58584D"/>
                </svg>
            </div>
            <div class="sort-btn receipt__status" @click="sortReceiptsBy(receipts, 'status')">
                Status 
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.632488 5.52699C0.774988 5.66949 1.00749 5.66949 1.14999 5.52699L3.35499 3.32199V8.12949C3.35499 8.33199 3.51999 8.49699 3.72249 8.49699H4.27749C4.47999 8.49699 4.64499 8.33199 4.64499 8.12949V3.32199L6.84999 5.52699C6.99249 5.66949 7.22499 5.66949 7.36749 5.52699L7.76499 5.12949C7.90749 4.98699 7.90749 4.75449 7.76499 4.61199L4.65999 1.50699L4.26249 1.10949V1.10199C4.11999 0.959492 3.88749 0.959492 3.74499 1.10199L3.34749 1.49949C3.33249 1.50699 3.33249 1.52199 3.32499 1.52949L0.234988 4.61949C0.0924878 4.76199 0.0924878 4.99449 0.234988 5.13699L0.632488 5.52699Z" fill="#58584D"/>
                </svg>
            </div>
            <div class="sort-btn receipt__type" @click="sortReceiptsByCategory()">
                Zuordnung 
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.632488 5.52699C0.774988 5.66949 1.00749 5.66949 1.14999 5.52699L3.35499 3.32199V8.12949C3.35499 8.33199 3.51999 8.49699 3.72249 8.49699H4.27749C4.47999 8.49699 4.64499 8.33199 4.64499 8.12949V3.32199L6.84999 5.52699C6.99249 5.66949 7.22499 5.66949 7.36749 5.52699L7.76499 5.12949C7.90749 4.98699 7.90749 4.75449 7.76499 4.61199L4.65999 1.50699L4.26249 1.10949V1.10199C4.11999 0.959492 3.88749 0.959492 3.74499 1.10199L3.34749 1.49949C3.33249 1.50699 3.33249 1.52199 3.32499 1.52949L0.234988 4.61949C0.0924878 4.76199 0.0924878 4.99449 0.234988 5.13699L0.632488 5.52699Z" fill="#58584D"/>
                </svg>
            </div>
            <div class="receipt__action">
                Aktion 
            </div>
        </div>

        <div class="receipt-items">
            <ReceiptListItem 
                ref="receipt"
                v-for="receipt in receipts"
                :key="receipt.id"
                :receipt="receipt"
                :receiptTitle="receipt.title"
                :receiptMonth="getMonthString(receipt.month - 1)"
                :receiptType="receipt.categoryName"
                :receiptStatus="receipt.status"
            >
            </ReceiptListItem>
        </div>
    </div>
</template>

<script>
import ReceiptListItem from './Receipt_ListItem'

export default {
    name: 'ReceiptListView',
    components: {
        ReceiptListItem
    },
    data() {
        return {
            reversedOrder: false
        }
    },
    props: {
        receipts: {
            type: Array, 
            required: true
        },
    },
    methods: {
        /**
         * Function that gets month as a number and returns its name as a string
         * @param {Int} n
         */
        getMonthString (n) {
            const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            return months[n]
        },

        sortReceiptsBy(array, sort) {
            if (!this.reversedOrder) {
                array.sort(function (a, b) {
                    if (a[sort] < b[sort]) return -1
                    if (a[sort] > b[sort]) return 1
                    return 0
                })
                this.reversedOrder = true
            } else {
                array.sort(function (a, b) {
                    if (a[sort] < b[sort]) return 1
                    if (a[sort] > b[sort]) return -1
                    return 0
                })
                this.reversedOrder = false
            }
        },

        sortReceiptsByCategory() {
            if (!this.reversedOrder) {
                this.receipts.sort(function (a, b) {
                    if (a.month === null) return -1
                    if (b.month === null) return 1
                    if (a.month < b.month) return -1
                    if (a.month > b.month) return 1
                    if (a.categoryName > b.categoryName) return 1
                    if (a.categoryName < b.categoryName) return -1
                })
                this.reversedOrder = true
            } else {
                this.receipts.sort(function (a, b) {
                    if (a.month === null) return -1
                    if (b.month === null) return 1
                    if (a.month < b.month) return 1
                    if (a.month > b.month) return -1
                    if (a.categoryName > b.categoryName) return 1
                    if (a.categoryName < b.categoryName) return -1
                })
                this.reversedOrder = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .list {
        margin: 0 7.5px;
        &__topbar {
            display: flex;
            justify-content: flex-start;
            font-size: 10px;
            line-height: 15px;
            color: $grey-text;
            border-bottom: 1px solid $default-grey;
            padding-bottom: 5px;
            font-family: "Gotham-Medium";
            .receipt {
                &__name {
                    width: 80%;
                    @media screen and (min-width: $medium-screen) {
                        width: 30%;
                    }
                }
                &__status {
                    width: 20%;
                    text-align: center;
                    @media screen and (min-width: $medium-screen) {
                        width: 30%;
                        text-align: left;
                    }
                }
                &__type {
                    display: none;
                    @media screen and (min-width: $medium-screen) {
                        display: block;
                        width: 35%;
                    }
                }
                &__action {
                    display: none;
                    @media screen and (min-width: $medium-screen) {
                        display: block;
                        width: 5%;
                        text-align: center;
                    }
                }
            }
            .sort-btn {
                -ms-user-select: None;
                -moz-user-select: None;
                -webkit-user-select: None;
                user-select: None;
                cursor: pointer;
            }
        } 
    }
</style>