<template>
    <div class="categories__buttons">
        <div :class="['btn--rounded', {active: value}]" @click="$emit('change', true)">Ja</div>
        <div :class="['btn--rounded', {active: value === false}]"  @click="$emit('change', false)">Nein</div>
    </div>
</template>
<script>
export default {
    name: 'BoolSelect',
    props: {
        value: {
            type: Boolean
        }
    }
}
</script>
