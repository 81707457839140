<template>
    <div>
        <div class="list-view">
            <ReceiptviewSubheader
                :title="currentCategory.name"
                :backLinkName="'zurück'"
                :btnName="$store.state.receipt.selectedReceiptIDs.length ? 'Diese Auswahl exportieren' : 'Angezeigte Belege exportieren'"
                @click-btn="exportFiles" >
                </ReceiptviewSubheader>
            <div>
                <FilterList />
                <ReceiptList 
                    :receiptsCategoryID="$route.params.id"
                    currentScreen="list"
                >
                </ReceiptList>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import ReceiptviewSubheader from '../partials/ReceiptView_Subheader'
    import FilterList from '../filter/Filter_List'
    import ReceiptList from '../ReceiptList/ReceiptList'


    export default {
        name: 'List',
        components: {
            ReceiptviewSubheader,
            FilterList,
            ReceiptList
        },
        computed: {
            ...mapState({
                /**
                 * gets all categories from backend
                 */
                categories: state => state.receipt.categories
            }),

            /**
             * returns current category 
             */
            currentCategory() {
                if (!this.$route.params.categoryID) {
                    return null
                }

                return this.categories[this.$route.params.categoryID]
            }
        },
        methods: {
            /**
             * Click event listener for export button
             */
            exportFiles() {
                if (this.$store.state.receipt.selectedReceiptIDs.length) {
                    this.$store.dispatch('receipt/downloadReceitpsByID', this.$store.state.receipt.selectedReceiptIDs)
                } else {
                    this.$store.dispatch('receipt/downloadReceipts', this.$store.state.receipt.receipts)
                }
            }
        }
    }
</script>
