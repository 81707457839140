import { INBOX_RECEIPTS, ARCHIVE_RECEIPTS } from '../constants/'
import { FILTER_CURRENT_MONTH, FILTER_PREVIOUS_MONTH } from '../store/actions/'


import Login from '@/components/views/Login'
import Collection from '@/components/views/Collection'
import Archive from '@/components/views/Archiv/Archive'
import UserHome from '@/components/views/Home/UserHome'
import StaffHome from '@/components/views/Home/StaffHome'
import LastMonthStart from '@/components/views/LastMonth/Start'
import CurrentMonthStart from '@/components/views/CurrentMonth/Start'
import ReportsStart from '@/components/views/Reports/Start'
import ReportFiles from '@/components/views/Reports/ReportFiles'
import DocumentStart from '@/components/views/Documents/Start'

import Index from '@/components/views/Index'
import List from '@/components/views/List'

import Settings from '@/components/views/Settings/Start'
import Account from '@/components/views/Settings/Account'
import Authentication from '@/components/views/Settings/Authentication'
import DataPrivacy from '@/components/views/Settings/DataPrivacy'
import Payment from '@/components/views/Settings/Payment'
import PricePackage from '@/components/views/Settings/PricePackage'

const routes = [
    {
        path: '/',
        name: 'base',
        redirect: { name: 'home' }
    },
    {
        path: '/home',
        name: 'home',
        component: UserHome
    },
    {
        path: '/collection',
        name: 'collection',
        component: Collection,
        props: {
            receiptsStoreKey: INBOX_RECEIPTS
        },
        children: [
            {
                path: 'receipt/:id',
                name: 'receipt detail collection',
            },
        ]
    },
    {
        path: '/receipt/:id',
        name: 'receipt detail (direct link)',
    },
    {
        path: '/archive',
        name: 'archive',
        component: Archive,
        props: {
            receiptsStoreKey: ARCHIVE_RECEIPTS
        },
        children: [
            {
                path: 'receipt/:id',
                name: 'receipt detail archive',
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/staff',
        name: 'staff',
        component: StaffHome
    },
    {
        path: '/freshdesk',
        name: 'freshdesk',
    },
    {
        path: '/currentmonth/',
        name: 'currentmonth',
        component: Index,
        children: [
            {
                path: '',
                name: 'start',
                component: CurrentMonthStart
            },
            {
                path: ':categoryID',
                name: 'currentMonthReceiptlist',
                component: List,
                props: {
                    receiptsStoreKey: ARCHIVE_RECEIPTS,
                    receiptsStoreAction: FILTER_CURRENT_MONTH
                },
                children: [
                    {
                        path: 'receipt/:id',
                        name: 'receipt detail current month',
                    },
                ]
            },
        ]
    },
    {
        path: '/lastmonth',
        name: 'lastmonth',
        component: Index,
        children: [
            {
                path: '',
                name: 'start',
                component: LastMonthStart
            },
            {
                path: ':categoryID',
                name: 'lastMonthReceiptlist',
                component: List,
                props: {
                    receiptsStoreKey: ARCHIVE_RECEIPTS,
                    receiptsStoreAction: FILTER_PREVIOUS_MONTH
                },
                children: [
                    {
                        path: 'receipt/:id',
                        name: 'receipt detail last month',
                    },
                ]
            },
        ]
    },
    {
        path: '/documents',
        name: 'documents',
        component: DocumentStart
    },
    {
        path: '/reports',
        name: 'reports',
        component: Index,
        children: [
            {
                path: '',
                name: 'reportsStart',
                component: ReportsStart
            },
            {
                path: ':categoryID',
                name: 'categoryReports',
                component: ReportFiles
            },
        ]
    },
    {
        path: '/settings',
        name: 'settings',
        component: Index,
        children: [
            {
                path: '',
                name: 'start',
                component: Settings
            },
            {
                path: 'account',
                name: 'account',
                component: Account
            },
            {
                path: 'authentication',
                name: 'authentication',
                component: Authentication
            },
            {
                path: 'dataprivacy',
                name: 'dataprivacy',
                component: DataPrivacy
            },
            {
                path: 'payment',
                name: 'payment',
                component: Payment
            },
            {
                path: 'pricepackage',
                name: 'pricepackage',
                component: PricePackage
            }
        ]
    }
]

export default routes
