<template>
    <div class="receipt__type">
        {{receiptMonth}} <span v-if="receiptType !== null && receiptMonth !== null">|</span> {{receiptType}}
    </div>
</template>

<script>
export default {
    name: 'ReceiptType',
    props: {
        receiptMonth: {
            type: String,
            required: false
        },
        receiptType: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .receipt__type {
        font-size: 10px;
        line-height: 15px;
        color: $grey-text;
        padding-bottom: 10px;
        display: none;
        @media screen and (min-width: $medium-screen) {
            display: block;
        }
    }
</style>