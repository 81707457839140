<template>
    <div class="wrapper">
        <div class="reports">
            <ReceiptviewHeader :title="'Dokumente'" :btnText="'Ordner exportieren'" @click-btn="exportClick">
                <template v-slot:text>
                    <p>
                        Hier findest du wichtige Dokumente aus den anderen Geschäftsbereichen zum Download.
                    </p>
                </template>
            </ReceiptviewHeader>

            <div v-if="$store.getters['auth/isStaff']" class="start">
                <div v-if="!userSelected">Zum Dokuemnte-Upload bitte zunächst einen Nutzer aus der <router-link  to="/home">User-Liste</router-link> wählen.</div>
                <input
                    class="fileupload"
                    type="file"
                    id="files-input"
                    ref="filesInput"
                    name="files"
                    :disabled="!userSelected"
                    @change="handleFileChanges"
                />
                <input
                    class="textinput"
                    placeholder="Text einfügen"
                    type="text"
                    v-model="documentTitle"
                    :disabled="!userSelected"
                >
                <button @click="createDocument" v-if="userSelected">Dokument erstellen</button>
            </div>

            <!-- <FilterReports /> -->
            <FileList 
                :files="this.$store.getters['documents/documents']"
                :deleteActive="$store.getters['auth/isStaff']"
                downloadAction="documents/downloadDocument"
                deleteAction="documents/deleteDocument"
            />
        </div>
    </div>
</template>

<script>
    import ReceiptviewHeader from '../../partials/ReceiptView_Header'
    import FileList from '../../FileList/FileList'

    export default {
        name: 'DocumentStart',
        components: {
            ReceiptviewHeader,
            FileList
        },
        data () {
            return {
                documentTitle: '',
                documentFiles: []
            }
        },
        computed: {
            userSelected() {
                return this.$store.state.staff.selectedUserId !== undefined
            }
        },
        methods: {
            /**
             * Click event listener for export button
             */
            exportClick() {
                this.$store.dispatch('documents/downloadAllDocuments')
            },

            /**
             * Change event listener for files upload
             */
            handleFileChanges(ev) {
                ([...ev.target.files]).forEach(f => {
                    this.documentFiles.push(f)
                })
            },

            /**
             * Click event listener for 'Dokument erstellen' button
             * stores document it in backend
             */
            createDocument() {
                this.$store.dispatch('documents/createDocument', {
                    files: this.documentFiles,
                    title: this.documentTitle
                })
                this.documentTitle = ''
                this.$refs.filesInput.value = null
                this.documentFiles = []
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .wrapper {
        padding: 7.5px;
        @media screen and (min-width: $medium-screen) {
            padding: 22.5px;
        }
    }

    .reports {
        p {
            max-width: 90%;
            @media screen and (min-width: $medium-screen) {
                max-width: none;
            }
        }
    }
    .content-cards {
        display: flex;
        flex-wrap: wrap;
    }

    .start {
        padding: 7.5px;
        margin-bottom: 20px;
        button {
            font-family: "Gotham-Medium"; 
            color: $brand-blue;
            cursor: pointer;
            padding: 5px 10px;
            &:hover {
                color: $hover-blue;
            }
        }
        input.textinput {
            display: inline-block;
            border: none;
            border-bottom: 1px solid $default-grey;
            border-radius: 0;
            outline: none;
            padding: 9px 0;
            color: $brand-deep-green;
            background-color: transparent;
            font-family: "Gotham-Regular";
            @media screen and (min-width: $medium-screen) {
                padding: 15px 0;
            }
            &.bold {
                font-family: "Gotham-Medium";
            }
            &::placeholder {
                font-family: "Gotham-Regular";
                color: $default-grey;
            }
        }
        input.fileupload {
            color: $default-grey;
            font-family: "Gotham-Regular";
        }
    }
</style>