<template>
    <div class="grid">
        <div class="grid-item"
            v-for="file in files"
            :key="file.id"
        >
            <FileGridItem
                :fileTitle="file.title"
                :fileID="file.id"
                :fileType="'PDF'"
                :fileSize="'1 MB'"
                :fileImage="file.thumbnailUrl"
                @delete="$emit('delete', $event)"
                @download="$emit('download', $event)"
                :deleteActive="deleteActive"
            >
            </FileGridItem>
        </div>
    </div>
</template>

<script>
import FileGridItem from './File_GridItem'

export default {
    name: 'FileGridView',
    components: {
        FileGridItem
    },
    props: {
        files: {
            type: Array, 
            required: true
        },
        deleteActive: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    .grid {
        display: flex;
        flex-wrap: wrap;
        &-item {
            width: 50%;
            padding: 7.5px;
            @media screen and (min-width: $medium-screen) {
                width: 25%;
            }
            @media screen and (min-width: $large-screen) {
                width: 20%;
            }
            &__content {
                padding-top: 50px;
                background-color: $light-grey;
                border-radius: $button-border-r;
                height: 180px;
                @media screen and (min-width: $medium-screen) {
                    height: 265px;
                    padding-top: 60px;
                }
                @media screen and (min-width: $large-screen) {
                    height: 250px;
                }
            }
        }
    }
</style>