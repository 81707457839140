<template>
     <div class="sortmodal">
        <Modal flexPosition="end" size="small" @close-modal="$emit('close-modal')">
            <template v-slot:headertext>
                Beleg verschieben
            </template>
            <template v-slot:body>
                <p>Sie sind dabei 1 Beleg in einen anderen Ordner zu verschieben.</p>
                <p class="text--medium">Bitte wählen Sie einen Zielordner aus:</p>
                <Dropdown :placeholder="'Monat auswählen'" :listItems="monthItems" @selected="selectMonth"/>
                <Dropdown :placeholder="'Belegordner auswählen'" :listItems="categoryItems" @selected="selectCategory"/>
            </template>
            <template v-slot:footer>
                <div class="footer-links text--small text--medium">
                    <button class="btn" @click.prevent="$emit('close-modal')">Abbrechen</button>
                    <button
                        @click="$emit('handlemoveclick')"
                        :class="['btn btn--blue', {'btn--disabled': !categoryIsSelected}]"
                    >
                        Beleg verschieben
                    </button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../../partials/Modal'
import Dropdown from '../../partials/Dropdown'

export default {
    name: 'SortModal',
    components: {
        Modal,
        Dropdown
    },
    props: {
        /**
         * this array includes all the items that are displayed in the 'Belegordner' dropdown 
         */
        categoryItems: {
            type: Array
        },
        /**
         * this array includes all the items that are displayed in the 'Monat' dropdown 
         */
        monthItems: {
            type: Array
        }
    },
    data() {
        return {
            /**
             * Boolean controls the behavior of the 'Beleg verschieben' button 
             * button is disabled when category is not selected.
             */
            categoryIsSelected: false
        }
    },
    methods: {
        /**
         * Click event listener for 'Belegordner' dropdown 
         * enables the 'Beleg verschieben' button when category is selected.
         */
        selectCategory(ev) {
            this.categoryIsSelected = true
            this.$emit('category-select', ev)
        },

        /**
         * Click event listener for month dropdown 
         */
        selectMonth(ev) {
            this.$emit('month-select', ev)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables.scss';

    p {
        font-size: 12px;
        padding-bottom: 10px;
        @media screen and (min-width: $medium-screen) {
            font-size: 14px;
            padding-bottom: 15px;
        }
        @media screen and (min-width: $large-screen) {
            font-size: 16px;
        }
    }
    .sortmodal {
        p {
            &.text--medium {
                padding-top: 30px;
                padding-bottom: 5px;
            }
        }
        .dropdown {
            margin-bottom: 10px;
        }
    }
    .footer-links {
        display: flex;
        justify-content: space-between;
    }
</style>