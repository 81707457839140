// this files defines some common types are common in queries, mutations and subscriptions

export const receiptItem = `
    id
    title
    project
    month
    year
    approved
    requestAssistance
    needInfoTicketID
    updatedAt
    createdAt
    owner
    archived
    files {
        items {
            id
            originalFilename
            imagePaths
            path
            identityId
            ocrResult
            createdAt
            updatedAt
        }
    }
    categoryID
    category {
        id
        name
        additionalPropertyTypes {
            items {
                propertyType {
                    id
                    name
                    itemLabel
                    type
                    required
                    min
                    max
                }
            }
        }
    }
    additionalProperty {
        items {
            id
            value
            typeID
            createdAt
            type {
                id
                name
                itemLabel
                type
                required
            }
        }
    }
`;
