import { v4 as uuidv4 } from 'uuid';

import { API, Storage, Auth, graphqlOperation } from 'aws-amplify';
// import { levenshtein } from 'levenshtein-js';

import * as queries from './queries'
import * as user_queries from './user_queries'

import * as mutations from './mutations';
import * as user_mutations from './user_mutations'

// import * as subscriptions from './subscriptions'
import * as user_subscriptions from './user_subscriptions';

/**
 * Creates a receipt entry in the Receipt database, uploads any files to S3 bucket and adds an ReceiptFile entry for each file uploaded.
 * @param {any} files receipt images/ PDFs to upload to S3 and add to Receipt entry
 * @param {string} currentUser username of the user creating the reciept
 */
export const createReceipt = async (files, currentUser, month, categoryID) => {
    const now = new Date()

    const receiptData = await API.graphql({
        query: user_mutations.createReceiptWithoutFiles,
        variables: {
            input: {
                categoryID: categoryID || "NONE",
                editBy: currentUser,
                title: files[0].name,
                year: now.getFullYear(),
                month: month || null,
                approved: false,
            }
        },
        authmode: 'amazon_cognito_user_pool'
    }).catch(err => console.log(err));
    const receiptId = receiptData.data.createReceipt.id

    const createFileResponse = await Promise.all(files.map(async (file) => {
        await createAndUploadReceiptFile(file, receiptId, currentUser)
    }))
    return createFileResponse;
}

/**
 * Uploads and adds a file to an existing receipt.
 * @param {object} file file object to add to the receipt
 * @param {string} receiptId ID of the receipt
 * @param {string} currentUser username of the user that uploads the file
 */
export const createAndUploadReceiptFile = async (file, receiptId, currentUser) => {
    const credentials = await Auth.currentCredentials()

    const uniqueFilename = uuidv4() + '.' + file.name.split('.').pop()
    const result = await Storage.put(`receipts/${uniqueFilename}`, file, {
        level: 'private'
    })
    const s3file = {
        originalFilename: file.name,
        path: result.key,
        imagePaths: file.name.match(/^.*pdf$/) ? [] : [result.key],
        receiptID: receiptId,
        identityId: credentials.identityId,
        editBy: currentUser
    }
    const receiptFileResponse = await API.graphql({
        query: user_mutations.createReceiptFile,
        variables: { input: s3file },
        authmode: 'amazon_cognito_user_pool'
    })

    return receiptFileResponse
}   

/**
 * Update a receipt entry with the given input.
 * @param {String} receiptID ID of the receipt to be updated
 * @param {Object} input object with attribtes (keys) and new values
 * @param {String} currentUser username of the user that makes the change
 */
export const updateReceipt = async (receiptID, input, currentUser) => {
    const updateReceipt = await API.graphql({
        query: user_mutations.updateReceipt,
        variables: {input: {
            id: receiptID,
            editBy: currentUser,
            ...input
        }}
    })
    return updateReceipt.data.updateReceipt
}

/**
 * Deletes a receipt.
 * @param {string} receiptID ID of the receipt to be deleted 
 */
export const deleteReceipt = async (receiptID) => {
    const response = await API.graphql({
        query: mutations.deleteReceipt,
        variables: {
            input: {
                id: receiptID
            }
        }
    })
    return response
}

/**
 * Deletes a receipt file.
 * @param {string} receiptFileID  ID of the receipt file to be deleted 
 */
export const deleteReceiptFile = async (receiptFileID) => {
    const response = await API.graphql({
        query: mutations.deleteReceiptFile,
        variables: {
            input: {
                id: receiptFileID
            }
        }
    })
    return response
}

/**
 * Update a receipt file entry.
 * @param {String} receiptFileID ID of the receipt file to be updated
 * @param {Object} input object with attribtes (keys) and new values
 * @param {String} currentUser username of the user that makes the change
 */
export const updateReceiptFile = async (receiptFileID, input, currentUser) => {
    const updateReceiptFile = await API.graphql({
        query: mutations.updateReceiptFile,
        variables: {input: {
            id: receiptFileID,
            editBy: currentUser,
            ...input
        }}
    })
    return updateReceiptFile.data.updateReceipt
}

/**  Build a graphql filter variable for fetching receipts.
 * @param {Boolean} isArchive if true, only fetches receipts that belong to the archive. If false, fetches receipts that belong to sammelbecken.
 * @param {number} fromDateYear only fetch receipts from this year (YYY)
 * @param {number} fromDateMonth only fetch receipts beginning at this month, 1-indexed moonth number
 * @param {number} toDateYear only fetch receipts from this year (YYY)
 * @param {number} toDateMonth only fetch receipts beginning at this month, 1-indexed moonth number
 * @param {string} categoryID filter receipts by this category ID
 * @returns {object} graphql filter object
 */
export const buildReceiptFilters = (username, isArchive, fromDateYear, fromDateMonth, toDateYear, toDateMonth, categoryID) => {
    let filter = {}

    if (fromDateYear && fromDateMonth && toDateYear) {
        filter = { 
            year: { between: [fromDateYear, toDateYear] },
        }
    }

    if (toDateMonth) {
        filter.month = { between: [fromDateMonth, toDateMonth] }
    }

    if (categoryID) filter.categoryID = { eq: categoryID }

    if (isArchive) {
        filter.or = [
            {
                approved: { eq: true },    
            },
            {
                archived: { eq: true }
            },
        ]
    } else {
        filter.and = [
            {
                or: [
                    { archived: { attributeExists: false } },
                    { archived: { eq: false } }
                ]
            },
            {
                approved: { eq: false },    
            },

        ]
    }

    return filter
}

/**
 * Lists receipts for a specific user.
 * @param {string} username
 * @param {Boolean} isArchive if true, only fetches receipts that belong to the archive. If false, fetches receipts that belong to sammelbecken.
 * @param {number} fromDateYear only fetch receipts from this year (YYY)
 * @param {number} fromDateMonth only fetch receipts beginning at this month, 1-indexed moonth number
 * @param {number} toDateYear only fetch receipts from this year (YYY)
 * @param {number} toDateMonth only fetch receipts beginning at this month, 1-indexed moonth number
 * @param {string} categoryID filter receipts by this category ID
 * @returns {array} receipts for user
 */
export const listReceipts = async (username, isArchive, fromDateYear, fromDateMonth, toDateYear, toDateMonth, categoryID) => {
    let filter = buildReceiptFilters(username, isArchive, fromDateYear, fromDateMonth, toDateYear, toDateMonth, categoryID)

    let receiptsResult
    try {
        receiptsResult = await API.graphql({
            query: user_queries.listReceiptsByOwner,
            variables: {
                owner: username,
                filter: filter
            },
        })
        return receiptsResult.data.listReceiptsByOwner.items
    } catch (err) {
        console.error('error fetching receipts', err.errors)
        return err.data ? err.data.listReceiptsByOwner.items.filter(i => i) : []
    }

}

/**
 * Fetch a single receipt by ID.
 * @param {string} receiptID ID of the receipt to fetch
 * @returns {object} receipt object
 */
export const getReceipt = async (receiptID) => {
    let response
    try {
        response = await API.graphql({
            query: user_queries.getReceipt,
            variables: {id: receiptID}
        })
    } catch(err) {
        console.error('could not fetch receipt', err)
        return null
    }
    return response.data.getReceipt
}

/**
 * Fetch a series of receipts by an array of IDs. Additionally, filter results. 
 * @param {array} receiptIDs IDs of the receipts to fetch
 * @param {string} username username of the user to filter receipts for
 * @param {Boolean} isArchive if true, only fetches receipts that belong to the archive. If false, fetches receipts that belong to sammelbecken.
 * @param {number} fromDateYear only fetch receipts from this year (YYY)
 * @param {number} fromDateMonth only fetch receipts beginning at this month, 1-indexed moonth number
 * @param {number} toDateYear only fetch receipts from this year (YYY)
 * @param {number} toDateMonth only fetch receipts beginning at this month, 1-indexed moonth number
 * @param {string} categoryID filter receipts by this category ID
 * @returns {array} receipt objects
 */
export const getFilteredReceipts = async (receiptIDs, username, isArchive, fromDateYear, fromDateMonth, toDateYear, toDateMonth, categoryID) => {
    let filter = buildReceiptFilters(username, isArchive, fromDateYear, fromDateMonth, toDateYear, toDateMonth, categoryID)

    let idOrFilter = []
    for (const id of receiptIDs) {
        idOrFilter.push({ id: { eq: id }})
    }

    let response
    try {
        response = await API.graphql({
            query: user_queries.listReceiptsByOwner,
            variables: {
                owner: username,
                filter: {
                    and: [
                        { ...filter }, 
                        { or: idOrFilter }
                    ]
                }
            }
        })
        return response.data.listReceiptsByOwner.items
    } catch (err) {
        console.error('error fetching receipts', err.errors)
        return err.data ? err.data.listReceiptsByOwner.items.filter(i => i) : []
    }
    
}

/**
 * Fetch a list of receipts by IDs
 * @param {array} receiptIDs
 */
export const getReceipts = async (receiptIDs) => {
    let idOrFilter = []
    for (const id of receiptIDs) {
        idOrFilter.push({ id: { eq: id }})
    }

    const response = await API.graphql({
        query: user_queries.listReceipts,
        variables: {
            filter: { or: idOrFilter }
        }
    })

    return response.data.listReceipts.items
}

export const mergeReceipts = async (username, receiptIDs, receiptFileIDs, title) => {
    const now = new Date()

    const receiptID = receiptIDs[0] // the first receipt of the receipts to be merges is the new "master" receipt


    // reset metadata of new master receipt
    await updateReceipt(receiptID, {
        categoryID: "NONE",
        title: title,
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        editBy: username
    },)

    for (const recieptFileID of receiptFileIDs) {
        updateReceiptFile(recieptFileID, {
            receiptID: receiptID
        }, username)
    }

    // delete receipt entries that are not needed anymore
    for (const oldRceiptID of receiptIDs.slice(1)) {
        await deleteReceipt(oldRceiptID)
    }

    return receiptID
}

/**
 * Find receipts for a user with a search term. Additionally, results are filtered on the frontend to show that exclusively have/ do not have a category.
 * @param {string} match search term
 * @param {string} username username of the user to fetch receipts for
 * @returns {array} result receipts
 */
export const searchReceipts = async (match, username) => {

    const variables = {
        keyword: match,
        ownerID: username,
    }
    const receiptSearchResult = await API.graphql({
        query: user_queries.receiptSearch,
        variables: variables
    })

    return receiptSearchResult.data.receiptSearch.items.map(r => r.receiptID)
}

/**
 * Tests wether a receipt GraphQL response object is complete. This is used to filter out receipt entries that have fields missing, mainly caused by receipts being in the creation/ upload process.
 * @param {object} receipt GraphQL receipt response
 */
const receiptResponseComplete = (receipt) => {
    if (receipt.files) return true
    console.log('receiptResponse incomplete')
    return false
}

/**
 * Create a GraphQL subscription for the createReceipt mutation for Receipt entries of a specific user.
 * @param {string} username user whose receipts are subscribed to
 * @param {Boolean} isArchive if true, exclusively returns only receipts that have a category, if false, exclusively returns receipts that don't have a category
 * @param {function} callback function that is called when a Receipt entry is created
 */
export const createReceiptSubscription = async (username, isArchive, callback) => {
    await API.graphql(
        graphqlOperation(
            user_subscriptions.onCreateReceipt,
            {
                owner: username
            }
        )
    ).subscribe({
        next: (subResp) => {
            if (subResp.value.errors) {
                console.error('errors in subscription:', subResp)
                return
            }
            const receipt = subResp.value.data.onCreateReceipt
            if(receipt && receiptResponseComplete(receipt)) {
                if (isArchive && receipt.categoryID !== "NONE" || !isArchive && receipt.categoryID == "NONE") {
                    callback(receipt)
                }
            }
        }
    })
}

/**
 * Create a GraphQL subscription for the updateReceipt mutation for Receipt entries of a specific user.
 * @param {string} username user whose receipts are subscribed to
 * @param {bool} isArchive filters subscription results to only include receitps with/ without category
 * @param {function} callback function that is called when a Receipt entry is updated
 */
export const updateReceiptSubscription = async (username, isArchive, callback) => {
    await API.graphql(
        graphqlOperation(
            user_subscriptions.onUpdateReceipt,
            {
                owner: username
            }
        )
    ).subscribe({
        next: (subResp) => {
            if (subResp.value.errors) {
                console.error('errors in subscription:', subResp)
                return
            }
            const receipt = subResp.value.data.onUpdateReceipt
            if(receipt && receiptResponseComplete(receipt)) {
                if (isArchive && receipt.categoryID !== "NONE" || !isArchive && receipt.categoryID == "NONE") {
                    callback(receipt)
                }
            }
        }
    })
}

/**
 * Create a GraphQL subscription for the createReceitpFile mutation for ReceiptFile entries of a specific user. Passes the connected Receipt entry to the callback function.
 * @param {string} username user whose receipts are subscribed to
 * @param {bool} isArchive filters subscription results to only include receitps with/ without category
 * @param {function} callback after a ReceiptFile is created, this function is called with the Receipt object that belongs to the ReceiptFile
 */
export const createReceiptFileSubscription = async (username, callback) => {
    const subscription = await API.graphql(
        graphqlOperation(
            user_subscriptions.onCreateReceiptFile,
            {
                owner: username
            }
        )
    ).subscribe({
        next: (subResp) => {
            if (subResp.value.errors) {
                console.error('errors in subscription:', subResp)
                return
            }
            const receipt = subResp.value.data.onCreateReceiptFile.receipt
            if (receipt && receiptResponseComplete(receipt)) {
                callback(receipt)
            }
        }
    })
    return subscription
}

/**
 * Create a GraphQL subscription for the updateReceitpFile mutation for ReceiptFile entries of a specific user. Passes the connected Receipt entry to the callback function.
 * @param {string} username user whose receipts are subscribed to
 * @param {bool} isArchive filters subscription results to only include receitps with/ without category
 * @param {function} callback after a ReceiptFile is updated, this function is called with the Receipt object that belongs to the ReceiptFile
 */
export const updateReceiptFileSubscription = async (username, callback) => {
    const subscription = await API.graphql(
        graphqlOperation(
            user_subscriptions.onUpdateReceiptFile,
            {
                owner: username
            }
        )
    ).subscribe({
        next: (subResp) => {
            if (subResp.value.errors) {
                console.error('errors in subscription:', subResp)
                return
            }
            const receipt = subResp.value.data.onUpdateReceiptFile.receipt
            if (receipt && receiptResponseComplete(receipt)) {
                callback(receipt)
            }
        }
    })
    return subscription
}

/**
 * Returns all values of the project field from all receipts of a user.
 * @param {string} username return projects from this username
 */
export const listProjects = async (username) => {
    const result = await API.graphql({
        query: user_queries.receiptProjects,
        filter: {
            owner: {
                eq: username
            }
        }
    })
    return result.data.listReceipts.items.map(i => i.project).filter(p => p != null)
}

/**
 * Returns all available receipt categories.
 * */
export const listCategories = async () => {
    const result = await API.graphql({
        query: user_queries.listReceiptCategorys,
    })
    return result.data.listByOrder.items
}

/**
 * Query the AdminQueries API for a list of users.
 * @returns {array} List of User objects
 * */
export const listUsers = async () => {
    let options = {
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const result = await API.get('AdminQueries', '/listUsers', options)

    result.Users.forEach(user => {
        const attributesArray = user.Attributes
        let attributesObject = {}
        attributesArray.forEach(attribute => {
            attributesObject[attribute.Name] = attribute.Value
        })
        user.Attributes = attributesObject
    })
    return result.Users
}

/**
 * Sets a feature flag for a cognito user pool user.
 * @param {object} user CognitoUser
 * @param {string} feature feature name
 * @param {boolean} value feature flag value
 */
export const setFeatureFlag = async (user, feature, value) => {
    if (typeof value !== 'boolean') {
        console.error('feature flag value needs to be boolean')
    }

    let updateObj = {}
    updateObj[`custom:${feature}`] = value ? '1' : '0'
    await Auth.updateUserAttributes(user, updateObj)
}

/**
 * Create a MetadataProperty item for a receipt.
 * @param {String} receiptID ID of the receipt
 * @param {String} metadataPropertyTypeID ID of the metadataPropertyType
 * @param {any} value value of the property (can be any value that can be converted to JSON)
 * @param {String} username of the user aking the change
 */
export const createMetadataProperty = async (receiptID, metadataPropertyTypeID, value, currentUser) => {
    const result = await API.graphql({
        query: user_mutations.createMetadataProperty,
        variables: {
            input: {
                value: JSON.stringify(value),
                typeID: metadataPropertyTypeID,
                receiptID: receiptID,
                editBy: currentUser
            }
        }
    })
    return result.data.createMetadataProperty
}

/**
 * Update an existing additional metadata property.
 * @param {String} metadataPropertyID ID od the metadata property
 * @param {any} value new property value (can be any value that can be converted to JSON)
 * @param {String} username of the user aking the change
 */
export const updateMetadataProperty = async (metadataPropertyID, value, username) => {
    const result = await API.graphql({
        query: user_mutations.updateMetadataProperty,
        variables: { input: {
            id: metadataPropertyID,
            value: JSON.stringify(value),
            editBy: username
        }}
    })
    return result.data.updateMetadataProperty
}

/**
 * Delete an additional metadata property.
 * @param {String} metadataPropertyID ID od the metadata property
 */
export const deleteMetadataProperty = async (metadataPropertyID) => {
    await API.graphql({
        query: mutations.deleteMetadataProperty,
        variables: { input: {
            id: metadataPropertyID
        }}
    })
    return true
}


/**
 * Fetch a presigned URL to access a private S3 object.
 * @param {string} key Path/ Key to S3 object
 * @param {string} identityId IdentityId of the owner of the object
 * @returns {string} URL the object can be accessed at
 */
export const getS3URL = async (key, identityId) => {
    const signedUrl = await Storage.get(key, {
        customPrefix: { public: `private/${identityId}/` },
        level: 'public'
    })
    return signedUrl
}

/**
 * Submit a support ticket to FreshDesk.
 * @param {string} subject Ticket subject line
 * @param {string} description Ticket description
 * @param {array} tags Ticket tags (array of atrings)
 * @param {string} email E-Mail of user that this ticket should be created for. Is ignored when non-staff user creates ticket request.
 * @returns {number} FreshDesk ticket ID
*/
export const submitTicket = async (subject, description, tags, email) => {
    const init = {
        body: {
            subject: subject,
            description: description,
            tags: tags,
            email: email
        }
    };
    try {
        const response = await API.post('tickets', '/ticket', init)
        return response.ticketID
    } catch(err) {
        console.error('could not submit ticket', err)
        throw new Error("could not submit ticket")
    }
}

/**
 * Submit a reply to a FreshDesk ticket.
 * @param {number} ticketID ID of the ticket to add the reply to
 * @param {string} message reply message body
 */
export const createTicketReply = async (ticketID, message) => {
    const init = {
        body: {
            message: message,
            ticketID: ticketID
        }
    };
    try {
        await API.post('tickets', '/reply', init)
    } catch(err) {
        console.error('could not submit ticket reply', err)
        throw new Error("could not submit ticket reply")
    }
}

/**
 * Fetches the SSO URL to login the currently authenticated user into FreshDesk.
 * @param {string} redirectUrl base redirect URL. FreshDesk redirect passes this param.
 * @param {string} state state string. FreshDesk redirect passes this param.
 * @param {string} nonce nonce parameter to include in the token. FreshDesk redirect passes this param.
 * @returns {string} SSO URL to FreshDesk
 */
export const getFreshDeskSSOUrl = async (redirectUrl, state, nonce) => {
    try {
        const init = {
            'queryStringParameters': {
                'nonce': nonce
            }
        }
        const response = await API.get('freshDeskToken', '/token', init)
        const url = `${redirectUrl}?state=${state}&id_token=${response.token}`
        return url
    } catch(err) {
        console.error('could not fetch freshdesk SSO URL', err)
        throw new Error("could not fetch freshdesk SSO URL")
    }
}

/**
 * Fetches the self service URL for billwerk.
 * @returns {string} URL to self service page.
 */
 export const getBillwerkSelfServiceURL = async () => {
    try {
        const response = await API.get('billwerkToken', '/selfServiceToken')
        console.log(response)
        return response.url
    } catch(err) {
        console.error('could not fetch billwerk self service URL', err)
        throw new Error("could not fetch billwerk self service URL")
    }
}

/**
 * Creates a report by staff.
 * @param {array} files report files
 * @param {string} title Title/name of the report
 * @param {string} categoryID category the report belongs to
 * @param {number} month report month (optional, can be null for e.g. "sonstige" report type)
 * @param {number} year report year (optional, can be null for e.g. "sonstige" report type)
 * @param {string} forUserName username of the user the report is for
 * @param {string} forUserIdentityID identityID of the user the report is for (for S3 access)
 */
export const createReport = async (files, currentUser, title, categoryID, month, year, forUserName, forUserIdentityID) => {
    let keys = []
    for (const file of files) {
        const uniqueFilename = uuidv4() + '.' + file.name.split('.').pop()
        const result = await Storage.put(`report/${uniqueFilename}`, file, {
            customPrefix: { public: `private/${forUserIdentityID}/` },
            level: 'public'
        })
        keys.push(result.key)
    }
    
    const report = await API.graphql({
        query: mutations.createReport,
        variables: {
            input: {
                title: title,
                originalFilenames: files.map(f => f.name),
                paths: keys,
                categoryID: categoryID,
                user: forUserName,
                month: month,
                year: year,
                identityId: forUserIdentityID,
                editBy: currentUser
            }
        }
    })

    return report.data.createReport
}

/**
 * Fetches reports for a user.
 * @param {string} username User to fetch reports for
 * @param {number} fromDateYear only fetch reports from this year (YYY)
 * @param {number} fromDateMonth only fetch reports beginning at this month, 1-indexed moonth number
 * @param {number} toDateYear only fetch reports from this year (YYY)
 * @param {number} toDateMonth only fetch reports beginning at this month, 1-indexed moonth number
 * @param {string} categoryID filter reports by this category ID
 */
export const listReports = async (username, fromDateYear, fromDateMonth, toDateYear, toDateMonth, categoryID) => {
    const filter = { 
        user: { eq: username }
    }

    if (fromDateYear && toDateYear) {
        filter.year = { between: [fromDateYear, toDateYear] }
    }

    if (fromDateMonth && toDateMonth) {
        filter.month = { between: [fromDateMonth, toDateMonth] }
    }

    if (categoryID) filter.categoryID = { eq: categoryID }

    const reportsResult = await API.graphql({
        query: queries.listReports,
        variables: { filter: filter }
    })
    return reportsResult.data.listReports.items
}

/**
 * Returns all available receipt categories.
 * */
 export const listReportCategories = async () => {
    const result = await API.graphql({
        query: user_queries.listReportCategorys,
    })
    return result.data.listReportCategoryByOrder.items
}

/**
 * Deletes a report by ID.
 * @param {string} reportID 
 */
 export const deleteReport = async (reportID) => {
    await API.graphql({
        query: mutations.deleteReport,
        variables: {
            input: {
                id: reportID
            }
        }
    })
}


/**
 * Create a GraphQL subscription for the updateReport mutation for Report entries of a specific user.
 * @param {string} username user whose reports are subscribed to
 * @param {string} adminUser admin user that creates reports
 * @param {function} callback after a Report is created, this function is called with the Report object as a parameter.
 */
export const updateReportSubscription = async (username, adminUser, callback) => {
    const subscription = await API.graphql(
        graphqlOperation(
            user_subscriptions.onUpdateReport,
            {
                owner: adminUser
            }
        )
    ).subscribe({
        next: (subResp) => {
            if (subResp.value.errors) {
                console.error('errors in subscription:', subResp)
                return
            }
            const report = subResp.value.data.onUpdateReport
            if (report.user === username) {
                callback(report)
            }
        }
    })
    return subscription
}

/**
 * Search reports
 * @param {string} term search term
 * @param {string} username include results of this user
 */
export const searchReports = async (term, username) => {
    const variables = {
        keyword: term,
        userID: username,
    }
    const reportSearchResult = await API.graphql({
        query: user_queries.reportSearch,
        variables: variables
    })
    return reportSearchResult.data.reportSearch.items
}

/**
 * Creates a document by staff.
 * @param {array} files document files
 * @param {string} title Title/name of the document
 * @param {string} forUserName username of the user the document is for
 * @param {string} forUserIdentityID identityID of the user the document is for (for S3 access)
 */
 export const createDocument = async (files, currentUser, title, forUserName, forUserIdentityID) => {
    let keys = []
    for (const file of files) {
        const uniqueFilename = uuidv4() + '.' + file.name.split('.').pop()
        const result = await Storage.put(`documents/${uniqueFilename}`, file, {
            customPrefix: { public: `private/${forUserIdentityID}/` },
            level: 'public'
        })
        keys.push(result.key)
    }
    
    const document = await API.graphql({
        query: mutations.createDocument,
        variables: {
            input: {
                title: title,
                originalFilenames: files.map(f => f.name),
                paths: keys,
                user: forUserName,
                identityId: forUserIdentityID,
                editBy: currentUser
            }
        }
    })

    return document.data.createDocument
}

/**
 * Fetches documents for a user.
 * @param  {string} username User to fetch documents for
 */
export const listDocuments = async (username) => {
    const filter = { 
        user: { eq: username }
    }

    const documentResult = await API.graphql({
        query: queries.listDocuments,
        variables: { filter: filter }
    })
    return documentResult.data.listDocuments.items
}

/**
 * Deletes a document by ID.
 * @param {string} documentID 
 */
export const deleteDocument = async (documentID) => {
    await API.graphql({
        query: mutations.deleteDocument,
        variables: {
            input: {
                id: documentID
            }
        }
    })
}

/**
 * Create a GraphQL subscription for the updateDocument mutation for Document entries of a specific user.
 * @param {string} username user whose documents are subscribed to
 * @param {string} adminUser admin user that creates documents
 * @param {function} callback after a document is created, this function is called with the document object as a parameter.
 */
export const updateDocumentSubscription = async (username, adminUser, callback) => {
    const subscription = await API.graphql(
        graphqlOperation(
            user_subscriptions.onUpdateDocument,
            {
                owner: adminUser
            }
        )
    ).subscribe({
        next: (subResp) => {
            if (subResp.value.errors) {
                console.error('errors in subscription:', subResp)
                return
            }
            const document = subResp.value.data.onUpdateDocument
            if (document.user === username) {
                callback(document)
            }
        }
    })
    return subscription
}