/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:66e25686-e466-44ac-8c76-39798f4a1bc3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_AbNPpEV09",
    "aws_user_pools_web_client_id": "3s20kddss1c0uosm8a26f568r4",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://o21r7ktv0k.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "tickets",
            "endpoint": "https://ae7bb8k4dl.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "billwerk",
            "endpoint": "https://rrnel46tke.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "freshDeskToken",
            "endpoint": "https://e5kmll1aok.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "billwerkToken",
            "endpoint": "https://wz582hg21f.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6oqi7pujofa5tiqpw5u3e5oynu.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "receiptfiles91114-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
