<template>
    <div 
        class="" 
        :class="['input-field', { inlineblock: display === 'inlineblock' }, { invalid: !valid }]"
        @mouseover="deleteBtnIsActive = true"
        @mouseleave="deleteBtnIsActive = false"
    >
        <input
            :type="type"
            :name="name"
            :placeholder="placeholder"
            :class="{bold: fontWeight === 'bold'}"
            v-model="text"
            @keyup="handleKeyUp"
        >
        <label :class="{active: text}" :for="name">{{label}}</label>

        <!-- x icon -->
        <svg v-if="!valid" class="valid-icon" :class="{active: !deleteBtnIsActive || !removeAble}" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2744 2.1802L12.8644 0.770203L7.27441 6.3602L1.68441 0.770203L0.274414 2.1802L5.86441 7.7702L0.274414 13.3602L1.68441 14.7702L7.27441 9.1802L12.8644 14.7702L14.2744 13.3602L8.68441 7.7702L14.2744 2.1802Z" fill="#58584D"/>
        </svg>
        <!-- checkmark icon -->
        <svg v-if="valid" class="valid-icon" :class="{active:  !deleteBtnIsActive || !removeAble}" width="14" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4823 0.438886L10.8423 0.0688908C10.6123 -0.0711092 10.3123 0.00888346 10.1723 0.248883L3.80226 11.2589L1.55226 7.3589C1.41226 7.1289 1.12227 7.03888 0.882266 7.17888L0.242266 7.5489C0.0122663 7.6889 -0.067734 7.98888 0.062266 8.21888L2.94226 13.2189C3.03226 13.3789 3.19226 13.4589 3.36226 13.4589H4.24227C4.41227 13.4589 4.57226 13.3689 4.66226 13.2089L11.6623 1.1089C11.7923 0.868899 11.7123 0.568886 11.4823 0.438886Z" fill="#151917"/>
        </svg>

        <!-- delete icon for metadata fields -->
        <svg v-if="removeAble" @click="$emit('deleteMetadata')" class="delete-btn" :class="{active: deleteBtnIsActive}" width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3882 3.9634H13.2138V0.983118C13.2138 0.696934 12.977 0.469971 12.6908 0.469971H8.93092C8.64474 0.469971 8.4079 0.696934 8.4079 0.983118V3.9634H1.23355C0.967103 3.9634 0.75 4.1805 0.75 4.44694V5.1772C0.75 5.44364 0.967103 5.66077 1.23355 5.66077H20.3783C20.6447 5.66077 20.8618 5.44364 20.8618 5.1772V4.44694C20.8717 4.17063 20.6546 3.9634 20.3882 3.9634ZM12.1678 3.9634H9.45395V1.50614H12.1678V3.9634Z" fill="#151917"/>
            <path d="M20.4866 7.47645L19.7662 7.32843C19.5096 7.26922 19.2333 7.50605 19.1643 7.84157L16.0853 22.7824H14.2597L15.6116 7.53565H14.5656L13.2136 22.7824H11.3781V7.53565H10.3419V22.7824H8.44715L7.13465 7.53565H6.09846L7.41097 22.7824H5.66426L2.54583 7.84157C2.47675 7.50605 2.21031 7.27908 1.94386 7.32843L1.22347 7.47645C0.96689 7.53566 0.808989 7.85145 0.878068 8.18697L4.17412 23.9666C4.20373 24.1146 4.27281 24.2232 4.36163 24.3218C4.3715 24.3317 4.37149 24.3416 4.38136 24.3416C4.42083 24.381 4.47018 24.4106 4.51952 24.4304C4.52939 24.4304 4.52938 24.4403 4.53925 24.4403C4.58859 24.46 4.64781 24.4699 4.70702 24.4699C4.71689 24.4699 4.71689 24.4699 4.72675 24.4699H17.0425C17.0524 24.4699 17.0524 24.4699 17.0623 24.4699C17.1116 24.4699 17.1708 24.46 17.2202 24.4403C17.2399 24.4304 17.2596 24.4205 17.2695 24.4107C17.2991 24.3909 17.3386 24.3712 17.3682 24.3416C17.3879 24.3218 17.4077 24.3021 17.4274 24.2725C17.4965 24.1837 17.5557 24.085 17.5853 23.9567L20.8419 8.1771C20.9011 7.85144 20.7432 7.53566 20.4866 7.47645Z" fill="#151917"/>
        </svg>


    </div>
</template>

<script>
export default {
    name: 'InputField',
    data() {
        return {
            text: this.value,
            keyUpTimer: null,
            deleteBtnIsActive: false
        }
    },
    props: {
        /**
         * sets individual name to combine label with input field
         */
        name: {
            type: String,
        },
        /**
         * default value of text input
         */
        value: {
        },
        /**
         * type of input field
         * can be everything that is known ('name', 'password', 'e-mail', 'phone' etc)
         */
        type: {
            type: String,
            required: true
        },
        /**
         * validity, shows 
         */
        valid: {
            type: Boolean,
            default: true
        },
        /**
         * text of label
         */
        label: {
            type: String,
            default: ''
        },
        /**
         * text of placeholder
         */
        placeholder: {
            type: String,
            default: ''
        },
        /**
         * detects how the inputfield should be displayed
         * can be 'inlineblock'
         */
        display: {
            type: String,
            default: '' 
        },
        /**
         * detects the fontweight of input fields
         * can be 'bold'
         */
        fontWeight: {
            type: String,
            default: ''
        },

        removeAble: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleKeyUp() {
            clearTimeout(this.keyUpTimer)
            this.keyUpTimer = setTimeout(() => this.$emit('change', this.text), 200)
        },
    }
}
</script>

<style lang="scss">
    @import 'src/assets/style/variables.scss';

    .input-field {
        display: block;
        position: relative;
        margin-bottom: 20px;
        max-width: 90vw; // this fixed mobile inputs that have been too wide
        &.inlineblock {
            display: inline-block;
        }
        input {
            display: block;
            width: 100%;
            border: none;
            border-bottom: 1px solid $default-grey;
            border-radius: 0;
            outline: none;
            box-shadow: none;
            padding: 10px 30px 10px 0;
            color: $brand-deep-green;
            background-color: transparent;
            font-family: "Gotham-Regular";
            font-size: 14px;
            @media screen and (min-width: $medium-screen) {
                padding: 10px 30px 10px 0;
                font-size: 16px;
            }
            &:hover {
                border-color: $hover-blue;
            }
            &:focus {
                border-color: $hover-blue;
                + label {
                    top: 140%;
                    font-size: 12px;
                    transition: all 0.2s;
                }
            }
            &.bold {
                font-family: "Gotham-Medium";
            }
            &::placeholder {
                font-family: "Gotham-Regular";
                color: $default-grey;
            }
        }
        label {
            position: absolute;
            top: 50%;
            display: block;
            transform: translateY(-50%);
            color: $default-grey;
            font-size: 14px;
            transition: all 0.2s;
            pointer-events: none;
            @media screen and (min-width: $medium-screen) {
                font-size: 16px;
            }
            &.active {
                top: 140%;
                font-size: 12px;
                transition: all 0.2s;
            }
        }
        &.invalid {
            input {
                border-color: $error;
            }
            svg path {
                fill: $error;
            }
        }
        svg {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            path {
                fill: $brand-blue;
            }
            &.valid-icon {
                @media screen and (min-width: $medium-screen) {
                    display: none;
                }
                &.active {
                    @media screen and (min-width: $medium-screen) {
                        display: block;
                    }
                }
            }
            &.delete-btn {
                width: 20px;
                height: 20px;
                cursor: pointer;
                right: -30px;
                @media screen and (min-width: $medium-screen) {
                    display: none;
                    right: 5px;
                }
                &.active {
                    display: block;
                }
                path {
                    fill: $error;
                }
            }
        }
    }
</style>